import { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import clsx from "clsx"
import { SliderArrows } from "./slider--arrows"
import "keen-slider/keen-slider.min.css"

export const Slider = ({ sliderSettings, children }) => {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [loaded, setLoaded] = useState(false)

	const arrowsSettings = {
		hideArrowMobile: sliderSettings?.arrows?.hideArrowMobile || false,
		hideArrowTablet: sliderSettings?.arrows?.hideArrowTablet || false,
		hideArrowDesktop: sliderSettings?.arrows?.hideArrowDesktop || false,
	}

	const [sliderRef, instanceRef] = useKeenSlider({
		rtl: sliderSettings.rtl,
		loop: sliderSettings.loop,
		disabled: sliderSettings.disabled,
		centred: sliderSettings.centred,
		initial: sliderSettings.initial,
		slides: {
			origin: sliderSettings.slides.origin,
			number: sliderSettings.slides.number,
			perView: sliderSettings.slides.perView,
			spacing: sliderSettings.slides.spacing,
		},
		defaultAnimation: {
			duration: sliderSettings.defaultAnimation.duration,
		},
		rubberband: sliderSettings.rubberband,
		renderMode: sliderSettings.renderMode,
		vertical: sliderSettings.vertical,
		opacity: sliderSettings.opacity,
		breakpoints: sliderSettings.breakpoints,

		slideChanged(Slider) {
			setCurrentSlide(Slider.track.details.rel)
		},
		created() {
			setLoaded(true)
		},
	})

	return (
		<>
			<div className={clsx("relative lg:px-10")}>
				<div ref={sliderRef} className={clsx("keen-slider")}>
					{children}
				</div>

				{loaded && instanceRef.current && (
					<div
						className={clsx(
							"keen-slider__controlls",
							"flex items-center justify-center mt-5"
						)}
					>
						<SliderArrows
							left
							arrowsSettings={arrowsSettings}
							onClick={() => instanceRef.current?.prev()}
						/>

						<div className={"dots flex items-center justify-center"}>
							{[...Array(instanceRef.current.track.details.slides.length).keys()].map(
								(idx) => {
									return (
										<button
											key={idx}
											onClick={() => {
												instanceRef.current?.moveToIdx(idx)
											}}
											variant="none"
											className={clsx(
												"dot w-5 h-5 cursor-pointer  rounded-full my-0 mx-3 p-2 bg-transparent border-2 border-black",
												currentSlide == idx ? "active bg-black" : ""
											)}
										/>
									)
								}
							)}
						</div>

						<SliderArrows
							right
							arrowsSettings={arrowsSettings}
							onClick={() => instanceRef.current?.next()}
						/>
					</div>
				)}
			</div>
		</>
	)
}
