import get from "lodash.get"
import { stripHtml } from "@/lib"
import truncate from "truncate"
import { Wysiwyg } from "@/ui"

export const normalizeNode = (node) => {
	return {
		video: {
			source: node?.field_vactory_media?.field_media_oembed_video,
			thumbnail: node?.field_vactory_media?.thumbnail?.uri?.value?._default,
		},
		image: {
			src: node?.field_vactory_media_image?.thumbnail?.uri?.value?._default,
			width: node?.field_vactory_media_image?.thumbnail?.meta?.width || 0,
			height: node?.field_vactory_media_image?.thumbnail?.meta?.height || 0,
			alt: node?.field_vactory_media_image?.thumbnail?.meta?.alt,
		},
		excerpt: truncate(stripHtml(node?.field_vactory_excerpt?.value)),
		vcc: node?.vcc_normalized,
		theme: node?.field_vactory_taxonomy_1?.name,
		url: node?.path?.alias,
		premium: node?.field_vactory_flag,
	}
}

export const normalizeNodes = (nodes, textLimit = 100) => {
	return (
		nodes?.map((post) => ({
			id: post.drupal_internal__nid,
			image: get(post, "field_vactory_media_image.thumbnail.uri.value", null),
			title: post.title,
			created: convertDate(post.created),
			theme: get(post, "field_vactory_taxonomy_1.name", null),
			video: get(post, "field_vactory_media.field_media_oembed_video", null),
			url: get(post, "path.alias", "#."),
			premium: get(post, "field_vactory_flag", null),
			excerpt: truncate(
				stripHtml(get(post, "field_vactory_excerpt.processed", "")),
				textLimit
			),
		})) || []
	)
}

export const normalizeTerms = (terms) => {
	return terms.map((term) => ({
		id: term.drupal_internal__tid,
		name: term.name,
	}))
}

export const normalizeVCC = (nodes) => {
	return nodes?.map((post) => ({
		id: post.id,
		image: post?.image,
		theme: post?.tag,
		created: post?.date?.html_date,
		title: post?.title,
		premium: post?.premium,
		excerpt: <Wysiwyg html={truncate(stripHtml(post?.excerpt?.[0]?.value, 100))} />,
		url: post?.url,
	}))
}

function convertDate(date) {
	var d = new Date(date)
	var yyyy = d.getFullYear().toString()
	var mm = (d.getMonth() + 1).toString()
	var dd = d.getDate().toString()

	var mmChars = mm.split("")
	var ddChars = dd.split("")

	return (
		(ddChars[1] ? dd : "0" + ddChars[0]) +
		"/" +
		(mmChars[1] ? mm : "0" + mmChars[0]) +
		"/" +
		yyyy
	)
}
