import { Fragment, useState, useEffect, useRef } from "react"
import { useI18n } from "@/hooks"
import { Icon, Input, Button, Heading, Link } from "@/ui"
import { Transition } from "@headlessui/react"
import { useRouter } from "next/router"
import { SYSTEM_ROUTES } from "@/lib/routes"
import clsx from "clsx"
import { drupal } from "@/lib"

export const SearchOverlay = ({
	onClose,
	show,
	setIsSearchOverlayVisible,
	// suggestions: terms,
}) => {
	const { t } = useI18n()
	const searchInputRef = useRef(null)
	const [query, setQuery] = useState("")
	const router = useRouter()
	const [terms, setKeywords] = useState([])

	const getSuggestions = async () => {
		if (!show || terms.length > 0) {
			return
		}
		const response = await drupal.fetch(
			`${router.locale}/frequent-searches?index=default_content_index&limit=10&lang=${router.locale}`
		)
		const { keywords } = await response.json()
		console.log("=======keywords", keywords)
		setKeywords(keywords)
	}

	useEffect(() => {
		getSuggestions()
	}, [show])

	useEffect(() => {
		const _time = setTimeout(() => {
			if (searchInputRef.current) {
				searchInputRef.current.focus()
				clearTimeout(_time)
			}
		}, 50)
	}, [show])

	const handleSubmit = (e, tag) => {
		e.preventDefault()
		if (tag) {
			router
				.push({
					pathname: `${SYSTEM_ROUTES.search.path}`,
					query: { q: tag },
				})
				.then(() => setIsSearchOverlayVisible(false))
			return
		}
		router
			.push({
				pathname: `${SYSTEM_ROUTES.search.path}`,
				query: { q: query },
			})
			.then(() => setIsSearchOverlayVisible(false))
		// .then(() => router.reload())
	}

	return (
		<Transition
			as={Fragment}
			show={show}
			enter="transition-all duration-800"
			enterFrom="opacity-0 -translate-y-24"
			enterTo="opacity-100 translate-y-0"
			leave="transition-all duration-800"
			leaveFrom="opacity-100 translate-y-0"
			leaveTo="opacity-0 -translate-y-24"
		>
			<div className="fixed inset-0 bg-search z-[70] flex items-start justify-center">
				<div
					className={clsx(
						`px-12 h-fit max-w-full md:max-w-3xl lg:max-w-[950px] xl:max-w-6xl w-full absolute`,
						"top-1/2 md:-translate-x-0 -translate-y-1/2"
					)}
				>
					<Button
						className="absolute top-12 right-12 bg-transparent border-0 text-black hover:bg-transparent outline-none focus:outline-none "
						onClick={() => {
							onClose()
						}}
					>
						<Icon id="close-thin" className="w-6 h-6 fill-current text-white" />
					</Button>
					<div className="w-full flex items-center justify-center flex-col">
						<form
							className="lg:w-[70%] md:w-4/5 w-full mt-28"
							onSubmit={(e) => handleSubmit(e)}
						>
							<div className="w-full h-[51px] flex">
								<Input
									variant="overlay"
									placeholder={t("Nx:What are you searching for ?")}
									onChange={(e) => setQuery(e.target.value)}
									ref={searchInputRef}
								/>
								<Button
									type="submit"
									className="flex-shrink-0 w-[50px] h-full  bg-[#fff] text-secondary flex justify-center items-center 
									active:ring-0 focus:ring-0 focus:outline-none active:outline-none focus:border-0 active:border-0 border-0"
								>
									<Icon id="search" className="h-5 w-5 fill-current text-secondary" />
								</Button>
							</div>
							<span className="my-5 inline-block text-sm font-normal text-white">
								{t("Nx:Hit enter to search or ESC to close")}
							</span>

							{terms?.length > 0 && (
								<>
									<Heading level="6" className="text-white">
										{t("Nx:Can we suggest?")}
									</Heading>
									<ul className="flex flex-row flex-wrap gap-x-1 gap-y-1">
										{terms.map((e) => (
											<li key={e.id}>
												<Link
													onClick={() => onClose()}
													href={`${SYSTEM_ROUTES.search.path}?q=${e.keywords}`}
													className="cursor-pointer px-2 py-1 uppercase leading-none text-xs text-white font-bold border border-white border-opacity-50 bg-white bg-opacity-20"
												>
													{`#${e.keywords}`}
												</Link>
											</li>
										))}
									</ul>
								</>
							)}
						</form>
					</div>
				</div>
			</div>
		</Transition>
	)
}
