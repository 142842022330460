import { useState, useEffect, useRef } from "react"
import clsx from "clsx"
import { useVideo } from "@/hooks"
import { Transition } from "@headlessui/react"
import dynamic from "next/dynamic"
const YouTube = dynamic(() => import("react-youtube"), { ssr: false })

const LoadingSpinner = () => (
	<div className="absolute inset-0 flex items-center justify-center text-white">
		Loading …
	</div>
)

export const GlobalVideoModal = ({ minimizerIcon, expenderIcon, closeIcon }) => {
	const { source, isOpen, setIsOpen, minimize, setMinimize } = useVideo()
	const [isLoading, setIsLoading] = useState(true)
	const ref = useRef(null)

	const onReady = () => {
		setIsLoading(false)
	}

	useEffect(() => {
		setIsLoading(true)
	}, [isOpen])

	// close on click outside
	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === "Escape") {
				setIsOpen(false)
			}
		}

		document.addEventListener("keydown", handleKeyDown)
		return () => {
			document.addEventListener("keydown", handleKeyDown)
		}
		// eslint-disable-next-line
	}, [isOpen])

	const opts = {
		playerVars: {
			autoplay: 1,
		},
	}

	return (
		<>
			<Transition
				show={isOpen}
				enter="transition-opacity duration-75"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className={clsx(
					"fixed z-[1000] flex justify-center items-center transition",
					minimize ? "bottom-5 left-0 sm:left-5" : "inset-0 p-5 lg:px-40"
				)}
			>
				<Transition
					show={!minimize && isOpen}
					enter="transition-opacity ease-linear duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					className="absolute inset-0 transition-opacity bg-black bg-opacity-50"
				>
					<div
						onClick={() => {
							setIsOpen(false)
						}}
						className="absolute inset-0 transition-opacity bg-gray-500 opacity-75"
					></div>
				</Transition>
				<Transition
					show={isOpen}
					enter="transition-opacity ease-linear duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					className={clsx(
						"relative flex-1 shadow-xl bg-black transition",
						minimize ? "rounded-md" : "rounded-lg"
					)}
				>
					<div ref={ref} className="group">
						<div
							className={clsx(
								"absolute z-50 md:bottom-full right-0 mb-1",
								"lg:opacity-0 md:group-hover:opacity-100 space-x-1"
							)}
						>
							<button
								onClick={() => setMinimize(!minimize)}
								type="button"
								className="hidden p-2 text-white bg-black bg-opacity-50 rounded-md md:inline hover:text-yellow-500"
							>
								<span className="sr-only">Minimize</span>
								{minimize ? expenderIcon : minimizerIcon}
							</button>
							<button
								onClick={(e) => {
									e.stopPropagation()
									setIsOpen(false)
								}}
								type="button"
								className="p-2 text-white bg-black bg-opacity-50 rounded-md hover:text-yellow-500"
							>
								<span className="sr-only">Close</span>
								{closeIcon}
							</button>
						</div>
						<div
							className={clsx(
								"overflow-hidden",
								minimize
									? "w-96 h-56 rounded-md"
									: "aspect-w-16 aspect-h-9 isolate rounded-lg "
							)}
						>
							<YouTube
								id={source}
								onReady={() => {
									onReady()
								}}
								opts={opts}
								videoId={source}
								iframeClassName="w-full h-full"
								className="absolute top-0 bottom-0 left-0 w-full h-full my-auto"
							/>
							{/* <ReactPlayer
										url={source}
										playing
										width="100%"
										height="100%"
										controls
										onReady={onReady}
										muted
									/> */}
						</div>
						{isLoading && (
							<LoadingSpinner className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 rtl:translate-x-1/2" />
						)}
					</div>
				</Transition>
			</Transition>
		</>
	)
}

// const checkVideoSource = (source) => {
// 	if (source.includes("youtube")) {
// 		return "youtube"
// 	} else {
// 		return "vimeo"
// 	}
// }

// export const ModalVideo = ({ variant = "default", thumbnail, source }) => {
// 	const { setSource, setIsOpen } = useContext(PlayerContext)
// 	const [thumbnailSource, setThumbnailSource] = useState(thumbnail || null)

// 	useEffect(() => {
// 		fetch(`https://noembed.com/embed?url=${source}`)
// 			.then((response) => {
// 				return response.json()
// 			})
// 			.then((data) => {
// 				if (checkVideoSource(source) === "youtube") {
// 					var highResolutionThumbnail = data.thumbnail_url.replace(
// 						"hqdefault",
// 						"maxresdefault"
// 					)
// 					setThumbnailSource(highResolutionThumbnail)
// 				} else {
// 					setThumbnailSource(data.thumbnail_url)
// 				}
// 			})
// 			.catch((error) => {
// 				console.log(error)
// 			})
// 	})

// 	const handleCardClick = () => {
// 		setSource(source)
// 		setIsOpen(true)
// 	}

// 	return (
// 		<>
// 			<div onClick={handleCardClick} className={modalVideo[variant].thumbnailContainer}>
// 				<img
// 					src={thumbnail ? thumbnail : thumbnailSource}
// 					className={modalVideo[variant].thumbnailImage}
// 				/>

// 				<div className={modalVideo[variant].playIcon.position}>
// 					<Icon
// 						id={modalVideo[variant].playIcon.id}
// 						className={modalVideo[variant].playIcon.base}
// 					></Icon>
// 				</div>
// 			</div>
// 		</>
// 	)
// }
