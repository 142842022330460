import get from "lodash.get"
import { stripHtml } from "@/lib"
import truncate from "truncate"
import { Wysiwyg } from "@/ui"

export const normalizeNodes = (nodes) => {
	return (
		nodes?.map((post) => ({
			id: post.drupal_internal__nid,
			title: post.title,
			url: get(post, "path.alias", "#."),
			excerpt: truncate(stripHtml(get(post, "field_vactory_excerpt.processed", "")), 100),
			category: get(post, "field_vactory_news_theme.name", null),
			image: get(post, "field_vactory_media.thumbnail.uri.value", null),
			date: get(post, "field_vactory_date", null),
		})) || []
	)
}

export const normalizeNode = (node) => {
	return {
		image: {
			src: node?.field_vactory_media?.thumbnail?.uri?.value?._default,
			width: node?.field_vactory_media?.thumbnail?.meta?.width || 0,
			height: node?.field_vactory_media?.thumbnail?.meta?.height || 0,
			alt: node?.field_vactory_media?.thumbnail?.meta?.alt,
		},
		category: node?.field_vactory_news_theme?.name,
		date: node.field_vactory_date,
		excerpt: truncate(stripHtml(node?.field_vactory_excerpt?.value)),
		body: node?.body?.value ? <Wysiwyg html={node?.body?.value} /> : null,
		paragraph: node?.field_vactory_paragraphs,
		vcc: node?.vcc_normalized,
	}
}

export const normalizeTerms = (terms) => {
	return terms.map((term) => ({
		id: term.drupal_internal__tid,
		name: term.name,
	}))
}

export const normalizeVCC = (nodes) => {
	return nodes?.map((post) => ({
		id: post.id,
		image: post?.image,
		category: post?.tag,
		date: post?.date?.html_date,
		title: post?.title,
		excerpt: <Wysiwyg html={truncate(stripHtml(post?.excerpt?.[0]?.value, 100))} />,
		url: post?.url,
	}))
}
