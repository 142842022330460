import { useState } from "react"
import { deserialise } from "kitsu-core"
import { useRouter } from "next/router"
import get from "lodash.get"
import { useUpdateEffect } from "@/hooks"
import { drupal } from "@/lib"

const objectMap = (obj, fn) =>
	Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

export const useCollectionContext = (data) => {
	const router = useRouter()
	const response = deserialise(get(data, "components.0.collection.data", {}))
	const nodes = response.data
	const count = response.meta.count
	const pager = parseInt(router?.query?.page || 1)
	const filters = get(data, "components.0.collection.original_filters", {})

	const terms = objectMap(data?.components?.[0]?.collection?.taxonomies, (terms) => {
		return (
			terms.map((item) => ({
				id: item.id,
				value: item.id,
				label: item.label,
				slug: item.slug,
			})) || []
		)
	})

	return { terms, nodes, count, pager, filters }
}

export const useCollectionFetcher = ({
	nodeType = "",
	initialPosts = [],
	initialPostsCount = 0,
	params = {},
}) => {
	const router = useRouter()
	const locale = router.locale
	const [inputs, setInputs] = useState({
		posts: initialPosts,
		count: initialPostsCount,
		isLoading: false,
	})

	const fetchData = async () => {
		const controller = new AbortController()
		setInputs((prev) => ({
			...prev,
			isLoading: true,
		}))

		try {
			const response = await drupal.getNodeCollection(nodeType, {
				locale: locale,
				params: params,
				signal: controller.signal,
			})
			setInputs((prev) => ({
				...prev,
				posts: deserialise(response)?.data || [],
				count: response.meta.count,
				isLoading: false,
			}))
		} catch (err) {
			// @todo: Toast & manage API errors
			console.error(err)
			setInputs((prev) => ({
				...prev,
				isLoading: false,
			}))
		}

		return () => controller.abort()
	}

	useUpdateEffect(() => {
		fetchData()
	}, [params])

	return { posts: inputs.posts, count: inputs.count, isLoading: inputs.isLoading }
}
