import { forwardRef, useRef, useState } from "react"
import { Icon } from "../icon/Icon"
import { Button } from "../button/Button"
import clsx from "clsx"
import { inputFile } from "./theme"

export const InputFile = forwardRef(
	({
		isMultiple = true,
		label,
		variant = "default",
		placeholder,
		addonAfter,
		addonBefore,
		prefix,
		hasError,
		errorMessage,
		description,
		props,
	}) => {
		const [choosedFiles, setChoosedFiles] = useState([])
		const inputFileRef = useRef()

		const onInputChange = (e) => {
			var inputElement = e.target.files
			setChoosedFiles((prev) => {
				return [...prev, inputElement[0]]
			})
		}

		const handleDeleteFile = (fileName) => {
			setChoosedFiles((prev) => {
				return prev.filter((choosedFile) => {
					return choosedFile.name !== fileName
				})
			})
		}

		const handleButtonClick = () => {
			inputFileRef.current.click()
		}

		// const handleDeleteSelectedFile = () => {
		// 	setChoosedFiles([])
		// 	inputFileRef.current.value = ""
		// }

		return (
			<div className="w-full mb-4">
				{label && (
					<div className={clsx(inputFile[variant].label)}>
						<label>{label}</label>
					</div>
				)}
				<div
					className={clsx(
						inputFile[variant].wrapper,
						hasError && inputFile[variant].hasError,
						"overflow-hidden"
					)}
				>
					{addonBefore && (
						<div className={clsx("flex", inputFile[variant].addonBefore)}>
							{addonBefore}
						</div>
					)}
					<span
						className={clsx(
							addonBefore
								? inputFile[variant].inputWrapper.inside
								: addonAfter
								? inputFile[variant].inputWrapper.left
								: addonBefore
								? inputFile[variant].inputWrapper.right
								: inputFile[variant].inputWrapper.full
						)}
					>
						{prefix && <div className={clsx(inputFile[variant].prefix)}>{prefix}</div>}
						<div className="min-w-0 relative overflow-hidden">
							<input
								multiple
								className="absolute w-0 h-0 top-0 left-0"
								type="file"
								placeholder={placeholder}
								onChange={onInputChange}
								accept={".jpg,.jpeg,.png,.pdf"}
								ref={(e) => {
									inputFileRef.current = e
								}}
								{...props}
							/>

							<div className={clsx(inputFile[variant].file)}>
								{choosedFiles !== [] ? (
									choosedFiles.map((choosedFile, index) => {
										return (
											<SelectedInput
												key={index}
												file={choosedFile.name}
												deleteFile={handleDeleteFile}
											/>
										)
									})
								) : (
									<p className="text-black">Choose a file</p>
								)}
							</div>
						</div>
					</span>

					{choosedFiles.length !== 0 && (
						<div className={clsx(inputFile[variant].sufix)}>
							<span className="bg-black text-white p-1 rounded-full text-sm w-5 h-5 flex items-center justify-center">
								{choosedFiles.length}
							</span>
						</div>
					)}

					<div className={clsx("flex", inputFile[variant].addonAfter)}>
						{isMultiple && choosedFiles.length !== 0 ? (
							<Button onClick={handleButtonClick} className="h-full">
								<Icon id="plus" className="w-5 h-5" />
							</Button>
						) : !isMultiple && choosedFiles.length !== 0 ? (
							<Button onClick={handleButtonClick} className="h-full">
								<Icon id="trash" className="w-5 h-5" />
							</Button>
						) : (
							<Button onClick={handleButtonClick} className="h-full">
								<Icon id="upload" className="w-5 h-5" />
							</Button>
						)}
					</div>
				</div>
				{errorMessage && hasError && (
					<p className={inputFile[variant].errorMessage}>{errorMessage}</p>
				)}
				{description && <p className={inputFile[variant].description}>{description}</p>}
			</div>
		)
	}
)

const formatFilename = (fileName) => {
	var split = fileName.split(".")
	var filename = split[0]
	var extension = split[1]
	if (filename.length > 10) {
		filename = filename.substring(0, 10)
	}
	return `${filename}.${extension}`
}

// const readFileAsync = (file) => {
// 	return new Promise((resolve, reject) => {
// 		let reader = new FileReader()

// 		reader.onload = () => {
// 			resolve(reader.result)
// 		}

// 		reader.onerror = reject
// 		reader.readAsArrayBuffer(file)
// 	})
// }

const SelectedInput = ({ file, deleteFile }) => {
	return (
		<div className="flex items-center gap-2 px-2 py-1.5 border border-gray-200 relative shadow rounded">
			<Icon id="document-text" className="w-4 h-4" />
			<span className="whitespace-nowrap text-xs">{formatFilename(file)}</span>
			<button
				onClick={() => {
					deleteFile(file)
				}}
				className="bg-black p-0.5 rounded-full "
			>
				<Icon id="x" className="w-2 h-2 text-white" />
			</button>
		</div>
	)
}
