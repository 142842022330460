import { Link } from "@/ui"
import Image from "next/image"

export const config = {
	id: "mre_templates:image_links",
}

function AccordionLinksWidget({ data }) {
	let nodes = data?.components.map((item) => ({
		image: {
			src: item.image[0]._default,
			alt: item.link.title,
			height: 150,
			width: 150,
		},
		link: item.link,
	}))

	return (
		<div className="grid grid-cols-2 md:grid-cols-4 place-items-center px-3 transform text-xs w-full overflow-hidden transition-all duration-300">
			{nodes.map((field, index) => (
				<div key={index} className="mx-auto">
					<div className="text-center text-base">{field.link.title}</div>
					<Link passHref href={field.link.url}>
						<a target="_blank" className="w-full h-full cursor-pointer p-2">
							<div className="border border-gray-300">
								<Image {...field.image} className="object-cover" />
							</div>
						</a>
					</Link>
				</div>
			))}
		</div>
	)
}

export default AccordionLinksWidget
