import { useRouter } from "next/router"
import { Icon, Link } from "@/ui"

export const config = {
	id: "mre_templates:share-links",
}

const ShareLinkContainer = ({ data }) => {
	const props = {
		title: data?.components?.[0]?.title || null,
		facebook: data?.components?.[0]?.facebook || false,
		twitter: data?.components?.[0]?.twitter || false,
		linkedin: data?.components?.[0]?.linkedin || false,
		whatsapp: data?.components?.[0]?.whatsapp || false,
		mail: data?.components?.[0]?.mail || false,
		pinterest: data?.components?.[0]?.pinterest || false,
	}

	return <ShareBlock {...props} />
}

const ShareBlock = ({
	title,
	facebook,
	twitter,
	linkedin,
	whatsapp,
	mail,
	pinterest,
}) => {
	return (
		<>
			<hr className="w-full h-[2px] bg-black" />
			<div className="flex py-7 md:flex-row flex-col gap-5  md:gap-8 items-center justify-center md:justify-start pb-0">
				{title && <span className="inline-block text-base font-normal">{title}</span>}
				<div className="flex items-center gap-6">
					{linkedin && <Share platform="linkedin" icon="linkedin" />}
					{facebook && <Share platform="facebook" icon="facebook" />}
					{twitter && <Share platform="twitter" icon="twitter" />}
					{mail && <Share isPopUp={false} platform="mail" icon="mail" />}
					{whatsapp && <Share isPopUp={false} platform="whatsapp" icon="chat" />}
					{pinterest && <Share platform="pinterest" icon="pinterest" />}
				</div>
			</div>
		</>
	)
}

export const Share = ({
	link = null,
	platform,
	isPopUp = true,
	icon = null,
	message,
	...props
}) => {
	const router = useRouter()
	const _link = link
		? link
		: `${process.env.NEXT_PUBLIC_BASE_URL}/${router.locale}${router.asPath}`
	const _icon = icon ? icon : platform

	const handleClick = (e) => {
		if (isPopUp) {
			e.preventDefault()
			window.open(
				shareLinks[platform](_link, (message = "")),
				"Partage links",
				"width=800, height=800"
			)
		}
	}

	return (
		<Link
			href={shareLinks[platform](_link, (message = ""))}
			onClick={(e) => handleClick(e)}
			target="_blank"
			rel="noreferrer noopener nofollow"
			ariaLabel={`Share on ${platform}`}
			title={`Share on ${platform} ${message}`}
			name={platform}
			className="inline-flex p-2 w-9 h-9  items-center justify-center"
			{...props}
		>
			<Icon id={_icon} className=" fill-current" width={20} height={20} />
		</Link>
	)
}

const shareLinks = {
	twitter: (link, message) =>
		`https://twitter.com/intent/tweet/?text=${encodeURIComponent(
			message || ""
		)}&url=${encodeURIComponent(link || "")}`,
	pinterest: (link, message) =>
		`http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
			message || ""
		)}&url=${encodeURIComponent(link || "")}`,
	whatsapp: (link, message) =>
		`whatsapp://send?text=${encodeURIComponent(message || "")}&url=${encodeURIComponent(
			link || ""
		)}`,
	facebook: (link) =>
		`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(link || "")}`,
	mail: (link, message) =>
		`mailto:?subject=${encodeURIComponent(message || "")}&body=${encodeURIComponent(
			link || ""
		)}`,
	linkedin: (link) =>
		`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
			link || ""
		)}`,
	telegram: (link, message) =>
		`https://t.me/share/url?url=${encodeURIComponent(
			link || ""
		)}&text=${encodeURIComponent(message || "")}`,
}

export default ShareLinkContainer
