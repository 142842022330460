import { Heading, Accordion, Wysiwyg, Container, Icon } from "@/ui"

export const config = {
	id: "mre_templates:accordion",
	lazy: false,
}

export const Accordions = ({ title, intro, showIntro, questions }) => {
	return (
		<div className="w-full relative overflow-hidden">
			{title && (
				<Icon
					id="awb-symbol-outline"
					className="absolute -top-[300px] opacity-50 -right-[240px]  md:-right-[570px] rimary fill-current w-[400px] md:w-[950px] h-[600px]"
				/>
			)}
			<Container>
				{title && (
					<Heading level="2" variant="mre" className="mb-8 enter">
						{title}
					</Heading>
				)}
				{showIntro && <>{intro}</>}
				{questions && (
					<Accordion variant="default" onlyOneOpen="true" nodes={questions} />
				)}
			</Container>
		</div>
	)
}

/*

*/

const AccordionContainer = (data) => {
	const props = {
		title: data.data.extra_field.bigtitle,
		showIntro: !!data.data.extra_field.intro.value["#text"],
		intro: (
			<Wysiwyg
				html={data.data.extra_field.intro.value["#text"]}
				className="flex flex-col mb-6"
			/>
		),
		questions: data.data.components.map((question, index) => {
			return {
				id: index,
				button: question.title,
				panel: (
					<Wysiwyg
						html={question.description.value["#text"]}
						className="flex flex-col gap-4"
					/>
				),
			}
		}),
	}
	return <Accordions {...props} />
}

export default AccordionContainer
