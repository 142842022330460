export const input = {
	mre: {
		container: "md:flex md:justify-between md:flex-wrap",
		wrapper: "w-full md:w-7/12",
		inputWrapper: {
			full: "",
			right: "",
			left: "",
			inside: "",
		},
		label: "w-5/12",
		addonBefore: "",
		addonAfter: "",
		input: "w-full focus:border-secondary focus:ring-0 text-sm grow",
		prefix: "absolute flex items-center left-3 my-auto top-0 bottom-0",
		sufix: "absolute flex items-center right-3 my-auto top-0 bottom-0",
		errorMessage: "text-xs text-red-600 mt-1 text-left md:ml-auto md:w-7/12",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-gray-400 mt-1 w-7/12 ml-auto rtl:mr-auto rtl:ml-0",
	},
	outlined: {
		container: "relative flex",
		wrapper: "flex w-full",
		input:
			"flex h-full w-full text-base text-white px-5 py-3 leading-none placeholder:text-white border border-opacity-50 bg-white bg-opacity-25 focus:border-secondary focus:ring-secondary border-white",
		inputWrapper: {
			full: "flex w-full h-full",
			right: "",
			left: "",
			inside: "",
		},
		margin: "mb-0",
		errorMessage:
			"absolute left-0 -bottom-4 text-xs text-red-600 mt-1 text-left md:ml-auto md:w-7/12",
		hasError: "!border-red-500 hover:!border-red-600 border-opacity-100 ring-red-400",
	},
	auth: {
		container: "md:flex md:justify-between md:flex-wrap",
		wrapper: "w-full",
		margin: "mb-0",
		inputWrapper: {
			full: "",
			right: "",
			left: "",
			inside: "",
		},
		label: "w-5/12",
		addonBefore: "",
		addonAfter: "",
		input:
			"w-full border-2 border-gray-300 py-2.5  focus:border-secondary  focus:ring-0 text-sm grow",
		prefix: "absolute flex items-center left-3 my-auto top-0 bottom-0",
		sufix: "absolute flex items-center right-3 my-auto top-0 bottom-0",
		errorMessage: "text-xs text-red-600 mt-1 text-left",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-gray-400 mt-1 w-7/12 ml-auto rtl:mr-auto rtl:ml-0",
	},
	"search-forum": {
		container: "md:flex md:justify-between md:flex-wrap",
		wrapper:
			"w-full relative border-2 border-gray-300 focus-within:border-secondary flex items-center pl-2",
		margin: "mb-0",
		inputWrapper: {
			full: "w-full",
			right: "w-full",
			left: "w-full",
			inside: "w-full",
		},
		label: "w-5/12",
		addonBefore: " ",
		addonAfter: "",
		input: "w-full py-[11px] border-0 focus:ring-0 text-sm grow",
		prefix: "absolute flex items-center left-3 my-auto top-0 bottom-0",
		sufix: "absolute flex items-center right-3 my-auto top-0 bottom-0",
		errorMessage: "text-xs text-red-600 mt-1 w-full ml-auto rtl:mr-auto rtl:ml-0",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-gray-400 mt-1 w-7/12 ml-auto rtl:mr-auto rtl:ml-0",
	},
	comment: {
		container: "md:flex md:justify-between md:flex-wrap",
		wrapper: "w-full",
		margin: "mb-0",
		inputWrapper: {
			full: "",
			right: "",
			left: "",
			inside: "",
		},
		required: "font-bold text-sm ml-2",
		label: "block text-gray-400 text-sm mb-0.5",
		addonBefore: "",
		addonAfter: "",
		input:
			"w-full border-[1.5px] border-gray-300 py-2.5  focus:border-secondary  focus:ring-0 text-sm grow",
		prefix: "absolute flex items-center left-3 my-auto top-0 bottom-0",
		sufix: "absolute flex items-center right-3 my-auto top-0 bottom-0",
		errorMessage: "text-xs text-red-600 mt-1 w-full ml-auto rtl:mr-auto rtl:ml-0",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-gray-400 mt-1 w-7/12 ml-auto rtl:mr-auto rtl:ml-0",
	},
	default: {
		container: "relative w-full",
		wrapper: "relative flex w-full border border-gray-200 hover:border-gray-400",
		inputWrapper: {
			full: "flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400",
			right: "flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400",
			left: "flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400",
			inside:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400",
		},
		label: "inline-block text-sm font-medium text-gray-700 mb-1",
		addonBefore: "bg-gray-200 bg-gray-200 flex items-center max-w-[30%]",
		addonAfter: "bg-gray-200 flex items-center max-w-[30%]",
		input:
			"appearance-none py-3 px-3 w-full placeholder-gray-500 outline-none grow border-none focus:ring-0 text-sm",
		prefix: "absolute flex items-center left-3 my-auto top-0 bottom-0",
		sufix: "absolute flex items-center right-3 my-auto top-0 bottom-0",
		errorMessage: "text-xs text-red-600 mt-1",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-gray-400 mt-1",
	},
	rounded: {
		container: "relative w-full",
		wrapper:
			"relative flex w-full border border-gray-200 hover:border-gray-300 rounded-lg",
		inputWrapper: {
			full: "flex grow relative w-full focus-within:ring-1 focus-within:ring-indigo-400 rounded-lg",
			right:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-indigo-400 rounded-r-lg",
			left: "flex grow relative w-full focus-within:ring-1 focus-within:ring-indigo-400 rounded-l-lg",
			inside:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-indigo-400",
		},
		label: "text-sm font-medium text-gray-700 pb-3",
		addonBefore: "bg-gray-200 rounded-l-lg bg-gray-200 flex items-center max-w-[30%]",
		addonAfter: "bg-gray-200 rounded-r-lg flex items-center max-w-[30%]",
		input:
			"appearance-none py-3 px-10 w-full placeholder-gray-500 outline-none grow rounded-lg border-none focus:ring-0 text-sm",
		prefix: "absolute flex items-center left-3 my-auto top-0 bottom-0",
		sufix: "absolute flex items-center right-3 my-auto top-0 bottom-0",
		errorMessage: "text-xs text-red-600 mt-1",
		hasError: "border-red-500 hover:border-red-600 focus-within:border-red-400",
		description: "text-sm text-gray-400 mt-1",
	},
	inline: {
		container: "relative flex items-center relative w-full",
		wrapper:
			"relative flex w-full border border-gray-200 hover:border-gray-300 rounded-lg",
		inputWrapper: {
			full: "flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400 rounded-lg",
			right:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400 rounded-r-lg",
			left: "flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400 rounded-l-lg",
			inside:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400",
		},
		label: "whitespace-nowrap text-sm font-medium text-gray-700 mr-6",
		addonBefore: "bg-gray-200 rounded-l-lg bg-gray-200 flex items-center max-w-[30%]",
		addonAfter: "bg-gray-200 rounded-r-lg flex items-center max-w-[30%]",
		input:
			"appearance-none py-3 px-3 w-full placeholder-gray-500 outline-none grow rounded-md border-none focus:ring-0 text-sm",
		prefix: "flex items-center pl-3",
		sufix: "flex items-center pr-3",
		errorMessage: "text-xs text-red-600 mt-1",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-gray-400 mt-1",
	},
	overlay: {
		margin: "mb-0",
		container: "relative flex items-center relative w-full",
		wrapper: "relative flex w-full border-none",
		inputWrapper: {
			full: "flex grow relative w-full rounded-lg",
			right: "flex grow relative w-full",
			left: "flex grow relative w-full",
			inside:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400",
		},
		label: "whitespace-nowrap text-sm font-medium text-gray-700 mr-6",
		addonBefore: "bg-gray-200 rounded-l-lg bg-gray-200 flex items-center max-w-[30%]",
		addonAfter: "bg-gray-200 rounded-r-lg flex items-center max-w-[30%]",
		input:
			"appearance-none py-[10px] px-[10px] h-[51px] w-full placeholder-white outline-none grow border border-white focus:border-secondary focus:ring-0 text-[14px] bg-search-input text-white",
		prefix: "flex items-center",
		sufix: "flex items-center",
		errorMessage: "top-full text-lg text-red-600 mt-1",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-white mt-3 text-right",
	},
	search: {
		margin: "mb-0",
		container: "relative flex items-center relative w-full",
		wrapper: "relative flex w-full border-none",
		inputWrapper: {
			full: "flex grow relative w-full rounded-lg",
			right: "flex grow relative w-full",
			left: "flex grow relative w-full",
			inside:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-yellow-400",
		},
		label: "whitespace-nowrap text-sm font-medium text-gray-700 mr-6",
		addonBefore: "bg-gray-200 rounded-l-lg bg-gray-200 flex items-center max-w-[30%]",
		addonAfter: "bg-gray-200 rounded-r-lg flex items-center max-w-[30%]",
		input:
			"appearance-none py-[13px] mb-[4px] px-5 w-full h-full placeholder-gray-400 outline-none grow border-none focus:ring-0 text-sm bg-transparent",
		prefix: "flex items-center pl-3",
		sufix: "flex items-center",
		errorMessage: "absolute top-0 top-full text-sm text-red-600 mt-1",
		hasError: "border-red-500 hover:border-red-600 focus-within:ring-red-400",
		description: "text-sm text-white mt-3 text-right",
	},
	chaabi: {
		container: "relative w-full",
		wrapper:
			"relative flex w-full border border-gray-200 hover:border-gray-300 rounded-[4px]",
		inputWrapper: {
			full: "flex grow relative w-full focus-within:ring-1 focus-within:ring-primary-400 rounded-[4px]",
			right:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-primary-400 rounded-r-[4px]",
			left: "flex grow relative w-full focus-within:ring-1 focus-within:ring-primary-400 rounded-l-[4px]",
			inside:
				"flex grow relative w-full focus-within:ring-1 focus-within:ring-primary-400",
		},
		label: "inline-block text-sm font-medium text-gray-700 pb-[6px]",
		addonBefore: "bg-gray-200 rounded-l-[4px] bg-gray-200 flex items-center max-w-[30%]",
		addonAfter: "bg-gray-200 rounded-r-[4px] flex items-center max-w-[30%]",
		input:
			"appearance-none py-3 px-[14px] w-full placeholder-gray-500 outline-none grow rounded-[4px] border-none focus:ring-0 text-sm text-gray-500",
		prefix: "absolute flex items-center left-3 my-auto top-0 bottom-0",
		sufix: "absolute flex items-center right-3 my-auto top-0 bottom-0",
		errorMessage: "text-xs text-red-600 mt-1",
		hasError: "border-red-500 hover:border-red-600 focus-within:border-red-400",
		description: "text-sm text-gray-400 mt-1",
	},
}
