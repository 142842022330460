import { Link, Icon, Heading } from "@/ui"
import { useI18n } from "@/hooks"
import clsx from "clsx"
import Image from "next/image"

const CardImage = ({ image, alt = "", ...rest }) => {
	if (!image) {
		return <></>
	}
	return (
		<div style={{ position: "relative", height: "190px" }}>
			<Image
				src={image?._default}
				alt={alt || "conseils card image"}
				layout="fill"
				className="object-cover"
				{...rest}
			/>
		</div>
	)
}

export const ConseilsCard = ({
	title,
	className,
	image,
	theme,
	created,
	url,
	excerpt,
	premium,
	...rest
}) => {
	const { t } = useI18n()

	return (
		<div
			className={clsx(
				"flex flex-col bg-white p-0 m-0  overflow-hidden text-black text-left w-full",
				className
			)}
			style={{
				boxShadow: "0px 4px 4px rgba(0,0,0,0.35)",
			}}
			{...rest}
		>
			<Link href={url || ".#"}>
				<div className="flex-shrink-0 w-full">
					{<CardImage image={image} alt={title} />}
				</div>
			</Link>
			<div className="px-[25px] pt-[25px] pb-[18px] flex  flex-col h-full">
				{(theme || created) && (
					<div className="flex items-center justify-between mb-[12px]">
						<div className="w-full flex text-xs items-center gap-4">
							<p className="tags uppercase font-semibold text-secondary">{theme}</p>
							<div className="w-px h-6 bg-black"> </div>
							<p className="text-light leading-[18px]">{created}</p>
						</div>
						{premium && (
							<div className="border border-black p-1">
								<Icon id="lock-2" className="rtl-icon" width="15" height="15" />
							</div>
						)}
					</div>
				)}
				<Link href={url} className="block mt-4">
					<>{title && <Heading level={5}>{title}</Heading>}</>
				</Link>
				{excerpt && <p className="card-paragraph">{excerpt}</p>}
				{url && (
					<div className="mt-auto">
						<Link href={url || ".#"} variant="permalink" className="uppercase">
							{t("Nx: Savoir plus")}
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}
