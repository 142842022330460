import { default as NextLink } from "next/link"
import { forwardRef } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { link } from "./theme"
import { Icon } from "../icon/Icon"

// https://github.com/makeswift/makeswift/blob/main/packages/runtime/src/components/shared/Link/index.tsx
const Link = forwardRef(
	(
		{
			href = "#.",
			children,
			className = "",
			variant = "default",
			color = "black",
			...rest
		},
		ref
	) => {
		return (
			<NextLink ref={ref} href={href} passHref>
				<a
					className={clsx(className, link[variant]?.base, link[variant]?.hover?.[color])}
					{...rest}
				>
					{variant === "permalink" && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className={clsx("svg-1 h-4 w-4 mr-3 overflow-visible rtl-icon")}
							viewBox="0 0 572 512"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								d="M8.82 66.505L66.504 8.821l239.643 239.643-4.459 4.403 4.418 4.414L66.504 496.883 8.82 439.2l186.372-186.363L8.82 66.504z"
								className={clsx(
									"transition-transform duration-300 ease-in-out md:transform group-hover:translate-x-1/2",
									color === "black" ? "fill-secondary" : "fill-white"
								)}
							/>
							<path
								d="M266.113 66.505l57.684-57.684 239.644 239.643-4.457 4.373 4.416 4.444-239.602 239.602-57.684-57.683 186.344-186.363L266.114 66.504z"
								className={clsx(
									"transition-transform duration-300 ease-in-out md:transform group-hover:-translate-x-1/2",
									// `${
									// colorClass
									// ? "group-hover:text-secondary"
									// : "group-hover:text-inherit"
									// }`,
									color === "black" ? "fill-secondary" : "fill-white"
								)}
							/>
						</svg>
					)}

					{variant === "permalink-besoin" && (
						<Icon
							id="double-chevron-right"
							width="16px"
							height="16px"
							className={link[variant]?.icon}
						/>
					)}

					{children}
				</a>
			</NextLink>
		)
	}
)

Link.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
}

export { Link }
