import { Heading } from "@/ui"

export const FieldSet = (props) => {
	const {
		title,
		title_display,
		description,
		description_display,
		displayDivider = true,
		classes,
		children,
	} = props

	return (
		<fieldset className={classes}>
			{displayDivider && <hr className="mx-8 md:mx-16 my-10" />}
			{(title_display !== "invisible" || title_display === "") && (
				<>
					{/* <legend>{title}</legend> */}
					<Heading
						className="text-left"
						variant="mre"
						level="3"
						icon="left"
						iconClassName="mt-0 mb-[24px]"
					>
						{title}
					</Heading>
				</>
			)}
			{description_display == "before" && <p>{description}</p>}
			{children}
			{description_display == "after" && <p>{description}</p>}
		</fieldset>
	)
}
