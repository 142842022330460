import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { useI18n as useTranslation } from "@/hooks"
import { toRegister } from "../utils/toRegister"
import { Input } from "@/ui"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const NumberField = ({ name, field }) => {
	const {
		label,
		placeholder,
		htmlInputType,
		helperText,
		validation,
		shouldDisplay,
		states,
	} = field
	const { register, watch } = useFormContext()
	const errorMessage = useErrorMessage(name, label)
	const { t } = useTranslation()
	const values = watch({ nest: true })
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	const local_state = useCheckConditions(states)

	if (!isVisible) return null
	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	return (
		<Input
			id={name}
			type={htmlInputType || "number"}
			name={name}
			label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
			variant="default"
			placeholder={placeholder}
			{...register(
				name,
				toRegister(t("Nx:" + label), local_validation?.required, values, t)
			)}
			hasError={!!errorMessage}
			errorMessage={errorMessage}
			description={helperText}
		/>
	)
}
