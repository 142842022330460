import Image from "next/image"
import clsx from "clsx"
import { Link, Icon } from "@/ui"
import { useI18n } from "@/hooks"

const CardImage = ({ image, alt = "", ...rest }) => {
	if (!image) {
		return <></>
	}
	return (
		<div style={{ position: "relative", height: "150px" }}>
			<Image
				src={image?._default}
				alt={alt}
				layout="fill"
				className="object-cover"
				{...rest}
			/>
		</div>
	)
}

export const Card = ({
	image,
	category,
	date,
	className,
	url,
	title,
	excerpt,
	...rest
}) => {
	const { t } = useI18n()
	return (
		<div
			className={clsx(
				"flex flex-col rounded-lg bg-white border border-[#F2F4F7] overflow-hidden text-black text-left",
				className
			)}
			{...rest}
		>
			<div className="flex-shrink-0 w-full">
				{<CardImage image={image} alt={title} />}
			</div>
			<div className="p-[25px] flex flex-col h-full">
				{(category || date) && (
					<div className="flex items-center flex-row mb-[18px]">
						<p className="tags text-base font-semibold text-primary-600 hover:text-primary-300">
							{category}
						</p>
						{date && (
							<p className="text-gray-300 text-xs leading-[18px] px-[10px] ml-[10px] border-l border-l-gray-300">
								{date}
							</p>
						)}
					</div>
				)}
				<Link href={url} className="block">
					<>
						{title && (
							<h3 className="text-xl leading-[30px] tracking-[1px] font-semibold text-primary-900 mb-[18px] hover:text-primary-500">
								{title}
							</h3>
						)}
						{excerpt && (
							<p className="text-sm leading-[21px] text-gray-500 mb-[25px]">{excerpt}</p>
						)}
					</>
				</Link>
				{url && (
					<div className="mt-auto">
						<Link href={url || ".#"} variant="permalink" className="">
							{t("Nx:En savoir plus")}
							<Icon id="arrow-right" className="rtl-icon" width="20" height="20" />
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}
