export const container = {
	default: "container px-4",
	container: "container",
	"select-pays": "max-w-full sm:max-w-md w-full sm:mx-auto md:max-w-5xl",
	fluid: "px-4 w-full",
	full: "w-full",
}

export const ContainerSpace = {
	default: "",
	forum: "pb-10",
	big_space: "py-10 md:py-16",
	small_space: "py-6 md:py-10",
	no_space: "py-0",
}
