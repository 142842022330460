import { BlocksController } from "@/blocks"
import { NodePageProvider } from "@/context"
import { BackToTop, Icon } from "@/ui"

export function Layout({ children, ...props }) {
	return (
		<>
			<NodePageProvider node={props?.node || {}} systemRoute={props?.systemRoute || {}}>
				<BlocksController region="top" />
				<BlocksController region="header" />
				<BlocksController region="bridge" />
				{children}
				<BlocksController region="footer" />
				<BlocksController region="bottom" />
				<BackToTop
					variant="mre"
					icon={
						<Icon
							id="chevron-up"
							className="text-black fill-current md:h-4 md:w-4 w-3 h-3 group-hover:text-white"
						/>
					}
				></BackToTop>
			</NodePageProvider>
		</>
	)
}
