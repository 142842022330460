import { forwardRef, useState } from "react"
import clsx from "clsx"
import { textarea } from "./theme"

const decodeDescription = (description) => {
	const codes = [
		"&eacute;",
		"&agrave;",
		"&egrave;",
		"&ecirc;",
		"&euml;",
		"&ccedil;",
		"&ocirc;",
		"&icirc;",
		"&acirc;",
		"&ucirc;",
	]
	const decoded = ["é", "à", "è", "ê", "ë", "ç", "ô", "î", "â", "û"]

	for (let i = 0; i < codes.length; i++) {
		description = description.replaceAll(codes[i], decoded[i])
	}

	console.log(description)

	return description
}

export const Textarea = forwardRef(
	(
		{
			variant = "default",
			placeholder,
			handleTextareaChange,
			label,
			description,
			hasError,
			errorMessage,
			rows = 1,
			resize = true,
			showCounter = false,
			maxLength = null,
			required,
			...rest
		},
		ref
	) => {
		const [length, setLength] = useState(0)
		const onTextareaChange = (e) => {
			var inputValue = e.target.value
			setLength(inputValue.split("").length)
			handleTextareaChange?.(e.target.value)
		}
		return (
			<div
				className={clsx(
					"relative w-full",
					textarea[variant]?.container,
					textarea[variant]?.margin ? textarea[variant]?.margin : "mb-4"
				)}
			>
				{label && (
					<label className={clsx(textarea[variant].label)}>
						{label}
						{required && (
							<span
								className={clsx(
									textarea[variant].required
										? textarea[variant].required
										: "font-bold mx-1 text-red-500"
								)}
								title="This field is required"
							>
								*
							</span>
						)}
					</label>
				)}
				<textarea
					ref={ref}
					className={clsx(
						textarea[variant].base,
						!resize && textarea[variant].resize,
						hasError && textarea[variant].hasError
					)}
					placeholder={placeholder}
					onChange={(e) => {
						onTextareaChange(e)
					}}
					rows={rows}
					maxLength={showCounter ? maxLength : null}
					{...rest}
				></textarea>
				{showCounter && maxLength !== null && (
					<span className="absolute bottom-2 right-2 text-sm">{`${length}/${maxLength}`}</span>
				)}
				{errorMessage && hasError && (
					<p className={clsx(textarea[variant].errorMessage, !label && "ml-0 md:ml-0")}>
						{errorMessage}
					</p>
				)}
				{description && (
					<p className={textarea[variant].description}>
						{decodeDescription(description)}
					</p>
				)}
			</div>
		)
	}
)

export default Textarea
