import { useState } from "react"
import clsx from "clsx"
import { Icon } from "../icon/Icon"
import { accordion } from "./theme"

export const Accordion = ({ variant = "default", nodes = [], onlyOneOpen = true }) => {
	// State & Functionality for the selected accordion if onlyOneOpen is TRUE
	const [selected, setSelected] = useState(0)
	const toggleItem = (i) => {
		setSelected(null)
		const _time = setTimeout(() => {
			selected !== i ? setSelected(i) : setSelected(null)
			clearTimeout(_time)
		}, 350)
	}

	// State for the selected accordion if onlyOneOpen is FALSE
	// Contains an Array of the opened accordions IDs
	const [openedAccordions, setOpenedAccordions] = useState([])
	return (
		<div className={accordion[variant].wrapper}>
			{nodes.map((item, i) => {
				// Functionality to style the panel's button & body and animate them depends if the accordion is open or closed
				const calculatedStyle = (el) => {
					if (onlyOneOpen) {
						return selected == i ? el.active : el.inactive
					} else {
						return openedAccordions.includes(i) ? el.active : el.inactive
					}
				}

				// Functionality to change the accordion's icon depends if it's open or closed
				const switchIcon = (icon) => {
					if (onlyOneOpen) {
						return selected == i ? icon.activeId : icon.inactiveId
					} else {
						return openedAccordions.includes(i) ? icon.activeId : icon.inactiveId
					}
				}

				return (
					<div className={accordion[variant].element} key={item.id}>
						<button
							className={clsx(
								accordion[variant].button.base,
								calculatedStyle(accordion[variant].button)
							)}
							onClick={() => {
								// To handle openign and closing the accordion if onlyOneOpen == TRUE
								onlyOneOpen && toggleItem(i)
								// To handle openign and closing the accordion if onlyOneOpen == FALSE
								!onlyOneOpen && openedAccordions.includes(i)
									? // Checks if the openedAccordions array has the clicked accordion's ID
									  // if TRUE, remove it from the array
									  setOpenedAccordions(openedAccordions.filter((e) => e !== i))
									: // if FALSE, Add it to the array
									  setOpenedAccordions([...openedAccordions, i])
							}}
						>
							<div className="text-lg leading-7">{item.button}</div>
							{accordion[variant].button.icon && (
								<Icon
									id={switchIcon(accordion[variant].button.icon)}
									className={clsx(
										accordion[variant].button.icon.base,
										switchIcon(accordion[variant].button.icon) === "minus" &&
											"text-secondary fill-current"
									)}
									width={accordion[variant].button.icon.width}
									height={accordion[variant].button.icon.height}
								/>
							)}
						</button>
						<div
							className={clsx(
								accordion[variant].panel.base,
								calculatedStyle(accordion[variant].panel)
							)}
						>
							{item.panel}
						</div>
					</div>
				)
			})}
		</div>
	)
}
