import { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import Image from "next/image"
import { useMedia } from "react-use"
import { Icon, Link, Container } from "@/ui"
import { useI18n, useVideo, useNode } from "@/hooks"
import { Widgets } from "@/runtime/widgets"
import clsx from "clsx"
import { YouTubeGetID } from "@/lib"

export const config = {
	id: "mre_templates:slider_HP",
}

const Slide = ({
	slide,
	currentSlide,
	count,
	nextSlide,
	prevSlide,
	isSubSlider = false,
}) => {
	const { t } = useI18n()
	const isMobile = useMedia("(max-width: 640px)")
	const [videoIsReady, setVideoIsReady] = useState(false)

	const { setIsOpen, setSource } = useVideo()

	return (
		<div
			className={`keen-slider__slide relative flex items-center w-full justify-center overflow-hidden`}
		>
			{slide.video ? (
				<>
					<video
						autoPlay
						loop
						muted
						className="absolute top-0 left-0 object-cover w-auto md:w-full h-[680px] sm:h-[602px] md:h-[925px] lg:h-[921px] z-[1]"
						onPlay={() => {
							setVideoIsReady(true)
						}}
					>
						<source src={slide.video} type="video/mp4" />
					</video>
					{!videoIsReady && slide.background_desktop.url && (
						<Image
							alt={slide?.background_desktop?.alt || "hero banner desktop"}
							priority={slide.priority}
							src={
								isMobile && slide?.background_mobile?.url
									? slide.background_mobile.url
									: slide.background_desktop.url
							}
							className="absolute top-0 right-0 object-cover"
							layout="fill"
						/>
					)}
				</>
			) : (
				slide.background_desktop.url && (
					<Image
						alt={slide?.background_mobile?.alt || "hero banner mobile"}
						priority={slide.priority}
						src={
							isMobile && slide?.background_mobile?.url
								? slide.background_mobile.url
								: slide.background_desktop.url
						}
						className="absolute top-0 right-0 object-cover"
						layout="fill"
					/>
				)
			)}

			<div className="relative w-full h-full z-2">
				{/* layer overlay */}
				<div className="bg-black/60 absolute top-0 right-0 w-full h-full"></div>
				<div className="relative w-full h-full flex">
					<div className="md:container">
						<div
							className={clsx(
								"flex flex-col pt-[106px] md:pt-[258px] lg:pt-[282px] md:w-3/4 p-[10px] md:px-0 ",
								isSubSlider ? "pb-[306px] md:pb-[339px]" : "pb-12"
							)}
						>
							<div className="-mx-3 w-full flex justify-start items-center text-white p-3">
								<Icon
									id="chevron-left"
									className="text-white fill-current cursor-pointer rtl-icon"
									width="12px"
									height="12px"
									onClick={() => prevSlide()}
								/>
								<span className="text-xs leading-none ml-[12px] mr-[8px] tracking-[2px] mt-[2px]">
									{currentSlide + 1}/{count}
								</span>
								<Icon
									id="chevron-right"
									className="text-white fill-current cursor-pointer rtl-icon"
									width="12px"
									height="12px"
									onClick={() => nextSlide()}
								/>
							</div>
							<h1 className="text-2xl leading-8 mb-1 md:mb-0 text-white md:text-5xl font-bold md:max-w-xl">
								{slide.title}
							</h1>
							<div className="wysiwyg-content mt-2 md:mt-6 text-sm md:text-xl text-white">
								<p>{slide.subtitle}</p>
							</div>
							{slide.video_modal && (
								<>
									<button
										onClick={() => {
											setSource(YouTubeGetID(slide.video_modal))
											setIsOpen(true)
										}}
										className="mt-3 md:mt-6 mb-[14px] md:mb-[20px] w-fit inline-flex items-center justify-center hover:bg-opacity-10 text-white uppercase text-sm font-bold leading-none"
									>
										<Icon
											id="play-rounded"
											width="44px"
											height="44px"
											className="text-white fill-current rtl-icon mr-5"
										/>
										{t("Nx:Voir la vidéo")}
									</button>
								</>
							)}

							{slide.link.url && !slide.video_modal && (
								<Link
									variant="permalink"
									color="white"
									href={slide.link.url}
									className="mt-3 md:mt-6 text-white"
								>
									{slide.link.title}
								</Link>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const SubSlider = ({ block }) => {
	const { widget_id, widget_data } = block.content
	const Component = Widgets[widget_id]
	return (
		<div className="absolute bottom-0 left-0 w-full white-heading border-t border-white border-opacity-50 md:max-h-none xs:min-h-[284px] xs:max-h-[284px] max-h-[300px] min-h-[300px]">
			<Container layout="full" spacing="no_space">
				<Component data={JSON.parse(widget_data)} hasAMP={false} key={block.id} />
			</Container>
		</div>
	)
}

const SliderFullBackground = ({ data }) => {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [sliderCreated, setSliderCreated] = useState(false)

	const slidesData = data.components.map((item, index) => {
		var slideData = {}
		slideData.priority = index === currentSlide
		slideData.title = item.title
		slideData.subtitle = item.subtitle
		slideData.content = item.content
		slideData.background_desktop = {
			url: item?.image?.[0]?._default,
			alt: item?.image?.[0]?.meta?.alt,
		}
		slideData.background_mobile = {
			url: item?.image_mobile?.[0]?._default,
			alt: item?.image_mobile?.[0]?.meta?.alt,
		}
		slideData.link = { title: item.link.title, url: item.link.url }
		if (item.video) {
			slideData.video = item?.video?.[0]?._default
		}

		if (item.video_modal) {
			slideData.video_modal = item?.video_modal?.url
		}
		return slideData
	})

	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		loop: true,
		created: () => {
			setSliderCreated(true)
		},
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel)
		},
	})

	const slideCount = slidesData.length

	const { blocks } = useNode()
	const block = blocks.filter((block) => block.region === "custom")[0]

	return (
		<>
			<div className="navigation-wrapper relative">
				<div
					ref={sliderRef}
					className={`keen-slider relative overflow-x-hidden h-full ${
						!sliderCreated
							? "flex-wrap flex"
							: "h-[680px] sm:h-[602px] md:h-[925px] lg:h-[921px] overflow-y-hidden"
					}`}
				>
					{slidesData.map((slide, index) => {
						return (
							<Slide
								key={index}
								currentSlide={currentSlide}
								slideId={index}
								slide={slide}
								count={slideCount}
								nextSlide={slideCount > 1 ? () => instanceRef.current.next() : () => {}}
								prevSlide={slideCount > 1 ? () => instanceRef.current.prev() : () => {}}
								isSubSlider={block ? true : false}
							/>
						)
					})}
				</div>

				{block && <SubSlider block={block} />}
			</div>
		</>
	)
}
export default SliderFullBackground
