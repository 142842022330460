import { deserialise } from "kitsu-core"
import get from "lodash.get"
import {
	Button,
	// Link,
	Wysiwyg,
	Heading,
	Text,
} from "@/ui"
import { normalizeNodes } from "./normalizer"
import { ConseilsNodeQueueCard } from "./ConseilsNodeQueueCard"

const NodeQueue = ({ data }) => {
	const title = get(data, "extra_field.title", "")
	const raw_description = get(data, "extra_field.description.value.#text", null)
	const description = <Wysiwyg html={raw_description} />
	const link = get(data, "extra_field.link.url", null)
	const link_label = get(data, "extra_field.link.title", "")
	let nodes = []
	data?.components.map((item) => {
		const items = deserialise(get(item, "nodes.data", {}))
		nodes = [...nodes, ...items?.data]
	})

	nodes = normalizeNodes(nodes)

	return (
		<div className="relative max-w-7xl mx-auto lg:px-48">
			<div className="text-center">
				{title && (
					<Heading variant="mre" level="2" className="text-center mb-5">
						{title}
					</Heading>
				)}
				{raw_description.length > 0 && (
					<Text className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
						{description}
					</Text>
				)}
			</div>
			{nodes.length > 0 && (
				<div className="mt-12 space-y-3 md:space-y-0 md:grid gap-5 grid-cols-3">
					{nodes.map((post, i) => (
						<div key={post.id} className={`flex ${!i ? "col-span-2" : ""}`}>
							<ConseilsNodeQueueCard {...post} index={i} />
						</div>
					))}
				</div>
			)}
			{link && (
				<div className="flex justify-center mt-12">
					<Button variant="mre" href={link}>
						{link_label}
					</Button>
				</div>
			)}
		</div>
	)
}

export const config = {
	id: "mre_templates:conseils_two_col_queue",
}

export default NodeQueue
