import { forwardRef } from "react"
import { Checkbox } from "../checkbox/Checkbox"
import { checkboxes } from "./theme"
import { Wysiwyg } from "@/ui"

export const Checkboxes = forwardRef(
	(
		{
			variant = "default",
			label,
			hasError,
			errorMessage,
			checkboxesData,
			checked, // must contain the array of the seleted values
			setChecked,
			description,
			...rest
		},
		ref
	) => {
		return (
			<div className={checkboxes[variant].wrapper}>
				<label className={checkboxes[variant].label}>{label}</label>
				<div className={checkboxes[variant].checkboxesContainer}>
					{checkboxesData.map((checkbox, index) => {
						return (
							<Checkbox
								key={index}
								ref={ref}
								{...rest}
								variant={variant}
								label={checkbox.label}
								value={checkbox.value}
								checked={checked.includes(checkbox.value)}
								setChecked={setChecked}
							/>
						)
					})}
				</div>
				{hasError && (
					<span className={checkboxes[variant].errorMessage}>{errorMessage}</span>
				)}
				{description && (
					<Wysiwyg
						className="text-xs text-gray-400"
						textVariant="small"
						html={description}
					/>
				)}
			</div>
		)
	}
)
