export const toRegister = (fieldLabel, validation, values, t) => {
	let register = {}

	if (validation?.sameAs) {
		register.validate = {}
	}

	if (validation?.required) {
		register.required = validation?.requiredError
			? t("Nx:" + validation?.requiredError)
			: t("Nx: Le champ") + " " + fieldLabel + " " + t("Nx: est requis.")
	} else {
		register.required = false
	}

	if (validation?.pattern) {
		let match = validation.pattern.match(new RegExp("^/(.*?)/([gimy]*)$"))
		if (match === null) {
			match = [null, validation.pattern, ""]
		}
		const regex = new RegExp(match[1], match[2])
		register.pattern = {
			value: regex,
			message: validation?.patternError
				? t("Nx:" + validation.patternError)
				: t("Nx: Le champ") + " " + fieldLabel + " " + t("Nx: est invalid."),
		}
	}

	if (validation?.sameAs && values[validation?.sameAs]) {
		const message = validation?.sameAsError
			? t("Nx:" + validation.sameAsError)
			: t("Nx: Le champ") + " " + fieldLabel + " " + t("Nx: est invalid.")
		register.validate.sameAs = (value) => value === values[validation?.sameAs] || message
	}

	return { ...register }
}
