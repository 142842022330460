import { forwardRef } from "react"
import clsx from "clsx"
import { Icon } from "@/ui"

const IconLeft = () => <Icon id="chevron-left" width="28" height="28" />

const IconRight = () => <Icon id="chevron-right" width="28" height="28" />

export const SliderArrows = forwardRef(
	(
		{
			arrowsSettings,
			onClick,
			className = "",
			left,
			right,
			iconLeft = <IconLeft />,
			iconRight = <IconRight />,
			...props
		},
		ref
	) => {
		return (
			<button
				onClick={onClick}
				ref={ref}
				className={clsx(
					className,
					"mx-auto bottom-0 flex justify-center items-center w-8 h-8 border-2 border-black text-black bg-transparent rounded transition hover:bg-black hover:text-white lg:absolute lg:top-1/2 lg:-translate-y-1/2",
					left && "left-0",
					right && "right-0",
					arrowsSettings.hideArrowMobile ? "onlyMobile:hidden" : null,
					arrowsSettings.hideArrowTablet ? "onlyTablet:hidden" : null,
					arrowsSettings.hideArrowDesktop ? "onlyDesktop:hidden" : null
				)}
				{...props}
			>
				{left && iconLeft && iconLeft}
				{right && iconRight && iconRight}
			</button>
		)
	}
)
