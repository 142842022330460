import { useEffect, useRef } from "react"
import { Introduction } from "@/ui"

export const config = {
	id: "mre_templates:introduction",
}

function IntroductionWidget({ data }) {
	const introRef = useRef(null)

	useEffect(() => {
		if (introRef.current) {
			introRef.current.closest(".paragraph--vactory_component").classList.add("bg-[#eee]")
		}
	}, [introRef])

	const content = data?.components[0]?.content || ""
	return (
		<div ref={introRef} className="mx-auto md:max-w-[85%] max-w-[90%]">
			<Introduction variant="introduction" content={content} />
		</div>
	)
}

export default IntroductionWidget
