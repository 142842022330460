import { Fragment } from "react"
import { deserialise } from "kitsu-core"
import get from "lodash.get"
import {
	Button,
	//  Link,
	Wysiwyg,
	Heading,
	Text,
} from "@/ui"
import { normalizeNodes } from "./normalizer"
import { Card } from "./Card"

const NodeQueue = ({ data }) => {
	const title = get(data, "extra_field.title", "")
	const raw_description = get(data, "extra_field.description.value.#text", null)
	const description = <Wysiwyg html={raw_description} />
	const link = get(data, "extra_field.link.url", null)
	const link_label = get(data, "extra_field.link.title", "")
	let nodes = []
	data?.components.map((item) => {
		const items = deserialise(get(item, "nodes.data", {}))
		nodes = [...nodes, ...items?.data]
	})

	nodes = normalizeNodes(nodes)
	return (
		<div className="relative">
			<div className="relative max-w-7xl mx-auto">
				<div className="text-center">
					{title && (
						<Heading variant="3" level="2" className="text-center mb-5">
							{title}
						</Heading>
					)}
					{raw_description.length > 0 && (
						<Text className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
							{description}
						</Text>
					)}
				</div>
				{nodes.length > 0 && (
					<div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
						{nodes.map((post) => (
							<Fragment key={post.id}>
								<Card {...post} />
							</Fragment>
						))}
					</div>
				)}
				{link && (
					<div className="flex justify-center mt-12">
						<Button href={link}>{link_label}</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export const config = {
	id: "vactory_dynamic_field_decoupled:node-queue",
}

export default NodeQueue
