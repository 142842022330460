import Image from "next/image"
import { Button, Heading, Text, Wysiwyg } from "@/ui"

export const config = {
	id: "mre_templates:notre_solution",
}

const NotreSolutionWidget = ({ data }) => {
	const overlay = data?.extra_field?.overlay
	const heading = data?.extra_field?.heading
	const title = data?.extra_field?.title
	const description = data?.extra_field?.description
	const background = data?.extra_field?.background[0]
	const primaryLink = data?.extra_field?.primary_link
	const secondaryLink = data?.extra_field?.secondary_link

	return (
		<div className="relative">
			<div className="absolute inset-0 -z-1">
				<Image
					src={background._default}
					alt={background?.meta?.alt || "notre solution"}
					layout="fill"
				/>
			</div>
			<div className={`py-16 ${overlay ? "bg-black bg-opacity-50" : ""}`}>
				<div className="text-center mb-12">
					<Heading variant="mre" level="2">
						{heading}
					</Heading>
				</div>
				<div>
					<div className="mx-4 sm:mx-16 max-w-3xl md:mx-auto">
						<div className="bg-white flex flex-col md:flex-row">
							<div className="flex-grow flex flex-col">
								<div className="flex-1 bg-white text-black p-5 md:p-10 m-px">
									<Text as="strong" variant="large">
										{title}
									</Text>

									<Wysiwyg html={description.value["#text"]} className="my-4" />

									<ul className="columns-1 md:columns-2 list-dash list-inside">
										{data?.components?.map((item, index) => (
											<li key={`bullet-${index}`} className="font-bold">
												{item.bullet}
											</li>
										))}
									</ul>
									<div className="flex flex-col md:flex-row gap-4 mt-12">
										<Button
											variant="mre"
											href={primaryLink.link ? primaryLink.link : "#"}
											className="flex-1"
										>
											{primaryLink.title}
										</Button>
										<Button
											variant="secondary-mre"
											href={secondaryLink.link ? secondaryLink.link : "#"}
											className="flex-1"
										>
											{secondaryLink.title}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotreSolutionWidget
