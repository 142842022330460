import { useState, Fragment } from "react"
import clsx from "clsx"
import { Transition } from "@headlessui/react"
import { Icon } from "../icon/Icon"
import { alert } from "./theme"

export const Alert = ({
	children,
	wrapper,
	variant = "info",
	icon,
	className,
	shouldClose = true,
}) => {
	const [show, setShow] = useState(true)
	const handleCloseClick = () => {
		shouldClose && setShow(false)
	}
	return (
		<Transition show={show} as={Fragment} {...alert.animation}>
			<div className={clsx(alert.wrapper[wrapper], alert.variant[variant], className)}>
				<div className="flex">
					<Icon id={icon} className={clsx(alert["icon"])}></Icon>
					<div>{children}</div>
				</div>
				{shouldClose && (
					<div onClick={handleCloseClick} className="cursor-pointer">
						<Icon id="x" className={clsx(alert["icon"])}></Icon>
					</div>
				)}
			</div>
		</Transition>
	)
}

export default Alert
