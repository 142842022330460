import { Link, Icon, Heading } from "@/ui"
import clsx from "clsx"

export const config = {
	id: "mre_templates:besoin_dinformations",
}

export const BesoinDinfo = ({ items, title, layout }) => {
	const CapitalizeFirstLetter = (string) => {
		string = string[0]?.toUpperCase() + string?.substring(1)
		return string
	}

	return (
		<div id="besoins_informations">
			<div className="flex justify-center mb-5">
				<Heading variant="mre" className="text-center">
					{title}
				</Heading>
			</div>
			<div
				className={clsx(
					"grid max-w-4xl my-6",
					"shadow-md mx-auto grid-cols-1",
					layout
						? "before:content-none after:content-none"
						: `relative 
						before:content-[''] before:absolute before:right-0 before:left-0 before:w-[95%] before:mx-auto before:h-px before:md:bg-gray-200 before:top-1/2 
						after:content-[''] after:absolute after:top-0 after:bottom-0 after:h-[90%]  after:md:my-auto after:w-px after:md:bg-gray-200 after:left-1/2`,
					layout ? "md:grid-cols-3" : "md:grid-cols-2",
					"divide-y divide-gray500 md:divide-transparent bg-white after:bg-transparent before:bg-transparent",
					items.length <= 2 && "before:content-none"
				)}
			>
				{items.map((item, index) => {
					return (
						<Link className="border-none" key={item.id} href={item.link.url}>
							<div
								className={clsx(
									"relative group hover:bg-secondary hover:text-white transition ease-in-out duration-200 bg-transparent flex z-[9] h-full",
									"p-6 md:p-8",
									layout ? "flex-col" : "flex-row",
									layout ? "gap-6" : "gap-8",
									layout ? "justify-start" : "justify-center",
									layout && index < items.length - 1
										? `after:absolute after:-right-[2px] after:top-0 after:bottom-0 after:content-[''] after:w-[2px] after:bg-gray-200 after:h-[90%] after:my-auto hover:after:bg-transparent`
										: "after:content-none"
								)}
							>
								<svg
									className={clsx(
										"absolute bottom-0 hidden group-hover:block z-1",
										layout ? "w-full" : "w-3/4",
										layout ? "-left-16" : "left-0"
									)}
									viewBox="0 0 422 123"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M353.24 24.36l-60.4 70.05c-23.65-28.06-70.36-83.6-70.36-83.6a27.16 27.16 0 00-20.7-9.75 27.4 27.4 0 00-20.76 9.7l-71.08 83.65c-23.61-28.06-70.3-83.6-70.3-83.6a27.17 27.17 0 00-20.7-9.75 27.29 27.29 0 00-20.78 9.7l-112.98 133.58h42.6c8.97 0 21.06-5.62 26.87-12.52L18.8 56c23.63 28.1 70.32 83.54 70.32 83.54a27.04 27.04 0 0020.7 9.78 27.07 27.07 0 0020.77-9.67s47.25-55.55 71.08-83.65c23.63 28.1 70.32 83.54 70.32 83.54a27.04 27.04 0 0020.7 9.78 27.03 27.03 0 0020.76-9.67s83.51-99.6 107.37-127.7h-40.48c-8.99 0-21.15 5.58-27.1 12.4"
										fillRule="nonzero"
										stroke="#FFF"
										fill="none"
										opacity=".57"
									/>
								</svg>
								<div
									className={`flex-shrink-0 ${
										layout ? "self-start" : "justify-self-start"
									} w-14 h-14 md:w-12 md:h-12 group-hover:text-white group-hover:fill-current transition ease-in-out duration-200`}
								>
									<Icon id={item.icon} className="w-full h-full" />
								</div>
								<div className="relative z-2 w-full">
									<h5 className="mb-4 line-clamp-2 uppercase font-bold text-[20px] leading-[24px]">
										{item.title}
									</h5>
									{item.link.title && (
										<Link
											href={item.link.url}
											id={item.id}
											target={item.target}
											className={
												item.className +
												"font-medium md:font-bold text-sm md:text-xl normal-case "
											}
											variant="permalink-besoin"
											rel={item.rel}
										>
											{CapitalizeFirstLetter(item.link.title)}
										</Link>
									)}
								</div>
							</div>
						</Link>
					)
				})}
			</div>
		</div>
	)
}

const BesoinDinfoContainer = ({ data }) => {
	const props = {
		title: data?.extra_field?.title,
		layout: data?.extra_field?.layout === 1,
		items: data?.components?.map((item) => ({
			title: item?.title,
			icon: item?.iconName,
			link: item?.link,
			id: item?.link?.attributes?.id,
			className: item?.link?.attributes?.class,
			target: item?.link?.attributes?.target,
			rel: item?.link?.attributes?.rel,
		})),
	}

	return <BesoinDinfo {...props} />
}

export default BesoinDinfoContainer
