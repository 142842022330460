import { Heading, Accordion, Wysiwyg } from "@/ui"
import Image from "next/image"

export const config = {
	id: "mre_templates:accordion_with_background",
}

const AccordionWithBackgroundWidget = ({ data }) => {
	const title = data?.extra_field?.title
	const background = data?.extra_field?.background?.[0]
	const nodes = data?.components?.map((item) => {
		return {
			button: item?.title,
			panel: <Wysiwyg html={item?.description?.value?.["#text"]} />,
		}
	})

	return (
		<div className="relative pt-12 flex flex-col items-center">
			<>
				{background ? (
					<div className="absolute w-full h-[240px] -z-1 top-0 left-0">
						<div className="relative w-full h-full">
							<Image
								src={background?._default}
								alt={background?.meta?.alt || "accordion with background image"}
								layout="fill"
							/>
						</div>
					</div>
				) : (
					<div className="bg-secondary absolute w-full h-[240px] -z-1 top-0 left-0"></div>
				)}
			</>
			<div className="container mx-auto">
				{title && (
					<Heading variant="mre" level="2" className="text-center mb-12">
						{title}
					</Heading>
				)}
				<div className={`${!title && "mt-6"} border border-primary shadow-gray`}>
					<Accordion nodes={nodes} onlyOneOpen={true} />
				</div>
			</div>
		</div>
	)
}

export default AccordionWithBackgroundWidget
