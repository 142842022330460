export const checkboxes = {
	default: {
		wrapper: "flex items-center gap-x-2",
		checkboxesContainer: "flex items-center gap-x-3",
		input:
			"h-5 w-5 rounded p-0.5 flex items-center justify-center transition duration-200",
		checked: {
			enabled: "bg-indigo-500 hover:ring-2 hover:ring-indigo-300 cursor-pointer",
			disabled: "bg-gray-300 cursor-not-allowed",
		},
		unchecked: {
			enabled: "border border-gray-300 cursor-pointer",
			disabled: "border border-gray-300 cursor-not-allowed",
		},
		label: "whitespace-nowrap text-sm font-medium text-gray-700",
		icon: "h-4 w-4 text-white",
		errorMessage: "text-xs text-red-500 inline-block mt-1",
	},
	mre: {
		wrapper: "gap-x-2",
		checkboxesContainer: "flex items-center gap-x-3",
		input: "h-6 w-6 p-0.5 flex items-center justify-center",
		checked: {
			enabled: "bg-black cursor-pointer",
			disabled: "bg-gray-300 cursor-not-allowed",
		},
		unchecked: {
			enabled: "border-2 border-black cursor-pointer",
			disabled: "border-2 border-black cursor-not-allowed",
		},
		label: "whitespace-nowrap text-sm font-medium text-gray-700",
		icon: "h-4 w-4 text-white",
		errorMessage: "text-xs text-red-500 inline-block mt-1",
	},
}
