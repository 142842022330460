import { Fragment } from "react"
import { Listbox, Transition } from "@headlessui/react"
import clsx from "clsx"
import { Icon } from "../icon/Icon"
import { theme } from "./theme"
import { useI18n as useTransition } from "@/hooks"

const RemoveSelectedItems = ({ variant, removeOptions }) => {
	const handleRemoveOption = (e) => {
		e.stopPropagation()
		removeOptions()
	}
	return (
		<button
			className={theme[variant].button.resetSelectButton.wrapper}
			onClick={(e) => {
				handleRemoveOption(e)
			}}
		>
			<Icon id="close" className={theme[variant].button.resetSelectButton.icon} />
		</button>
	)
}

const SelectedItemsCount = ({ variant, selectedOptions }) => {
	return (
		<p className={theme[variant].button.selectedOptionsCount}>
			<span>+</span>
			<span className="font-semibold">{selectedOptions.length}</span>
		</p>
	)
}

const SelectItem = ({ variant, selected, active, option }) => {
	return (
		<div
			className={clsx(
				theme[variant].options.option.wrapper.default,
				active && theme[variant].options.option.wrapper.active
			)}
		>
			<input
				className="mr-2"
				type="checkbox"
				defaultChecked={selected}
				checked={selected}
			/>

			<span className={theme[variant].options.option.optionText}>{option.label}</span>
		</div>
	)
}

const SelectedItem = ({ variant, option, removeOption }) => {
	const handleRemoveItem = (e) => {
		e.stopPropagation()
		removeOption(option.value)
	}

	return (
		<div className={theme[variant].button.selectedOption.wrapper}>
			<span className={theme[variant].button.selectedOption.optionText}>
				{option.label}
			</span>
			<button
				onClick={(e) => {
					handleRemoveItem(e)
				}}
				className={theme[variant].button.selectedOption.deleteButton.wrapper}
			>
				<Icon
					id="close"
					className={theme[variant].button.selectedOption.deleteButton.icon}
				/>
			</button>
		</div>
	)
}

const DefaultValue = ({ variant, defaultValue }) => {
	return (
		<div className={theme[variant].placeholder.wrapper}>
			<span className={theme[variant].placeholder.text}>{defaultValue}</span>
		</div>
	)
}

export const SelectMultiple = ({
	variant = "default",
	options,
	selected,
	setSelected,
	placeholder,
	className,
}) => {
	const ids = options.map((item) => item.id)
	selected = selected.filter((item) => ids.includes(item))

	const { t } = useTransition()
	const removeOption = (optionId) => {
		setSelected((prev) => {
			return prev.filter((option) => {
				return option != optionId
			})
		})
	}

	const removeOptions = () => {
		setSelected([])
	}

	const getOption = () => {
		const lastSelectedOption = selected[selected.length - 1]
		return options.find((option) => {
			return option.value === lastSelectedOption
		})
	}

	const handleSelectChange = (option) => {
		setSelected(option)
	}

	return (
		<div className={(theme[variant], className)}>
			<Listbox value={selected} onChange={handleSelectChange} multiple>
				<Listbox.Button className={theme[variant].button.wrapper}>
					{selected.length > 0 ? (
						<div className="flex items-center justify-between flex-grow">
							<SelectedItem
								variant={variant}
								option={getOption()}
								removeOption={removeOption}
							/>
							<div className="flex items-center">
								<SelectedItemsCount variant={variant} selectedOptions={selected} />
								<RemoveSelectedItems variant={variant} removeOptions={removeOptions} />
							</div>
						</div>
					) : (
						<DefaultValue variant={variant} defaultValue={t(placeholder)} />
					)}
					<span className="pointer-events-none pl-2">
						<Icon
							id="chevron-down"
							className="w-4 h-4 text-gray-500 hover:text-gray-900 fill-current"
						/>
					</span>
				</Listbox.Button>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Listbox.Options className={theme[variant].options.wrapper}>
						{options.map((option, optionId) => (
							<Listbox.Option key={optionId} value={option.value}>
								{({ selected, active }) => (
									<SelectItem
										variant={variant}
										selected={selected}
										active={active}
										option={option}
									/>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</Listbox>
		</div>
	)
}

SelectMultiple.defaultProps = {
	placeholder: "Choose an option",
}
