const theme = {
	default: {
		wrapper: `relative z-10
	after:contents-[''] after:absolute after:h-px   after:bg-primary after:left-0 after:bottom-0
	before:contents-[''] before:absolute before:w-px  before:bg-primary before:top-0 before:right-0
	overflow-hidden w-full h-full flex lg:flex-row items-center lg:items-start flex-col gap-8 border-t border-l border-primary bg-white py-6 px-5 `,
		image: "flex-shrink-0 items-start w-[200px] h-[200px] max-h-[200px] relative z-[11]",
		card: {
			wrapper: "relative z-[11] flex-1 overflow-hidden",
			wysiwyg: "font-normal",
			curve: {
				bg: "absolute z-9 top-0 left-0 w-[calc(100%-1px)] h-full text-white overflow-hidden",
				border:
					"absolute ltr:-bottom-[58px] rtl:bottom-[2px] border-l border-l-primary ltr:-right-11 rtl:right-[16px] rotate-45 h-[87px] bg-transparent w-[87px]",
			},
			reverse: false,
		},
	},
	quoted: {
		wrapper: `relative z-10
        after:contents-['']  after:absolute after:h-px after:bg-primary after:left-0 after:bottom-0
        before:contents-[''] before:absolute before:w-px before:bg-primary before:top-0 before:right-0
         overflow-hidden w-full h-full flex flex-col md:flex-row items-center lg:items-start gap-4 md:gap-8 border-t border-l border-primary bg-white px-8 py-6 md:px-5`,
		image:
			"flex-shrink-0 items-start w-full md:w-[200px] h-[200px] max-h-full relative z-[11] ",
		card: {
			wrapper: "relative z-[11] flex-1 md:text-left text-center",
			wysiwyg: "font-normal text-lg md:text-center text-left",
			curve: {
				bg: "absolute z-9 top-0 left-0 w-[calc(100%-1px)] h-full text-white",
				border:
					"absolute -bottom-[58px] border-l border-l-primary -right-11 rotate-45 h-[87px] bg-transparent w-[87px]",
			},
			reverse: true,
		},
	},
}

export { theme }
