import { Fragment } from "react"
import get from "lodash.get"
import { Heading, Icon } from "@/ui"

const ContactsUtilesCard = ({ title, phone, description }) => {
	return (
		<div className="flex flex-col py-5 px-4 shadow-md bg-white border border-[#F2F4F7] overflow-hidden text-black text-left">
			{title && (
				<h3 className="text-xl md:text-[24px] line-clamp-2 leading-[30px] tracking-[1px] font-semibold">
					{title}
				</h3>
			)}
			{description && <p className="text-xs">{description}</p>}
			<div className="h-px w-[60%] bg-gray500 mt-2 mb-4" />
			<div className="flex text-lg font-medium gap-4 flex-col">
				{phone && (
					<div className="grid grid-cols-icon-paragraph items-center gap-3">
						<Icon
							id="handset"
							className="text-secondary fill-current"
							width={20}
							height={20}
						/>
						<a
							style={{
								direction: "ltr",
							}}
							href={`tel:${phone}`}
						>
							{phone}
						</a>
					</div>
				)}
			</div>
		</div>
	)
}

const ContactsGridWidget = ({ data }) => {
	const title = get(data, "extra_field.title", "")

	const nodes = get(data, "components", [])

	return (
		<div className="container">
			{title && (
				<div className="text-center mb-10">
					<Heading variant="mre" className="text-center mb-5">
						{title}
					</Heading>
				</div>
			)}
			{nodes.length > 0 && (
				<div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 md:max-w-none">
					{nodes.map((node) => (
						<Fragment key={node.id}>
							<ContactsUtilesCard
								title={node.title}
								phone={node.phone}
								description={node.description}
							/>
						</Fragment>
					))}
				</div>
			)}
		</div>
	)
}

export const config = {
	id: "mre_templates:contacts_grid",
}

export default ContactsGridWidget
