import { YouTubeGetID } from "@/lib"
import { Heading, VideoThumbnail, Button, Container, Wysiwyg } from "@/ui"
import clsx from "clsx"
import { useVideo } from "hooks/useVideo"

export const config = {
	id: "mre_templates:video_content_section",
}

function VideoContentSection({ data }) {
	const { setIsOpen, setSource } = useVideo()
	const videoContent = {
		title: data?.components[0]?.title,
		content: (
			<Wysiwyg
				html={data?.components[0]?.content.value["#text"]}
				className="flex flex-col gap-4"
			/>
		),
		// content: data?.components[0]?.content,
		image: data?.components[0]?.image[0],
		video: data?.components[0]?.video?.url,
		isReversed: data?.components[0]?.isReversed === 1,
		isOverlapped: data?.components[0]?.isOverlapped === 1,
	}

	const handleClick = (e) => {
		e.preventDefault()
		if (videoContent.video) {
			setIsOpen(true)
			setSource(YouTubeGetID(videoContent.video))
		}
	}

	if (data?.components[0]?.link) {
		videoContent.link = data?.components[0]?.link
	}
	if (data?.extra_field?.doc_file && data?.extra_field?.doc_file?.length > 0) {
		videoContent.doc_file = data.extra_field.doc_file[0]
	}
	if (data?.extra_field?.btn_label && data?.extra_field?.btn_label?.length > 0) {
		videoContent.btn_label = data.extra_field.btn_label
	}

	let buttonProps = {
		title: "",
		url: "",
	}
	if (videoContent.link.title && videoContent.link.url) {
		buttonProps = { ...buttonProps, ...videoContent.link }
	}
	if (!videoContent.link.title && !videoContent.link.url) {
		buttonProps = {
			...buttonProps,
			title: videoContent.btn_label,
			url: videoContent.doc_file?._default,
		}
	}

	return (
		<Container layout="container">
			{videoContent.title && (
				<Heading variant="mre" className="text-center">
					{videoContent.title}
				</Heading>
			)}
			<div
				className={clsx(
					`w-full relative p-0 md:p-4 flex gap-6 `,
					videoContent.isReversed
						? "flex-col md:flex-row-reverse"
						: "flex-col md:flex-row",
					videoContent.isOverlapped
						? "max-w-5xl mx-auto justify-between items-center flex-col mb-52"
						: "justify-center items-center flex-row mb-0"
				)}
			>
				{videoContent.image?._default && (
					<VideoThumbnail
						image={videoContent.image?._default}
						title={videoContent.title}
						video={videoContent.video}
						isOverlapped={videoContent.isOverlapped}
						onClick={handleClick}
					/>
				)}
				<div
					className={clsx(
						"w-full ",
						videoContent.isOverlapped
							? "border-2 border-primary max-w-[450px] p-4 bg-white absolute -bottom-48 md:bottom-auto md:right-10 md:max-w-lg"
							: "bg-transparent max-w-[450px] md:max-w-[500px]"
					)}
				>
					<p
						className={clsx(
							"w-fit mb-2",
							videoContent.isOverlapped ? "text-base md:text-lg" : "text-base"
						)}
					>
						{videoContent.content}
					</p>

					{buttonProps.url && (
						<div
							className={`w-full mt-6 flex ${
								videoContent.isReversed
									? "justify-center md:justify-end"
									: "md:justify-start justify-center"
							}`}
						>
							<Button href={buttonProps.url} variant="mre">
								{buttonProps.title}
							</Button>
						</div>
					)}
				</div>
			</div>
		</Container>
	)
}

export default VideoContentSection
