import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { toRegister } from "../utils/toRegister"
import { useI18n as useTranslation } from "@/hooks"
import { Input } from "@/ui"
import { process } from "../process/process"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const TextField = ({ name, field }) => {
	const {
		label,
		placeholder,
		htmlInputType,
		helperText,
		validation,
		shouldDisplay,
		variant,
		states,
		fullWidth,
		hideLabel,
		onKeyPress,
	} = field
	const { t } = useTranslation()
	const { register, watch, setValue } = useFormContext()

	const local_state = useCheckConditions(states)

	const errorMessage = useErrorMessage(name, label)
	const values = watch({ nest: true })

	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	if (!isVisible) return null

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	const { onChange, onBlur, ...registerFuncs } = register(
		name,
		toRegister(t("Nx:" + label), local_validation, {}, t)
	)

	const revertProcess = (e) => {
		process[field["process"]] !== undefined ? process[field["process"]](e) : null
	}

	const blurHanlder = (e) => {
		const trValue = e.target.value.trim()
		setValue(name, trValue)
		if (onBlur) onBlur(e)
	}

	return (
		<Input
			// label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
			label={label && !hideLabel && t(`Nx:${label}`)}
			required={local_validation?.required}
			variant={variant || "mre"}
			placeholder={placeholder}
			type={htmlInputType || "text"}
			hasError={!!errorMessage}
			fullWidth={fullWidth}
			errorMessage={errorMessage}
			onBlur={blurHanlder}
			onChange={(e) => {
				onChange(e)
				revertProcess(e)
			}}
			{...registerFuncs}
			disabled={local_state?.disabled}
			description={helperText}
			onKeyPress={onKeyPress}
		/>
	)
}
