import { Icon, Heading } from "@/ui"
import { useI18n } from "@/hooks"

export const EmptyBlock = ({ message, icon }) => {
	const { t } = useI18n()
	return (
		<div className="max-w-md flex gap-6 flex-col p-5 mx-auto text-center">
			<div className="w-full max-w-[272px] relative mx-auto">
				{icon ? (
					icon
				) : (
					<Icon id="search" width="100" height="100" className="text-gray-400 mx-auto" />
				)}
			</div>
			<div>
				<Heading level="4" className="text-gray-900 ">
					{message ? message : t("Aucun résultat trouvé")}
				</Heading>
			</div>
		</div>
	)
}
