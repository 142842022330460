import { Fragment, useRef } from "react"
import { useNode, useI18n, useHeader /*, useAccount*/ } from "@/hooks"
import { getEnabledLanguages } from "@/lib"
import { useRouter } from "next/router"
import Image from "next/image"
import { Menu, Transition } from "@headlessui/react"
import { Icon, Link, Button, Text } from "@/ui"
import clsx from "clsx"

export const config = {
	id: "mre_templates:header_lp",
}

const languages = getEnabledLanguages({
	withLabels: true,
})

const HeaderComponent = ({ data }) => {
	const logo = {
		src: data?.components[0]?.header_logo?.[0]?._default || null,
		width: data?.components[0]?.header_logo?.[0]?.meta?.width,
		height: data?.components[0]?.header_logo?.[0]?.meta?.height,
		alt: data?.components[0]?.header_logo?.[0]?.meta?.alt,
	}
	const slogon = data?.components[0]?.slogon
	const switchLocation = data?.components[0]?.show_language_dropdown
	const link = data?.components[0]?.cta

	const router = useRouter()
	const locale = router.locale
	// To hide/animate the header
	const { headerState } = useHeader(100)

	const { t } = useI18n()

	const isSticky = !headerState.includes("top") || headerState.includes("scroll")
	const headerRef = useRef(null)

	return (
		<header className="z-[51] absolute top-0 left-0 w-full">
			<div
				ref={headerRef}
				className={clsx(
					"transition-all duration-300 ease-in-out",
					isSticky
						? "fixed inset-x-0 bg-gradient-to-tr bg-white shadow-gray"
						: "relative",
					"flex flex-col",
					"z-[51]"
				)}
			>
				<div>
					<div
						className={clsx(
							isSticky ? "" : "md:pt-5 md:pb-4",
							"md:container flex items-center p-3 relative z-[52]"
						)}
					>
						<Link className="flex" href={`/${locale}`}>
							<Text as="span" className="sr-only">
								{t("Nx:Portail MRE par Attijariwafa Bank")}
							</Text>

							<div className="divide-x-2 divide-black divide-solid flex items-center">
								<div className="h-14 pr-5">
									<Image
										src={logo.src}
										alt={logo.alt}
										width="175"
										height="56"
										className="w-[96px] h-[32px] md:w-[175px] md:h-[56px]"
									/>
								</div>

								{slogon && (
									<div className="font-bold md:text-xl pl-4">
										<Text as="span">{slogon}</Text>
									</div>
								)}
							</div>
						</Link>
						<div className="flex items-center ml-auto">
							{switchLocation == 1 && <SwitchLocale />}
							{link.title && link.url && (
								<Button variant="mre" href={link.url} className="ml-8 hidden md:flex">
									{link.title}
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

const SwitchLocale = () => {
	const router = useRouter()
	const locale = router.locale
	const { path_18n } = useNode()

	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<Menu.Button
					className={clsx(
						"inline-flex items-center p-2 text-black hover:bg-white hover:bg-opacity-20 uppercase"
					)}
				>
					{locale.toUpperCase()}
					<Icon
						id="chevron-down"
						className="h-3 w-3 ml-2 fill-current"
						width="20px"
						height="20px"
					/>
				</Menu.Button>
			</div>
			<Transition
				unmount={false}
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="ltr:origin-top-right rtl:origin-top-left absolute right-0 mt-2 w-auto bg-white text-black shadow-gray">
					<div className="flex flex-col py-1">
						{languages.map((language) => (
							<Menu.Item key={language.code}>
								{({ active }) => {
									const url = path_18n[language.code] || "#."
									return (
										<a
											href={url}
											className={`${
												active ? "bg-gray-100 text-secondary" : "text-gray-900"
											} block px-4 py-2 text-sm hover:bg-gray-100 hover:text-secondary`}
										>
											{language.label}
										</a>
									)
								}}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}

export default HeaderComponent
