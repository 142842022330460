export const theme = {
	default: {
		wrapper: "relative md:w-96 w-full",
		button: {
			wrapper:
				"uppercase flex items-center justify-between divide-x gap-x-2 truncate relative w-full cursor-default border border-secondary bg-white py-1 md:py-2 px-3 text-left focus:outline-none sm:text-sm",
			resetSelectButton: {
				wrapper: "p-1 ml-4",
				icon: "w-3 h-3 fill-gray-500 hover:fill-gray-900",
			},
			selectedOptionsCount: "flex items-center text-sm gap-x-0.5",
			selectedOption: {
				wrapper: "flex justify-between bg-gray-100 rounded",
				optionText: "text-xs px-2 py-1.5",
				deleteButton: {
					wrapper:
						"text-gray-900 hover:text-secondary hover:bg-red-200 px-2 flex items-center justify-center rounded-l-none rounded-r",
					icon: "w-2 h-2 fill-current",
				},
			},
		},
		options: {
			wrapper:
				"absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1.5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-30",
			option: {
				wrapper: {
					default:
						"uppercase relative flex items-center gap-x-1.5 cursor-default select-none py-2 px-3 hover:text-secondary text-sm",
					active: "bg-gray-100",
				},
				checkbox: {
					default: "w-5 h-5 border flex justify-center items-center rounded",
					selected: "bg-black",
					unselected: "bg-white",
					icon: "w-3 h-3 text-white",
				},
				optionText: "",
			},
		},
		placeholder: {
			wrapper: "py-1",
			text: "text-gray-800 text-sm font-bold",
		},
	},
	forum: {
		wrapper: "relative w-full",
		button: {
			wrapper:
				"flex items-center justify-between divide-x gap-x-2 truncate relative w-full cursor-default border border-secondary bg-white py-2 px-3 text-left focus:outline-none sm:text-sm",
			resetSelectButton: {
				wrapper: "p-1 ml-4",
				icon: "w-3 h-3 fill-gray-500 hover:fill-gray-900",
			},
			selectedOptionsCount: "flex items-center text-sm gap-x-0.5",
			selectedOption: {
				wrapper: "flex justify-between bg-gray-100 rounded",
				optionText: "text-xs px-2 py-1.5",
				deleteButton: {
					wrapper:
						"text-gray-900 hover:text-secondary hover:bg-red-200 px-2 flex items-center justify-center rounded-l-none rounded-r",
					icon: "w-2 h-2 fill-current",
				},
			},
		},
		options: {
			wrapper:
				"absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1.5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-30",
			option: {
				wrapper: {
					default:
						"relative flex items-center gap-x-1.5 cursor-default select-none py-2 px-3 hover:text-secondary text-sm",
					active: "bg-gray-100",
				},
				checkbox: {
					default: "w-5 h-5 border flex justify-center items-center rounded",
					selected: "bg-black",
					unselected: "bg-white",
					icon: "w-3 h-3 text-white",
				},
				optionText: "",
			},
		},
		placeholder: {
			wrapper: "py-1",
			text: "text-gray-800 text-sm font-bold",
		},
	},
}
