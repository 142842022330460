import { RenderField } from "./Form"
import { FlexBox } from "./containers/FlexBox"
import { FieldSet } from "./containers/FieldSet"
import { Details } from "./containers/Details"
import { Container } from "./containers/Container"

const LAYOUTS = ["webform_flexbox", "container", "fieldset", "details"]

export const FormLayout = ({ data, classes = "" }) => {
	// w-1/2 w-1/3 w-2/3 w-1/4 w-2/4 w-3/4 w-1/5 w-2/5 w-3/5 w-4/5
	const keys = Object.keys(data?.childs || [])
	if (keys.length == 0) return null
	let Wrapper = Container
	classes =
		data?.class !== undefined && data?.class !== "" ? `${classes} ${data.class}` : classes
	let attributes = { classes: classes }
	switch (data?.type) {
		case "webform_flexbox":
			Wrapper = FlexBox
			attributes["align_items"] = data?.align_items || "items-start"
			break
		case "container":
			Wrapper = Container
			break
		case "fieldset":
			Wrapper = FieldSet
			attributes["title"] = data?.title || ""
			attributes["title_display"] = data?.title_display || ""
			attributes["description"] = data?.description || ""
			attributes["description_display"] = data?.description_display || ""
			attributes["displayDivider"] = data?.displayDivider
			break
		case "details":
			Wrapper = Details
			attributes["title"] = data?.title || ""
			attributes["title_display"] = data?.title_display || ""
			break
	}
	return (
		<Wrapper {...attributes}>
			{keys.map((item) => {
				if (item === "flexTotal") {
					return
				}
				const flexWidth =
					data.childs["flexTotal"] !== undefined
						? `w-${data.childs[item]["flex"]}/${data?.childs["flexTotal"]}`
						: "w-full"
				if (LAYOUTS.includes(data?.childs[item]["type"])) {
					return <FormLayout classes={flexWidth} data={data?.childs[item]} />
				}
				return (
					<div key={`${item}-wrapper`} className={flexWidth}>
						<RenderField key={`${item}-container`} field={[item, data?.childs[item]]} />
					</div>
				)
			})}
		</Wrapper>
	)
}
