export const link = {
	default: "cursor-pointer",
	permalink: {
		base: "inline-flex uppercase items-center text-[15px] leading-[24px] font-bold group transition duration-400 ease-in-out cursor-pointer",
		hover: {
			black: "hover:text-secondary",
		},
	},
	"permalink-besoin": {
		base: "inline-flex items-center text-[15px] leading-[24px] font-bold group cursor-pointer",
		hover: {
			black: "hover:text-white",
		},
		icon: "rtl-icon mr-3 fill-secondary group-hover:fill-white",
	},
}
