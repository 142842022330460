import { Fragment } from "react"
import { deserialise } from "kitsu-core"
import get from "lodash.get"
import { Button, Wysiwyg, Heading, Text } from "@/ui"
import { normalizeNodes } from "./normalizer"
import { NewsCard } from "./NewsCard"
import clsx from "clsx"

const NewsPrefiltredBlock = ({ data }) => {
	const title = get(data, "extra_field.title", "")
	const raw_description = get(data, "extra_field.description.value.#text", null)
	const description = <Wysiwyg html={raw_description} />
	const link = get(data, "extra_field.link.url", null)
	const link_label = get(data, "extra_field.link.title", "")
	let nodes = []
	data?.components.map((item) => {
		const items = deserialise(get(item, "nodes.data", {}))
		nodes = [...nodes, ...items?.data]
	})
	nodes = normalizeNodes(nodes)

	return (
		<div className="relative px-6 md:px-0 md:max-w-[950px] xl:max-w-[1100px] mx-auto">
			<div className="text-center">
				{title && (
					<Heading variant="mre" className="text-center">
						{title}
					</Heading>
				)}
				{raw_description.length > 0 && (
					<Text className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
						{description}
					</Text>
				)}
			</div>
			{nodes.length > 0 && (
				<div
					className={clsx(
						`mt-12 max-w-lg mx-auto grid gap-5 grid-cols-1 lg:grid-cols-${
							nodes.length > 1 ? nodes.length : 2
						} lg:max-w-none`
					)}
				>
					{nodes.map((post) => (
						<Fragment key={post.id}>
							<NewsCard {...post} />
						</Fragment>
					))}
				</div>
			)}
			{link && (
				<div className="flex justify-center mt-12">
					<Button variant="mre" href={link}>
						{link_label}
					</Button>
				</div>
			)}
		</div>
	)
}

export const config = {
	id: "mre_templates:news_prefiltred_block",
}

export default NewsPrefiltredBlock
