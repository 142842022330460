const processDecimal = (e) => {
	var inputValue = e.target.value
	inputValue = inputValue.split(",").join("")
	const rgx = /(\d+)(\d{3})/
	while (rgx.test(inputValue)) {
		inputValue = inputValue.replace(rgx, "$1" + "," + "$2")
	}
	inputValue = inputValue.replace(/[a-z]/gi, "")
	e.target.value = inputValue
}

const revertDecimalProcess = (value) => {
	return value.split(",").join("")
}

const processPhoneNumber = (e) => {
	if (e.target.value.length <= 16) {
		var inputValue = e.target.value
		var formatted = inputValue
			.replace(/\D/g, "")
			.replace(/(\d{2})(\d)/, "$1 $2")
			.replace(/(\d{2})(\d)/, "$1 $2")
			.replace(/(\d{2})(\d)/, "$1 $2")
			.replace(/(\d{2})(\d)/, "$1 $2")
			.replace(/( \d{2})\d+?$/, "$1")

		e.target.value = formatted
	}
}

const revertPhoneNumberProcess = (value) => {
	return value.split(" ").join("")
}

export const process = {
	processDecimal: processDecimal,
	revertDecimalProcess: revertDecimalProcess,
	processPhoneNumber: processPhoneNumber,
	revertPhoneNumberProcess: revertPhoneNumberProcess,
}
