import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { Textarea } from "@/ui"
import { useI18n as useTranslation } from "@/hooks"
import { toRegister } from "../utils/toRegister"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const TextAreaField = ({ name, field }) => {
	const { label, placeholder, helperText, validation, shouldDisplay, states } = field
	const { register, watch } = useFormContext()
	const errorMessage = useErrorMessage(name, label)
	const values = watch({ nest: true })
	const { t } = useTranslation()
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])
	const local_state = useCheckConditions(states)

	if (!isVisible) return null

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	return (
		<Textarea
			variant="mre"
			placeholder={placeholder}
			label={label && t(`Nx:${label}`)}
			required={local_validation?.required}
			// label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
			description={helperText}
			hasError={!!errorMessage}
			errorMessage={errorMessage}
			rows={3}
			{...register(name, toRegister(t("Nx:" + label), local_validation, {}, t))}
			id={name}
			name={name}
		/>
	)
}
