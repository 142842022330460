import { deserialise } from "kitsu-core"
import { getSession } from "next-auth/react"
// import { join } from "path"
import { logger as defaultLogger } from "../logger"
import { query } from "../utils"
// import Router from "next/router"

// See https://jsonapi.org/format/#content-negotiation.
const DEFAULT_HEADERS = {
	"Content-Type": "application/vnd.api+json",
	Accept: "application/vnd.api+json",
}
const DEFAULT_WITH_AUTH = false

const DEFAULT_NEXT_API_PROXY = "/api/proxy"
const DEFAULT_DUPAL_API = "http://localhost:8080"

class JsonApiErrors extends Error {
	errors
	statusCode

	constructor(errors, statusCode) {
		super()

		this.errors = errors
		this.statusCode = statusCode
		this.message = JsonApiErrors.formatMessage(errors)
	}

	static formatMessage(errors) {
		if (typeof errors === "string") {
			return errors
		}

		const [error] = errors

		let message = `${error.status} ${error.title}`

		if (error.detail) {
			message += `\n${error.detail}`
		}

		return message
	}
}

export class DrupalClient {
	_headers
	_nextApiProxy
	_nextDrupalApi
	withAuth
	cache
	logger
	accessToken
	debug = false

	constructor(options = {}) {
		const {
			nextApiProxy = DEFAULT_NEXT_API_PROXY,
			nextDrupalApi = DEFAULT_DUPAL_API,
			cache = null,
			debug = false,
			headers = DEFAULT_HEADERS,
			logger = defaultLogger,
			withAuth = DEFAULT_WITH_AUTH,
			auth,
			fetcher,
			accessToken,
		} = options

		this._nextApiProxy = nextApiProxy
		this._nextDrupalApi = nextDrupalApi
		this.debug = debug
		this.fetcher = fetcher
		this.headers = headers
		this.logger = logger
		this.withAuth = withAuth
		this.cache = cache
		this.auth = auth
		this.accessToken = accessToken
		this._debug("Debug mode is on.")
	}

	set auth(auth) {
		this._auth = auth
	}

	/**
	 * @param {any} value
	 */
	set headers(value) {
		this._headers = value
	}

	async fetch(input, init = {}) {
		init = {
			...init,
			headers: {
				...(typeof init.skipDefaultHeaders === "undefined" && { ...this._headers }),
				...init?.headers,
			},
		}

		if (init?.withAuth) {
			this._debug(`Using authenticated request.`)
			if (typeof init.withAuth === "function") {
				this._debug(`Using custom auth.`)
				init["headers"]["Authorization"] = init.withAuth()
			} else {
				// Otherwise use the built-in client_credentials grant.
				this._debug(`Using default auth (client_credentials).`)

				// Fetch an access token and add it to the request.
				// Access token can be fetched from cache or using a custom auth method.
				const session = await getSession()

				const token = await this.getAccessToken()
				if (session?.accessToken) {
					init["headers"]["Authorization"] = `Bearer ${token}`
					init["headers"]["X-Auth-Provider"] = session.provider
				}
			}
		}

		if (this.fetcher) {
			this._debug(`Using custom fetcher.`)

			return await this.fetcher(input, init)
		}

		// this._debug(`Using default fetch (polyfilled by Next.js).`)

		if (init?.absoluteUrl) {
			return await fetch(input, init)
		}

		if (init?.noProxy || typeof window === "undefined") {
			return await fetch(`${this._nextDrupalApi}/${input}`, init)
		}

		return await fetch(`${this._nextApiProxy}/${input}`, init)
	}

	async upload(input, init, filename, binary) {
		const { locale, ...rest } = init

		return this.fetch("/api/upload", {
			...rest,
			absoluteUrl: true,
			skipDefaultHeaders: true,
			method: "POST",
			headers: {
				...init?.headers,
				"x-language": locale,
				"x-path": input,
				Accept: "application/json",
				"Content-Disposition": 'file; filename="' + filename + '"',
			},
			body: binary,
		})
	}

	async getAccessToken() {
		// @todo: check for expired before using this
		// if (this.accessToken) {
		// 	return this.accessToken
		// }

		const session = await getSession()

		if (!session) {
			return null
		}

		this.token = session.accessToken

		return session.accessToken
	}

	async getTranslations(options = {}) {
		if (options?.withCache) {
			const cached = await this.cache.get(options.cacheKey)

			if (cached) {
				this._debug(`Returning cached translation items using key ${options.cacheKey}`)
				return JSON.parse(cached)
			}
		}

		const path = `_translations`
		this._debug(`Fetching translations.`)
		this._debug(`${this._nextDrupalApi}_translations`)

		const response = await this.fetch(path, { noProxy: true })

		if (!response?.ok) {
			await this.handleJsonApiErrors(response)
		}

		const data = await response.json()

		if (options.withCache) {
			await this.cache.set(options.cacheKey, JSON.stringify(data))
		}

		return data
	}

	async getMenu(name, locale, options = {}) {
		if (options?.withCache) {
			const cached = await this.cache.get(options.cacheKey)

			if (cached) {
				this._debug(
					`Returning cached menu items for ${name} using key ${options.cacheKey}`
				)
				return JSON.parse(cached)
			}
		}

		const path = `${locale}/_menus?menu_name=${name}`
		this._debug(`Fetching menu items for ${name}.`)
		this._debug(`${this._nextDrupalApi}/${path}`)

		const response = await this.fetch(path, { ...options, noProxy: true })

		if (!response?.ok) {
			await this.handleJsonApiErrors(response)
		}

		const data = await response.json()

		if (options.withCache) {
			await this.cache.set(options.cacheKey, JSON.stringify(data))
		}

		return data
	}

	async getRoute(slug, locale, options = {}) {
		// // @todo: with auth
		// if (options?.withCache) {
		// 	const cached = (await this.cache.get(options.cacheKey))

		// 	if (cached) {
		// 		this._debug(`Returning cached router item for /${locale}${slug} using key ${options.cacheKey}`)
		// 		return JSON.parse(cached)
		// 	}
		// }

		slug = `/${slug}`.replace("//", "/")
		const path = `${locale}/api/router?path=${slug}`
		this._debug(`Fetching router item for page /${locale}${slug}.`)
		this._debug(`${this._nextDrupalApi}/${path}`)

		const response = await this.fetch(path, { ...options, noProxy: true })

		if (!response?.ok) {
			await this.handleJsonApiErrors(response)
		}

		return response

		// const data = await response.json()

		// if (options.withCache) {
		// 	await this.cache.set(options.cacheKey, JSON.stringify(data))
		// }

		// return data
	}

	async getNode(router, params, locale, slug, options = {}) {
		if (options?.withCache) {
			const cached = await this.cache.get(options.cacheKey)

			if (cached) {
				this._debug(
					`Returning cached node ${router.entity.id} / ${router.entity.bundle} for /${locale}/${slug} using key ${options.cacheKey}`
				)
				return JSON.parse(cached)
			}
		}

		const jsonParams = query(params)
		this._debug(
			`Fetching node ${router.entity.id} / ${router.entity.bundle} for /${locale}/${slug}.`
		)
		this._debug(router.jsonapi.individual + "?" + jsonParams)

		const response = await this.fetch(router.jsonapi.individual + "?" + jsonParams, {
			...options,
			absoluteUrl: true,
		})

		if (!response?.ok) {
			await this.handleJsonApiErrors(response)
		}

		const data = await response.json()
		const jsonApiData = this.deserialize(data)?.data

		if (options.withCache) {
			await this.cache.set(options.cacheKey, JSON.stringify(jsonApiData))
		}

		return jsonApiData
	}

	async getNodeCollection(type, options = {}) {
		if (!options.locale) {
			throw new Error("[drupal-client] Calling getNodeCollection without option.locale")
		}

		// if (options?.withCache) {
		// 	const cached = (await this.cache.get(options.cacheKey))

		// 	if (cached) {
		// 		this._debug(`Returning node collection ${type} using key ${options.cacheKey}`)
		// 		return JSON.parse(cached)
		// 	}
		// }

		const jsonParams = query(options?.params || [])
		this._debug(`Fetching collection ${type}.`)
		this._debug(
			decodeURIComponent(
				`${process.env.NEXT_PUBLIC_BASE_URL}/${this._nextApiProxy}/${options.locale}/api/node/${type}?${jsonParams}`
			)
		)

		const response = await this.fetch(
			`${options.locale}/api/node/${type}?${jsonParams}`,
			{ ...options }
		)

		if (!response?.ok) {
			await this.handleJsonApiErrors(response)
		}

		const data = await response.json()
		// const jsonApiDocument = this.deserialize(data)

		// if (options.withCache) {
		// 	await this.cache.set(options.cacheKey, JSON.stringify(jsonApiDocument))
		// }

		return data
	}

	deserialize(body) {
		if (!body) return null

		return deserialise(body)
	}

	_debug(message) {
		!!this.debug && this.logger.debug("[drupal-client]", message)
	}

	async getErrorsFromResponse(response) {
		const type = response.headers.get("content-type")
		let message = `URL: ${response.url}`

		if (type === "application/json") {
			const error = await response.json()
			message += " " + error.message
			return message
		}

		// Construct error from response.
		// Check for type to ensure this is a JSON:API formatted error.
		// See https://jsonapi.org/format/#errors.
		if (type === "application/vnd.api+json") {
			const _error = await response.json()

			if (_error?.errors?.length) {
				message += " " + _error.errors.join(" ,")
				return message
			}
		}

		message += " " + response.statusText
		return message
	}

	// Error handling.
	// If throwErrors is enable, we show errors in the Next.js overlay.
	// Otherwise we log the errors even if debugging is turned off.
	// In production, errors are always logged never thrown.
	throwError(error) {
		if (!this.throwJsonApiErrors) {
			return this.logger.error(error)
		}

		throw error
	}

	async handleJsonApiErrors(response) {
		if (!response?.ok) {
			// console.log(response.url)
			// console.log(response.headers)
			// throw new Error(response)
			const errors = await this.getErrorsFromResponse(response)
			throw new JsonApiErrors(errors, response.status)
		}
	}
}
