import Image from "next/image"
import clsx from "clsx"
import { Link, Icon, Heading } from "@/ui"
import { useI18n } from "@/hooks"

export const NewsCard = ({
	image,
	category,
	date,
	className,
	url,
	title,
	excerpt,
	isPremium,
	...rest
}) => {
	const { t } = useI18n()

	date = new Date(date).toLocaleDateString("fr-FR")

	return (
		<article className={clsx("cursor-default shadow-gray", className)} {...rest}>
			<div className="!h-full w-full flex flex-col">
				<div className="aspect-w-16 aspect-h-9">
					<Link href={url} className="bg-gray-200">
						{image && (
							<Image
								src={image._default}
								alt={title || "news card image"}
								className="h-full w-full object-cover"
								layout="fill"
								width={image.meta.width}
								height={image.meta.height}
								sizes={[
									{
										size: "decoupled_image_298_168",
										media: "(min-width: 352px)",
									},
									{
										size: "decoupled_image_398_224",
										media: "(min-width: 480px)",
									},
									{
										size: "decoupled_image_334_188",
										media: "(min-width: 768px)",
									},
									{
										size: "decoupled_image_454_255",
										media: "(min-width: 1024px)",
									},
									{
										size: "decoupled_image_344_194",
										media: "(min-width: 1200px)",
									},
								]}
							/>
						)}
					</Link>
				</div>
				<div className="flex-1 !h-full bg-white p-6 flex flex-col items-start space-y-3 md:space-y-5">
					<div className="text-xs flex items-center w-full">
						<span
							// href="#!"
							className="text-secondary font-bold uppercase"
						>
							{category}
						</span>
						{date && (
							<time className="border-l border-black px-4 py-1 ml-4" dateTime={date}>
								{date}
							</time>
						)}
						{isPremium && (
							<div
								title={t("Nx:This is a premium content")}
								className="border border-current p-1 text-black ml-auto"
							>
								<span className="sr-only">This is a premium content</span>
								<Icon className="ml-auto" id="lock-2" width="14px" height="14px" />
							</div>
						)}
					</div>
					<Heading level={5} title={title}>
						<Link href={url}>{title}</Link>
					</Heading>
					{/* Limitted in only 4 lines no not render the card too long */}
					<p className="text-base line-clamp-4 !mb-4">{excerpt}</p>

					<Link href={url || ".#"} variant="permalink" className="uppercase !mt-auto">
						{t("NX: Lire Plus")}
					</Link>
				</div>
			</div>
		</article>
	)
}
