export const button = {
	base: "focus:outline-none transition ease-in-out duration-300 border border-solid inline-flex gap-2",
	disabled: "opacity-50 cursor-not-allowed",
	pill: "rounded-full",
	icon: "flex items-center gap-2",
	size: {
		small: "px-2 py-1 text-sm",
		normal: "text-sm leading-[21px] px-[10px] py-[9px]",
		large: "px-8 py-3 text-lg",
		xlarge: "px-10 py-5 text-3xl",
	},
	variant: {
		primary:
			"bg-indigo-500 hover:bg-white border-indigo-500 text-white hover:text-indigo-500",
		secondary: {
			base: "inline-flex items-center justify-center px-5 lg:px-7  xl:px-9 py-2.5 border border-gray500 relative isolate group w-full md:w-fit",
			textWrapper:
				"relative z-1 text-gray500 group-hover:text-white text-base font-medium uppercase",
		},
		"secondary-mre": {
			base: "inline-flex items-center justify-center px-10 py-2.5 border border-secondary relative isolate group w-full md:w-fit",
			textWrapper:
				"relative z-1 text-secondary group-hover:text-white text-base font-medium uppercase",
		},
		"secondary-white": {
			base: "inline-flex text-base items-center justify-center px-10 md:px-4 lg:px-10 pt-[10px] pb-[9px] border border-white relative isolate group",
			textWrapper: "relative z-1 text-black group-hover:text-white font-medium uppercase",
		},
		"secondary-black": {
			base: "inline-flex items-center justify-center px-5 py-2.5 border border-black relative isolate group w-full md:w-fit",
			textWrapper:
				"relative z-1 text-black group-hover:text-white text-base font-medium uppercase",
		},
		outlined: {
			base: "inline-flex items-center justify-center px-5 py-2.5 border border-white/50 hover:border-white group bg-white bg-opacity-25 hover:bg-opacity-50",
			textWrapper:
				"relative text-white group-hover:text-white text-base font-medium uppercase",
		},
		"gray-mre": {
			base: "w-full inline-flex items-center justify-center px-5 py-2 border border-[#80807E] group bg-transparent hover:bg-[#80807E] text-[#80807E] hover:text-white font-bold",
			textWrapper:
				"relative text-white group-hover:text-white text-base font-bold uppercase",
		},
		"play-video":
			"text-white rounded-full overflow-hidden p-1 transition duration-300 ease-in hover:scale-110",

		danger:
			"bg-red-500 border-red-500 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white",
		toolBox:
			"relative w-full inline-flex items-center justify-center bg-white hover:bg-indigo-500 text-indigo-500 hover:text-white text-sm font-bold rounded-md",
		roundedAddonAfter:
			"bg-blue-600 border-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 text-white hover:text-white rounded-r-md",
		roundedAddonBefore:
			"bg-blue-600 border-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 text-white hover:text-white rounded-l-md",
		inputDate:
			"inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white rounded shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-0",
		chevronIconInputDate: "bg-gray-1000 text-blue-1000",
		headerDate: "bg-gray-1000 hover:text-blue-1000",
		mre: {
			base: "absolute inset-0 border-inherit",
			corner:
				"absolute border-inherit border-l-2 top-0 ltr:left-0 rtl:left-0 w-5 h-5 transform rotate-45 translate-x-1 translate-y-1 rtl:-translate-x-[8px] rtl:-translate-y-[9px]",
			border: {
				top: "absolute border-inherit border-t-2 top-0 right-0 left-3.5 rtl:right-0 rtl:left-3.5",
				left: "absolute border-inherit ltr:border-l-2 rtl:border-l-2 left-0 rtl:left-0 bottom-0 top-3.5",
			},
			bg: "absolute inset-y-0 ltr:right-0 rtl:right-0 w-full group-hover:w-0 transition",
			textWrapper:
				"relative pt-o pb-0 z-1 text-base font-medium leading-none whitespace-nowrap uppercase",
		},
	},
	outlineVariant: {
		primary: `bg-transparent hover:bg-indigo-500 border-indigo-500 text-indigo-500 hover:text-white`,
		secondary: `bg-transparent border-gray-200 text-gray-600 hover:text-white hover:bg-gray-200`,
		danger: `bg-transparent border-red-500 text-red-500 hover:text-white hover:bg-red-500`,
	},
}
