import clsx from "clsx"
import { Link } from "../link/Link"
import { Icon } from "../icon/Icon"
import { breadcrumb } from "./theme"

export const Breadcrumb = ({
	homeUrl,
	className = "",
	variant = "default",
	pages = [],
}) => {
	return (
		<div className={clsx(breadcrumb[variant].wrapper, className)}>
			<ol role="list" className={breadcrumb[variant].list}>
				{homeUrl && (
					<li className={breadcrumb[variant].listElement}>
						<Link href={homeUrl} className={breadcrumb[variant].link}>
							<Icon
								id={breadcrumb[variant].homeIcon.id}
								className={breadcrumb[variant].homeIcon.className}
								width={breadcrumb[variant].homeIcon.width}
								height={breadcrumb[variant].homeIcon.height}
							/>
						</Link>
					</li>
				)}
				{pages.map((page, index) => {
					return (
						<li key={page.id} className={breadcrumb[variant].listElement}>
							{page.name == "<none>" || (index == 0 && !homeUrl) ? null : (
								<Icon
									className={breadcrumb[variant].separateIcon.className}
									id={breadcrumb[variant].separateIcon.id}
									width={breadcrumb[variant].separateIcon.width}
									height={breadcrumb[variant].separateIcon.height}
								/>
							)}

							{page.name !== "<none>" &&
								(page.href ? (
									<Link
										href={page.href}
										className={
											page.current
												? breadcrumb[variant].linkActive
												: breadcrumb[variant].link
										}
										aria-current={page.current ? "page" : undefined}
									>
										{page.name}
									</Link>
								) : (
									page.name
								))}
						</li>
					)
				})}
			</ol>
		</div>
	)
}
