import { useState } from "react"
import { Icon, Button, Heading, Container } from "@/ui"
import { useI18n as useTranslation } from "@/hooks"
import clsx from "clsx"
import Image from "next/image"

export const config = {
	id: "mre_templates:select_redirect",
}

const CornerImage = ({ image, position = "top" }) => {
	let positions = {
		top: "-top-12",
		bottom: "-bottom-9",
	}
	return (
		<div
			className={clsx(
				`absolute scale-x-[-1] w-[150px] md:w-[250px] h-[90px] ${positions[position]} left-2`
			)}
		>
			<Image
				src={image._default}
				layout="fill"
				className="w-full h-full object-contain"
			/>
		</div>
	)
}

export const PaysSelect = ({
	title,
	line,
	taxonomy,
	button_link,
	isSelect,
	select_bg_image,
	position_corner_image,
	corner_image,
	link,
}) => {
	const { t } = useTranslation()
	const [selectedPays, setSelectedPays] = useState(null)
	let styles = {}
	let buttonProps = {
		variant: "mre",
	}

	if (select_bg_image) {
		buttonProps.color = "white"
	}

	if (select_bg_image && select_bg_image._default) {
		styles = {
			backgroundImage: `url(${select_bg_image._default})`,
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
		}
	}

	return (
		<div>
			{title && (
				<div className="flex justify-center mb-12">
					<Heading variant="mre">{title}</Heading>
				</div>
			)}
			<div className="relative w-full h-[241px] flex justify-center items-center py-10 md:py-20 overflow-hidden">
				<Icon
					id="awb-symbol-outline"
					height="241"
					width="874"
					className={clsx(
						"absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-current",
						"text-secondary",
						"bg-clip-text bg-gradient-to-r from-primary to-secondary fill-current"
					)}
				/>
				<Container layout="select-pays">
					<div
						className={clsx(
							"relative w-full flex flex-col md:flex-row gap-6 md:gap-3 lg:gap-8 justify-between items-center h-auto md:h-[150px] p-5  px-10 shadow-md",
							select_bg_image ? "text-white" : "bg-white text-gray-800"
						)}
						style={styles}
					>
						{corner_image && (
							<CornerImage position={position_corner_image} image={corner_image} />
						)}
						<span
							className={clsx(
								"inline-block font-medium flex-1",
								isSelect ? "text-xl" : "text-2xl"
							)}
						>
							{line}
						</span>
						<div className="flex-1 w-full md:w-auto">
							{isSelect ? (
								<select
									className="text-gray-800 w-full font-bold py-3"
									onChange={(e) =>
										setSelectedPays(
											taxonomy.find((term) => term.tid == e.target.value) || null
										)
									}
								>
									<option value="-1">{t("Nx:Sélectionner un pays")}</option>
									{taxonomy.map((term) => (
										<option key={term.tid} value={term.tid}>
											{term.name}
										</option>
									))}
								</select>
							) : (
								<>
									{button_link ? (
										<div className="flex-1 flex justify-end items-center w-full">
											<Button
												variant={select_bg_image ? "mre-white" : "mre"}
												href={button_link.url || ".#"}
											>
												{button_link.title}
											</Button>
										</div>
									) : (
										""
									)}
								</>
							)}
						</div>
						{isSelect ? (
							<div className="flex-1 flex justify-end items-center w-full">
								{selectedPays && (
									<Button {...buttonProps} href={selectedPays.link}>
										{button_link.title}
									</Button>
								)}
							</div>
						) : (
							""
						)}
					</div>{" "}
				</Container>
			</div>
			{link && link.url && (
				<Container layout="select-pays">
					<div className="flex justify-center items-center pt-20">
						<Button {...buttonProps} href={link.url}>
							{link.title}
						</Button>
					</div>
				</Container>
			)}
		</div>
	)
}

const PaysSelectContainer = ({ data }) => {
	const props = {
		title: data?.components[0]?.title,
		line: data?.components[0]?.line,
		button_link: data?.components[0]?.button_link,
		isSelect: data?.components[0]?.isSelect === 1,
		select_bg_image:
			data?.components[0]?.select_bg_image &&
			data?.components[0]?.select_bg_image.length > 0
				? data?.components[0]?.select_bg_image[0]
				: null,
		link: data?.extra_field?.link ? data?.extra_field?.link : null,
		corner_image:
			data?.extra_field?.corner_image && data?.extra_field?.corner_image.length > 0
				? data?.extra_field?.corner_image[0]
				: null,
		position_corner_image: data?.extra_field?.position_corner_image,
		taxonomy: data?.components[0]?.collection?.data?.data.map((item) => ({
			name: item?.attributes?.name,
			link: item?.attributes?.field_pays_link?.uri,
			tid: item?.attributes?.drupal_internal__tid,
		})),
	}

	return <PaysSelect {...props} />
}

export default PaysSelectContainer
