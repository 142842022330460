import { Fragment } from "react"
import { deserialise } from "kitsu-core"
import get from "lodash.get"
import { Button, Wysiwyg, Heading, Text, Container } from "@/ui"
import { normalizeNodes } from "./normalizer"
import { ContactsUtilesCard } from "./ContactsUtilesCard"

const BackgroundImage = ({ image }) => {
	let styles = {}
	let bgColor = "bg-secondary"
	if (image && image._default) {
		bgColor = ""
		styles = {
			backgroundImage: `url(${image._default})`,
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
		}
	}
	return (
		<div
			className={`w-full h-[250px] absolute -top-10 z-10 ${bgColor}`}
			style={styles}
		></div>
	)
}

const NodeQueue = ({ data }) => {
	const title = get(data, "extra_field.title", "")
	const raw_description = get(data, "extra_field.description.value.#text", null)
	const description = <Wysiwyg html={raw_description} />
	const link = get(data, "extra_field.link.url", null)
	const link_label = get(data, "extra_field.link.title", "")
	const image = get(data, "extra_field.image", "")

	let nodes = []
	data?.components.map((item) => {
		const items = deserialise(get(item, "nodes.data", {}))
		nodes = [...nodes, ...items?.data]
	})

	nodes = normalizeNodes(nodes)
	return (
		<div className="relative w-full my-10">
			<BackgroundImage image={image[0]} />
			<div className="relative max-w-5xl mx-auto z-20">
				<Container>
					<div className="text-center mb-10">
						{title && (
							<Heading variant="mre" className="text-center mb-5 text-white">
								{title}
							</Heading>
						)}
						{raw_description.length > 0 && (
							<Text className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
								{description}
							</Text>
						)}
					</div>
					{nodes.length > 0 && (
						<div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 md:max-w-none">
							{nodes.slice(0, 2).map((post) => (
								<Fragment key={post.id}>
									<ContactsUtilesCard {...post} />
								</Fragment>
							))}
						</div>
					)}
					{link && (
						<div className="flex justify-center mt-12">
							<Button variant="mre" href={link}>
								{link_label}
							</Button>
						</div>
					)}
				</Container>
			</div>
		</div>
	)
}

export const config = {
	id: "mre_templates:contacts_utiles_node_queue",
}

export default NodeQueue
