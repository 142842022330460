import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { toRegister } from "../utils/toRegister"
import { useI18n as useTranslation } from "@/hooks"
import { Input } from "@/ui"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const TimeField = ({ id, name, field }) => {
	const {
		label,
		placeholder,
		htmlInputType,
		helperText,
		validation,
		shouldDisplay,
		states,
	} = field
	const { t } = useTranslation()
	const { register, watch } = useFormContext()
	const errorMessage = useErrorMessage(name, label)
	const values = watch({ nest: true })
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	const local_state = useCheckConditions(states)

	if (!isVisible) return null
	if (local_state?.invisible) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	return (
		<Input
			// label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
			label={t(`Nx:${label}`)}
			required={local_validation?.required}
			variant="mre"
			placeholder={placeholder}
			// addonAfter ={ null}
			// addonBefore ={ null}
			// prefix ={ null}
			// sufix ={ null}
			// type={"text"}
			type={htmlInputType || "time"}
			{...register(name, toRegister(t("Nx:" + label), local_validation, values, t))}
			// handleInputChange
			hasError={!!errorMessage}
			errorMessage={errorMessage}
			description={helperText}
			// props

			id={name}
			data-testid={id}
			name={name}
		/>
	)
}
