import get from "lodash.get"
import { stripHtml } from "@/lib"
import truncate from "truncate"
import { Wysiwyg } from "@/ui"

export const normalizeNode = (node) => {
	return {
		phone: node?.field_vactory_telephone,
		email: node?.field_vactory_email,
		website: node?.field_vactory_website,
		adresse: node?.field_vactory_address?.value ? (
			<Wysiwyg html={node?.field_vactory_address?.value} />
		) : null,
		type: node?.field_vactory_taxonomy_3?.name,
	}
}

export const normalizeNodes = (nodes) => {
	return (
		nodes?.map((post) => ({
			id: post.drupal_internal__nid,
			title: post.title,
			url: get(post, "path.alias", "#."),
			phone: get(post, "field_vactory_telephone", null),
			email: get(post, "field_vactory_email", null),
			website: get(post, "field_vactory_website", null),
			adresse: truncate(stripHtml(get(post, "field_vactory_address.processed", "")), 100),
		})) || []
	)
}

export const normalizeTerms = (terms) => {
	return terms.map((term) => ({
		id: term.drupal_internal__tid,
		name: term.name,
	}))
}
