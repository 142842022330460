import { DrupalClient } from "./client"
import { redis } from "../cache"

export const redisCache = {
	async set(key, value) {
		if (!redis?.set) {
			return
		}
		return await redis.set(key, value)
	},

	async get(key) {
		if (!redis?.get) {
			return Promise.resolve(false)
		}
		return await redis.get(key)
	},
}

export const drupal = new DrupalClient({
	cache: redisCache,
	debug: "DRUPAL_CLIENT_DEBUG" in process.env || "development" === process.env.NODE_ENV,
	nextDrupalApi: process.env.NEXT_PUBLIC_DRUPAL_BASE_URL,
})
