import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { useI18n as useTranslation } from "@/hooks"
import { toRegister } from "../utils/toRegister"
import { InputRadio } from "@/ui"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const RadiosField = ({ name, field }) => {
	const { label, validation, shouldDisplay, states, helperText } = field
	const { t } = useTranslation()
	// const { register, watch, defaultValues, getValues } = useFormContext()
	const { watch, control, defaultValues, getValues } = useFormContext()
	const values = watch({ nest: true })
	const errorMessage = useErrorMessage(name, label)
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	const local_state = useCheckConditions(states)

	if (!isVisible) return null

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}
	return (
		<>
			<Controller
				control={control}
				name={name}
				rules={toRegister(t("Nx:" + label), local_validation, values, t)}
				render={({ field: { onChange, value } }) => (
					<InputRadio
						variant="mre"
						// label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
						label={t(`Nx:${label}`)}
						isRequired={local_validation?.required}
						name={name}
						options={field.options.map((radio) => ({
							value: radio.value,
							label: radio.label || radio.name,
							checked: defaultValues[name]?.includes(radio.value),
						}))}
						defaultValue={field.options.find((option) => {
							return option.value === getValues(name)
						})}
						selectedInput={value}
						setSelectedInput={(e) => {
							onChange(e.value)
						}}
						hasError={!!errorMessage}
						errorMessage={!!errorMessage && errorMessage}
						disabled={local_state.disabled}
						description={helperText}
						aria-label={t(`Nx:${label}`)}
					/>
				)}
			/>
		</>
	)
}
