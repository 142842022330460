import PropTypes from "prop-types"
import clsx from "clsx"
import { container, ContainerSpace } from "./theme"

const Container = ({
	children,
	layout = "default",
	spacing = "default",
	className = "",
	...props
}) => {
	return (
		<div
			className={clsx(container[layout], ContainerSpace[spacing], className)}
			{...props}
		>
			{children}
		</div>
	)
}

Container.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	layout: PropTypes.string,
}

export { Container }
