import { useCallback, useReducer } from "react"
import get from "lodash.get"
import qs from "qs"
import { useI18n as useTranslation } from "@/hooks"
import { useRouter } from "next/router"
import reducer, { initialState } from "./reducer"
import { fetching, success, error } from "./actionCreators"
import axios from "axios"
import { useSession } from "next-auth/react"
import { process } from "../../process/process"

export const useWebformRequest = () => {
	const router = useRouter()
	const { locale } = router
	const { activeLocale } = useTranslation()
	const [state, webformRequestDispatch] = useReducer(reducer, initialState) // eslint-disable-line no-unused-vars
	const { data } = useSession()
	const accessToken = data?.accessToken
	const provider = data?.provider
	const submitWebform = useCallback(
		async (data, schema) => {
			var headers = {
				"content-type": "application/x-www-form-urlencoded",
			}
			if (accessToken) {
				headers["Authorization"] = `Bearer ${accessToken}`
				if (provider !== "credentials") {
					headers["X-Auth-Provider"] = provider
				}
			}

			const formattedData = formatData(data)
			const processedData = revertProcessData(formattedData, schema)

			webformRequestDispatch(fetching())
			try {
				const response = await axios.post(
					process.env.NEXT_PUBLIC_DRUPAL_BASE_URL + "/" + locale + "/_webform",

					qs.stringify(processedData),
					{
						headers: headers,
					}
				)
				webformRequestDispatch(success(response))
				return response
			} catch (e) {
				const apiErrorMessage = get(e, "response.data.error")
				const errorMessage = apiErrorMessage ? apiErrorMessage : e.message
				webformRequestDispatch(error(errorMessage))
				// throw new Error(errorMessage)
				throw errorMessage
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeLocale]
	)

	return submitWebform
}

const checkObjContainsFid = (obj) => {
	if (obj["fid"] !== undefined) {
		return true
	} else {
		return false
	}
}

const formatData = (data) => {
	var newData = {}
	for (var record in data) {
		if (Array.isArray(data[record])) {
			var newArray = data[record].map((item) => {
				if (checkObjContainsFid(item)) {
					return item.fid
				} else {
					return item
				}
			})
			newData[record] = newArray
		} else if (typeof data[record] === "object") {
			if (checkObjContainsFid(data[record])) {
				newData[record] = data[record].fid
			} else {
				newData[record] = data[record]
			}
		} else {
			newData[record] = data[record]
		}
	}
	return newData
}

const revertProcessData = (data, schema) => {
	var formattedData = {}
	for (var record in data) {
		if (process[schema[record]?.["revertProcess"]]) {
			formattedData[record] = process[schema[record]?.["revertProcess"]](data[record])
		} else {
			formattedData[record] = data[record]
		}
	}
	return formattedData
}
