import clsx from "clsx"
import Image from "next/image"

import { Button, Heading } from "@/ui"

export const config = {
	id: "mre_templates:find_agency",
}

export const FindAgency = ({
	title,
	intro,
	bgColor,
	link,
	id,
	className,
	rel,
	image,
	target,
}) => {
	return (
		<div
			className={`relative w-full h-[450px]`}
			style={{
				backgroundColor: bgColor ? bgColor : "#f5c000",
			}}
		>
			{image.src && image.width && (
				<div className="absolute top-0 transform rtl:-scale-x-100 ltr:right-0 rtl:left-0  bottom-0 h-full z-0 bg-transparent">
					<Image {...image} className="w-full h-full object-cover" layout="raw" />
				</div>
			)}
			<div className="flex h-full items-center mx-auto my-auto max-w-6xl px-4">
				<div className="bg-white w-fit lg:w-[60%] p-9 relative shadow-md z-3">
					{title && (
						<Heading variant="mre-agency" className="text-left" icon="left">
							{title}
						</Heading>
					)}
					{intro && (
						<p className="mt-7 mb-6 md:mb-10 font-[400] md:font-normal text-[15px] md:text-[24px] leading-[20px] md:leading-9">
							{intro}
						</p>
					)}
					{link?.url && link?.title && (
						<Button
							href={link.url}
							target={target}
							rel={rel}
							id={id}
							variant="mre"
							className={clsx(className, "px-12")}
						>
							{link.title}
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}

const FindAgencyContainer = ({ data }) => {
	const props = {
		title: data?.components?.[0]?.title,
		intro: data?.components?.[0]?.intro,
		bgColor: data?.components?.[0]?.bgColor,
		link: data?.components?.[0]?.link,
		id: data?.components?.[0]?.link?.attributes?.id,
		className: data?.components?.[0]?.link?.attributes?.class,
		target: data?.components?.[0]?.link?.attributes?.target,
		rel: data?.components?.[0]?.link?.attributes?.rel,
		image: {
			src: data?.components?.[0].background?.[0]?._default,
			width: data?.components?.[0].background?.[0]?.meta?.width,
			height: data?.components?.[0].background?.[0]?.meta?.height,
			alt: data?.components?.[0].background?.[0]?.meta?.alt,
		},
	}

	return <FindAgency {...props} />
}

export default FindAgencyContainer
