import { Heading, Text, Icon, Link } from "@/ui"
import Image from "next/image"
import clsx from "clsx"

export const config = {
	id: "mre_templates:list_card_icons",
}

const ListCardIconsWidget = ({ data }) => {
	const title = data?.extra_field?.title
	const items = data?.components
	const background = data?.extra_field?.image[0]

	return (
		<div className={clsx("relative")}>
			{background?._default && (
				<div className="absolute inset-0 -z-1">
					<Image
						src={background?._default}
						alt={background?.meta?.alt || "list card icons image"}
						layout="fill"
						objectFit="cover"
					/>
				</div>
			)}
			<div className="py-12 container">
				<Heading variant="mre" level="2" className="text-center mb-12">
					{title}
				</Heading>
				<div className="flex flex-col md:flex-row justify-around gap-5">
					{items.map((item, index) => (
						<div
							key={`card_${index + 1}`}
							className="flex-1 p-px group transition-transform transform md:hover:scale-95 shadow-gray"
						>
							<Link
								href={item.link.url}
								className="md:flex-col md:items-start flex h-full w-full py-6 px-5 md:p-6 text-black bg-white"
							>
								<Icon
									id={item.icon ? item.icon : "question"}
									width="60px"
									height="60px"
									className="h-16 w-16 mr-4 flex-shrink-0 group-hover:text-secondary mb-2 md:mb-3 fill-current"
								/>
								<div className="h-full flex flex-col">
									<Text
										as="p"
										className="text-base md:text-2xl font-bold group-hover:text-secondary mb-5"
									>
										{item.title}
									</Text>
									<Link
										variant="permalink"
										className="group-hover:text-secondary mt-auto"
									>
										{item.link.title}
									</Link>
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default ListCardIconsWidget
