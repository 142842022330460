export const backTotop = {
	default: {
		className:
			"border border-gray-50 bg-white bottom-10 fixed z-[50] hover:text-blue-500 ltr:right-10 p-3 rounded-full rtl:left-10 shadow-2xl transition",
		showAt: 40,
		speed: 1500,
		easing: "easeInOutQuint",
	},
	v2: {
		className:
			"border-2 border-indigo-500 text-indigo-500 bg-white bottom-10 fixed z-[50] hover:text-white hover:bg-indigo-500 ltr:right-10 p-3 rtl:left-10 shadow-2xl transition duration-300 ease-in ",
		showAt: 40,
		speed: 1500,
		easing: "easeInOutQuint",
	},
	mre: {
		className:
			"group z-[50] bg-primary text-gray-700 hover:text-white hover:bg-secondary bottom-[80px] md:bottom-10 fixed ltr:right-4 md:ltr:right-10 p-3.5 md:p-4 rtl:left-4 md:rtl:left-10 shadow-2xl",

		showAt: 40,
		speed: 1500,
		easing: "easeInOutQuint",
	},
}
