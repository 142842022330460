import get from "lodash.get"
import { Button, Wysiwyg, Icon } from "@/ui"
import { useRouter } from "next/router"

export const config = {
	id: "vactory_decoupled_webform:webform_confirmation",
	lazy: true,
}

export const WebFormConfirmationPage = ({ data }) => {
	const backToFormLabel = get(data, "components.0.link", "")
	const router = useRouter()
	let { isSubmitted, message, destination } = router.query
	if (isSubmitted === undefined && message === undefined) return null
	if (isSubmitted || message)
		return (
			<ConfirmationMessage
				message={message}
				btnLabel={backToFormLabel}
				destination={destination}
			/>
		)
}

export const ConfirmationMessage = ({
	message,
	btnLabel = "",
	destination = "",
	close = () => {},
}) => {
	return (
		<>
			<div className="py-[30px]" id="confirmation-message">
				<div className="relative bg-green-500 pl-5 pr-10 py-4 rounded">
					<span className="text-sm">
						<Wysiwyg html={message} />
					</span>
					<button className="absolute right-3 top-3" onClick={close}>
						<Icon id="close" className="fill-current w-3 h-3" />
					</button>
				</div>
			</div>

			{btnLabel !== "" && destination !== "" && (
				<Button href={destination || ".#"}>{btnLabel}</Button>
			)}
		</>
	)
}

export default WebFormConfirmationPage
