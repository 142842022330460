import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { Recaptcha } from "@/ui"
import { toRegister } from "../utils/toRegister"
import { useI18n as useTranslation } from "@/hooks"

export const ReCaptchaField = ({ field }) => {
	const { t } = useTranslation()
	const { label, validation, shouldDisplay } = field
	const { register, watch, control } = useFormContext()
	const errorMessage = useErrorMessage("g-recaptcha-response", label)
	const values = watch({ nest: true })
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	return isVisible ? (
		<Controller
			control={control}
			name="g-recaptcha-response"
			rules={toRegister("g-recaptcha-response", validation, values, t)}
			render={({ field: { onChange, value } }) => {
				return (
					<Recaptcha
						{...register(
							"g-recaptcha-response",
							toRegister("g-recaptcha-response", validation, values, t)
						)}
						hasError={!!errorMessage}
						errorMessage={!!errorMessage && errorMessage}
						onChange={onChange}
						value={value}
					/>
				)
			}}
		/>
	) : null
}
