export const cookieCompliance = {
	default: {
		wrapper: "fixed inset-x-0 bottom-0 z-10 bg-primary",
		container: "py-3 px-3 sm:px-6 lg:px-8 relative",
		paragraph: "text-white",
		btnAccept:
			"block text-xs bg-transparent border-none p-0 underline hover:bg-transparent",
		btnDecline:
			"block text-xs bg-transparent border-none p-0 underline hover:bg-transparent",
		btnPolicy: "text-xs underline whitespace-nowrap",
		buttonContainer:
			"absolute top-0 right-0 transform -translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 sm:order-3 sm:ml-3",
		buttonPosition:
			"-mr-1 flex p-2 rounded-full shadow transition bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2",
	},
	mre: {
		wrapper: "fixed inset-x-0 bottom-0 z-[60] bg-white shadow-md cookie-wrapper",
		container: "max-w-full px-3 md:px-8 mx-auto pt-6 md:pt-5 pb-6 md:pb-5 relative ",
		paragraph: "font-normal tracking-tight text-black text-[14px] leading-7 mr-3",
		btnAccept:
			"block text-black  text-xs bg-transparent border-none !p-0 !pr-3 underline hover:bg-transparent hover:text-primary",
		btnDecline:
			"block text-black text-xs bg-transparent border-none !p-0 underline hover:bg-transparent hover:text-primary",
		btnPolicy: "block text-xs underline whitespace-nowrap !p-0 !pr-3 hover:text-primary",
		buttonContainer: "absolute right-0 lg:right-3 top-1",
		buttonPosition:
			"absolute ltr:right-5 rtl:left-5 lg:ltr:right-11 lg:rtl:left-11 -top-5 flex p-4 rounded-full border-0 shadow transition bg-primary hover:bg-primary outline-none",
	},
}
