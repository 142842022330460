// paragraph_accordion
export const accordion = {
	default: {
		wrapper: "border border-primary shadow-gray bg-white py-3",
		element: "",
		button: {
			base: "p-4 md:py-5 md:px-8 text-black flex items-center flex-row-reverse text-left",
			inactive: "text-black",
			active: "text-secondary",
			icon: {
				base: "mr-4 w-3 h-7 flex-shrink-0",
				inactiveId: "plus",
				activeId: "minus",
				width: "20",
				height: "20",
			},
		},
		panel: {
			base: "px-7 transform text-base w-full overflow-hidden transition-all duration-300",
			inactive: "max-h-0 opacity-0",
			active: "opacity-1 pb-5",
		},
	},
	"image-links": {
		wrapper:
			"border max-w-full  md:max-w-3xl  lg:max-w-4xl mx-4 md:mx-auto border-primary shadow-gray bg-white py-3",
		element: "",
		button: {
			base: "py-5 px-8 text-black flex items-center flex-row-reverse text-left",
			inactive: "text-black",
			active: "text-secondary",
			icon: {
				base: "mr-4 w-3 h-7 flex-shrink-0",
				inactiveId: "plus",
				activeId: "minus",
				width: "20",
				height: "20",
			},
		},
		panel: {
			base: "w-full",
			inactive: "max-h-0 opacity-0",
			active: "max-h-fit md:max-h-[240px] opacity-1 pb-5",
		},
	},
	no_icon: {
		wrapper: "overflow-y-hidden z-50",
		element: "mb-3",
		button: {
			base: "flex justify-between w-full px-4 py-2 text-sm font-medium ltr:text-left rtl-text-right rounded-lg",
			inactive: "text-purple-900 bg-purple-100 hover:bg-purple-200",
			active: "text-purple-100 bg-purple-900 hover:bg-purple-800",
		},
		panel: {
			base: "px-4 text-sm text-gray-500 w-full overflow-hidden transition-all duration-300",
			inactive: "max-h-0",
			active: "max-h-screen pt-4 pb-2",
		},
	},
	secondary: {
		wrapper: "bg-indigo-100 p-10 rounded-xl",
		element: "mb-5",
		button: {
			base: "flex justify-between font-bold text-sm w-full px-5 py-4 border shadow border-indigo-500 rounded-full transition-all duration-300 ease-in",
			inactive: "bg-white hover:bg-indigo-500 text-indigo-500 hover:text-white",
			active: "bg-indigo-500 hover:bg-white text-white hover:text-indigo-500",
			icon: {
				base: "transition ease-in duration-300",
				inactiveId: "chevron-right",
				activeId: "chevron-down",
				width: "20",
				height: "20",
			},
		},
		panel: {
			base: "px-4 text-sm text-gray-500 w-full overflow-hidden transition-all duration-300",
			inactive: "max-h-0",
			active: "max-h-screen pt-4 pb-2",
		},
	},
	paragraph_accordion: {
		wrapper: "",
		element: "bg-primary-25 rounded-lg mb-4",
		button: {
			base: "text-lg leading-[25px] font-bold px-[30px] py-[22px] flex items-center justify-between w-full transition-all ease-in-out duration-300",
			inactive: "text-gray-900 hover:text-primary-500",
			active: "text-primary-500 hover:text-gray-900",
			icon: {
				id: "facebook",
				base: "transition ease-in duration-300 text-primary-500",
				inactive: "",
				active: "rotate-45",
				width: "25",
				height: "25",
				activeId: "accordion-mince",
				inactiveId: "accordion-plus",
			},
		},
		panel: {
			base: "px-[30px] pt-[0px] text-gray-500 text-base leading-6 transition ease-in duration-300 overflow-hidden",
			inactive: "max-h-0 pb-[0px]",
			active: "max-h-screen pb-[22px] ",
		},
	},
}
