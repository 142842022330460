export const checkbox = {
	default: {
		wrapper: "flex items-center gap-x-2",
		input:
			"h-5 w-5 rounded p-0.5 flex items-center justify-center transition duration-200",
		checked: {
			enabled: "bg-indigo-500 hover:ring-2 hover:ring-indigo-300 cursor-pointer",
			disabled: "bg-gray-300 cursor-not-allowed",
		},
		unchecked: {
			enabled: "border border-gray-300 cursor-pointer",
			disabled: "border border-gray-300 cursor-not-allowed",
		},
		label: {
			enabled: "text-sm",
			disabled: "text-sm text-gray-300",
		},
		icon: "h-4 w-4 text-white",
		errorMessage: "text-xs text-red-500 inline-block mt-1",
	},
	chaabi: {
		wrapper: "flex items-center gap-x-2",
		input:
			"h-5 w-5 rounded p-0.5 flex items-center justify-center transition duration-200",
		checked: {
			enabled: "bg-primary-500 hover:ring-2 hover:ring-primary-300 cursor-pointer",
			disabled: "bg-gray-300 cursor-not-allowed",
		},
		unchecked: {
			hasError: "border border-red-500 cursor-pointer",
			enabled: "border border-gray-300 cursor-pointer",
			disabled: "border border-gray-300 cursor-not-allowed",
		},
		label: {
			enabled: "text-sm",
			disabled: "text-sm text-gray-300",
		},
	},
	mre: {
		wrapper: "flex items-center gap-x-2 mt-6",
		input: "h-6 w-6 p-0.5 flex items-center justify-center",
		checked: {
			enabled: "bg-black cursor-pointer",
			disabled: "bg-gray-300 cursor-not-allowed",
		},
		unchecked: {
			enabled: "border-2 border-black cursor-pointer",
			disabled: "border-2 border-black cursor-not-allowed",
		},
		label: {
			enabled: "text-xs",
			disabled: "text-xs text-gray-300",
		},
		icon: "h-4 w-4 text-white",
		errorMessage: "text-xs text-red-500 inline-block mt-1",
	},
	"mre-white": {
		wrapper: "flex items-center gap-x-2 mt-6",
		input: "h-5 w-5 p-0.5 flex items-center justify-center",
		checked: {
			enabled: "bg-black cursor-pointer border-2 border-white",
			disabled: "bg-gray-300 cursor-not-allowed",
		},
		unchecked: {
			enabled: "bg-white cursor-pointer",
			disabled: "border-2 border-black cursor-not-allowed",
		},
		label: {
			enabled: "text-xs",
			disabled: "text-xs text-gray-300",
		},
		icon: "h-4 w-4 text-white",
		errorMessage: "text-xs text-red-500 inline-block mt-1 text-left w-full",
	},
}
