import { useEffect, useState } from "react"
import Cookies from "js-cookie"
import isClient from "is-client"
import { Button, Link, Wysiwyg, Icon } from "@/ui"
import { cookieCompliance } from "./theme"
import clsx from "clsx"
import { dlPush } from "@/lib/gtm"

export const CookieComplianceLayer = ({
	body,
	actionLabel,
	declineLabel,
	show = false,
	variant = "default",
	cookieLifeTime = 300,
	privacyPolicy = {},
	...rest
}) => {
	const cookieName = "CookieConsent"
	const [showCookie, setShowCookie] = useState(show)

	const onAccept = () => {
		Cookies.set(
			cookieName,
			{
				necessary: true,
				analytics: true,
				marketing: true,
				preferences: true,
			},
			{ expires: cookieLifeTime }
		)
		dlPush("cookie_consent_given", {
			necessary: true,
			analytics: true,
			marketing: true,
			preferences: true,
		})
		setShowCookie(false)
	}

	const onDecline = () => {
		Cookies.set(
			cookieName,
			{
				necessary: true,
				analytics: false,
				marketing: false,
				preferences: false,
			},
			{ expires: cookieLifeTime }
		)
		setShowCookie(false)
	}

	// Check cookie on load.
	useEffect(() => {
		if (Cookies.get(cookieName) === undefined) {
			setShowCookie(true)
		}
	}, [])

	// No cookie layer on SSR.
	if (!isClient()) {
		return null
	}

	return (
		<>
			{showCookie && (
				<div className={clsx(cookieCompliance[variant].wrapper)}>
					<div className={cookieCompliance[variant].container}>
						<div
							className={clsx(
								cookieCompliance[variant].paragraph,
								"lg:flex justify-between items-center"
							)}
						>
							<Wysiwyg html={body} textVariant="small" {...rest} />
							<div className="w-full sm:max-w-[250px]  xl:max-w-xs mx-auto flex content-center	items-center mt-1 lg:mt-0">
								{privacyPolicy && (
									<Link
										href={privacyPolicy?.url}
										target={privacyPolicy?.attributes?.target}
										className={clsx(
											privacyPolicy?.attributes?.class,
											cookieCompliance[variant].btnPolicy
										)}
									>
										{privacyPolicy?.title}
									</Link>
								)}
								<div className="lg:mt-0 mt-3 flex flex-col w-full mx-auto gap-2 justify-center  items-center">
									<Button variant="mre" className="capitalize !w-full" onClick={onAccept}>
										{actionLabel}
									</Button>
									{declineLabel && (
										<Button variant="gray-mre" className="!w-full" onClick={onDecline}>
											{declineLabel}
										</Button>
									)}
								</div>
							</div>
						</div>
						<div className={cookieCompliance[variant].buttonContainer}>
							<Button
								onClick={onDecline}
								className={cookieCompliance[variant].buttonPosition}
								icon={<Icon id="close-bold" width="14" height="14" />}
								aria-label="Close cookie layer"
							></Button>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
