import { TwoColumnImage } from "@/ui"

export const config = {
	id: "mre_templates:two_column_image",
}

const TwoColumnImageWidget = ({ data }) => {
	const props = {
		title: data?.components[0]?.title || null,
		content: data?.components[0]?.content.value["#text"] || "",
		image:
			data?.components[0]?.image && data?.components[0]?.image?.length > 0
				? data.components[0]?.image[0]
				: null,
		link: data?.extra_field?.link || null,
	}
	return <TwoColumnImage {...props} />
}

export default TwoColumnImageWidget
