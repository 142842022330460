import { Wysiwyg, Text } from "@/ui"
import clsx from "clsx"

function Introduction({ divClassName, variant, className, content }) {
	const text = content?.value?.["#text"]
	return (
		<div className={divClassName}>
			{text.includes("<p") || text.includes("</p>") ? (
				<Wysiwyg
					textVariant={variant}
					className={clsx("text-center", className)}
					html={text}
				/>
			) : (
				<Text variant={variant} className={clsx("text-center", className)}>
					{text}
				</Text>
			)}
		</div>
	)
}

export { Introduction }

Introduction.defaultProps = {
	divClassName: "py-5 max-w-4xl mx-auto",
}
