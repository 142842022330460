import { Wysiwyg, Heading } from "@/ui"

export const config = {
	id: "mre_templates:wysiwyg",
	lazy: false,
}

const freeContent = (data) => {
	return (
		<>
			{data?.data?.components[0]?.title && (
				<Heading
					variant="mre"
					level={3}
					className="text-xl text-center md:text-3xl tracking-tight font-bold leading-tight md:leading-normal section-heading mb-6 md:mb-12"
				>
					{data?.data?.components[0]?.title}
				</Heading>
			)}

			{data.data.components[0].content && (
				<Wysiwyg html={data.data.components[0].content.value["#text"]} />
			)}
		</>
	)
}

export default freeContent
