import { Icon, Heading, Text } from "@/ui"
import { useVideo } from "@/hooks"
import clsx from "clsx"
import { useKeenSlider } from "keen-slider/react"
import Image from "next/image"
import { useState } from "react"
import { YouTubeGetID } from "@/lib"

export const config = {
	id: "mre_templates:slider_cards_video",
}

const Card = ({ title, image, description, video, setSource, setIsOpen }) => {
	return (
		<div className="block flex-1 relative isolate border-white keen-slider__slide">
			<div className="aspect-w-16 aspect-h-13 sm:aspect-h-9 md:aspect-h-13 lg:aspect-h-9 shadow-xxs">
				<Image
					src={image?.src}
					layout="fill"
					objectFit="cover"
					alt={image?.alt || "slider cards video image"}
				/>
			</div>
			<div
				className="p-5 absolute inset-0 flex flex-col items-start text-white"
				style={{
					backgroundImage:
						"linear-gradient(17.5deg,rgba(0,0,0,.5) 33%,rgba(255,255,255,.3) 100%)",
				}}
			>
				<Heading level={3} className="text-xl font-bold line-clamp-2">
					{title}
				</Heading>
				<div className="line-clamp-2">
					<Text>{description}</Text>
				</div>
				<button
					onClick={() => {
						setSource(YouTubeGetID(video))
						setIsOpen(true)
					}}
					className="order-first mb-auto text-white rounded-full overflow-hidden p-1 transition duration-300 ease-in hover:scale-110"
				>
					<span className="sr-only">play video</span>
					<Icon
						id="play-rounded"
						width="44px"
						height="44px"
						className="rtl-icon text-white fill-current"
					/>
				</button>
			</div>
		</div>
	)
}

const SliderCardVideoWidget = ({ data }) => {
	const { setIsOpen, setSource } = useVideo()
	const title = data?.extra_field?.title
	const subtitle = data?.extra_field?.subtitle
	const items = data?.components?.map((item) => ({
		title: item?.title,
		description: item?.description,
		image: {
			src: item?.image[0]?._default,
			alt: item?.image[0]?.meta?.alt,
		},
		video: item?.video?.url,
	}))

	const [currentSlide, setCurrentSlider] = useState(0)
	const [loaded, setLoaded] = useState(false)

	let keenOptions = {
		loop: true,
		mode: "snap",
		created() {
			setLoaded(true)
		},
		slides: {
			spacing: 20,
			perView: 2,
		},
		breakpoints: {
			"(max-width: 768px)": {
				slides: { perView: 1, spacing: 5 },
			},
		},
		slideChanged(slider) {
			setCurrentSlider(slider.track.details.rel)
		},
	}

	const [refCallback, slider] = useKeenSlider(keenOptions)

	const handlePaginationClick = (index) => {
		setCurrentSlider(index)
		slider.current?.moveToIdx(index)
	}

	return (
		<div className="py-12 container">
			<Heading variant="mre" level="2" className="text-center mb-12">
				{title}
			</Heading>
			<div className="mt-3 max-w-2xl mx-auto text-gray-500 mb-3 md:mb-10 text-center">
				<Text className="text-xl">{subtitle}</Text>
			</div>

			<div className="relative mx-auto overflow-x-hidden">
				<div className="flex flex-col md:flex-row md:items-stretch justify-around">
					<button
						className={clsx(
							"items-center justify-center hidden md:flex text-black hover:opacity-100 transition duration-200 ease-in-out",
							currentSlide === 0 && "opacity-50"
						)}
						onClick={(e) => e.stopPropagation() || slider.current?.prev()}
					>
						<Icon id="chevron-left" className="w-10 h-10 fill-current rtl-icon" />
					</button>

					<div
						ref={refCallback}
						className={clsx("relative overflow-x-hidden keen-slider w-full")}
					>
						{items.map((slide, index) => {
							return (
								<Card
									key={`card_${index + 10}`}
									title={slide.title}
									image={slide.image}
									description={slide.description}
									setIsOpen={setIsOpen}
									setSource={setSource}
									video={slide.video}
								/>
							)
						})}
					</div>

					<button
						className={clsx(
							"items-center justify-center hidden md:flex text-black hover:opacity-100 transition duration-200 ease-in-out",
							currentSlide === items.length - 1 && "opacity-50"
						)}
						onClick={(e) => e.stopPropagation() || slider.current?.next()}
					>
						<Icon id="chevron-right" className="w-10 h-10 fill-current rtl-icon" />
					</button>
				</div>
				{loaded && slider.current && (
					<div className="flex items-center w-full mt-8 px-0 md:px-4">
						<button
							className={clsx(
								"items-center justify-center flex md:hidden text-black hover:opacity-100 transition duration-200 ease-in-out",
								currentSlide === 0 && "opacity-50"
							)}
							onClick={(e) => e.stopPropagation() || slider.current?.prev()}
						>
							<Icon id="chevron-left" className="w-4 h-4 fill-current rtl-icon" />
						</button>
						<div className="w-full h-[5px] flex items-center mx-2 md:mx-28">
							{slider.current.track.details.slides.map((_, index) => {
								return (
									<button
										className="group h-[2px] flex items-center w-full py-auto"
										key={`pagination-${index}`}
										onClick={() => {
											handlePaginationClick(index)
										}}
									>
										<div
											className={`inline-block w-full transition delay-100 ease-in-out ${
												currentSlide === index
													? "bg-primary h-[4px]"
													: "bg-black bg-opacity-50 h-[2px]"
											}   group-hover:bg-opacity-100  group-hover:h-[4px]`}
										></div>
									</button>
								)
							})}
						</div>
						<button
							className={clsx(
								"items-center justify-center flex md:hidden text-black hover:opacity-100 transition duration-200 ease-in-out",
								currentSlide === items.length - 1 && "opacity-50"
							)}
							onClick={(e) => e.stopPropagation() || slider.current?.next()}
						>
							<Icon id="chevron-right" className="w-4 h-4 fill-current rtl-icon" />
						</button>
					</div>
				)}
			</div>
		</div>
	)
}

export default SliderCardVideoWidget
