// System routes.
export const SYSTEM_ROUTES = {
	error_page: {
		path: "/error-page",
		title: "Error Page",
	},
	account: {
		path: "/account",
		title: "Account",
	},
	account_login: {
		path: "/account/login",
		title: "Login",
	},
	account_register: {
		path: "/account/register",
		title: "Register",
	},
	account_lost_password: {
		path: "/account/lost-password",
		title: "Lost Password",
	},
	account_reset_password: {
		path: "/account/reset-password",
		title: "Reset Password",
	},
	account_forget_password: {
		path: "/account/forget-password",
		title: "Forget Password",
	},
	search: {
		path: "/search",
		title: "Search Results",
	},
}
