import { Wysiwyg, Button } from "@/ui"
import { stripHtml } from "@/lib"
import Image from "next/image"
import clsx from "clsx"

export const config = {
	id: "mre_templates:slide_lp",
}
// title,excerpt,image,urlTag,url,urlContent,className,category,
const slideLP = ({ data }) => {
	return (
		<div className="lp-slider relative h-[640px] sm:h-[670px] md:h-[700px] bg-[#fffaf3] before:content-[''] before:block before:w-[185px] before:h-[310px] sm:before:w-[220px] sm:before:h-[350px] md:before:w-[370px] md:before:h-[370px] lg:before:h-[700px] lg:before:w-[420px] xl:before:w-[450px] before:bg-primary before:absolute before:right-0 top-0 lg:before:bottom-0">
			<div className="container flex flex-col-reverse lg:flex-row h-full">
				<div className="lg:basis-1/2 lg:mt-auto my-5 lg:mb-10">
					<Wysiwyg
						as="div"
						html={data?.components?.[0].title?.value["#text"]}
						className={"lp-slider_title mb:3 md:mb-5 lg:mb-8 xl:mb-10"}
					/>
					<Wysiwyg
						as="p"
						html={stripHtml(data?.components?.[0].description?.value["#text"])}
						className="text-[16px] md:text-[20px] lg:text-[22px] font-normal leading-5 md:leading-7 mb-3 lg:mb-8 xl:mb-10"
					/>
					<Button
						href={data?.components?.[0].link.url}
						target={data?.components?.[0].link.attributes.target}
						rel={data?.components?.[0].link.attributes.rel}
						id={data?.components?.[0].link.attributes.id}
						variant="mre"
						className={clsx(
							data?.components?.[0].link.attributes.className,
							"!py-3 mb-3 lg:mb-8 xl:mb-10 !normal-case"
						)}
					>
						{data?.components?.[0].link.title}
					</Button>
					<Wysiwyg
						as="div"
						html={data?.components?.[0].info?.value["#text"]}
						className="lp-slider_avantages"
					/>
				</div>
				<div className="absolute overflow-hidden w-[230px] h-[230px] sm:w-[270px] sm:h-[270px] md:w-[290px] md:h-[290px] lg:w-[520px] lg:h-[520px] right-0 top-20 lg:top-auto lg:bottom-0">
					<Image
						src={data?.components?.[0].image?.[0]._default}
						layout="fill"
						className="object-contain right-0 bottom-0"
					/>
				</div>
			</div>
		</div>
	)
}

export default slideLP
