export const FlexBox = ({ align_items, classes, children }) => {
	switch (align_items) {
		case "flex-end":
			align_items = "items-end"
			break
		case "center":
			align_items = "items-center"
			break
		default:
			align_items = "items-start"
			break
	}

	return (
		<div className={`flex ${align_items} gap-4 ${classes !== undefined ? classes : ""}`}>
			{children}
		</div>
	)
}
