export const generateTermsObjectsFromIds = (value, terms = [], default_value = "all") => {
	if (value === null || value === undefined) {
		return default_value
	}
	let results = []
	terms.forEach((element) => {
		if (value.includes(element.id)) {
			results.push(element)
		}
	})

	if (results.length <= 0) {
		return default_value
	}

	return results
}
