import { useState, useRef, Fragment } from "react"
import { useRouter } from "next/router"
import { ContactsUtilesCard } from "./ContactsUtilesCard"
import { normalizeNodes } from "./normalizer"
import {
	Pagination,
	Container,
	Button,
	LoadingSpinner,
	EmptyBlock,
	SelectMultiple as Select,
} from "@/ui"
import { useForm, Controller } from "react-hook-form"
import { useI18n, useUpdateEffect, useNode } from "@/hooks"
import { generateTermsSlugFromIds, generateIdsFromTermsSlug } from "@/lib/utils"
import { useCollectionContext, useCollectionFetcher } from "@/hooks"
import { generateTermsObjectsFromIds } from "./utils"

export const config = {
	id: "mre_templates:contacts_utiles_list",
}

const ContactsUtilesListWidget = ({ data }) => {
	const scrollRefPagination = useRef()
	const { t } = useI18n()
	const router = useRouter()
	const locale = router.locale
	const { systemRoute, path_18n } = useNode()
	const current_page_alias = path_18n[locale]
	const defaulPagetLimit = 9
	const context = useCollectionContext(data)
	const [pager, setPager] = useState(context.pager)
	const [filters, setFilters] = useState(context.filters)

	// Extract term slug from node alias and convert it to term id.
	const defaultType = generateIdsFromTermsSlug(
		systemRoute?._query?.type?.replaceAll(" ", "+"),
		context.terms.mre_contact_utiles_type,
		"all"
	)

	const defaultPays = generateIdsFromTermsSlug(
		systemRoute?._query?.pays?.replaceAll(" ", "+"),
		context.terms.mre_contact_utiles_pays,
		"all"
	)

	const [selectedPays, setSelectedPays] = useState(defaultPays)
	const [selectedType, setSelectedType] = useState(defaultType)
	const nodeAliasPath = `${current_page_alias}/{type}/{pays}?page={page}`

	// Shallow URL is used to update history URL.
	const [shallowUrl, setShallowUrl] = useState(
		nodeAliasPath
			.replace(
				"{type}",
				selectedType !== null && selectedType !== undefined
					? generateTermsSlugFromIds(
							selectedType,
							context.terms.mre_contact_utiles_type,
							"all"
					  )
					: "all"
			)
			.replace(
				"{pays}",
				selectedPays !== null && selectedPays !== undefined
					? generateTermsSlugFromIds(
							selectedPays,
							context.terms.mre_contact_utiles_pays,
							"all"
					  )
					: "all"
			)
			.replace("{page}", pager)
	)

	// Fill filter form with default values.
	const { handleSubmit, reset, control } = useForm({
		defaultValues: {
			type: generateTermsObjectsFromIds(
				defaultType,
				context.terms.mre_contact_utiles_type,
				null
			),
			pays: generateTermsObjectsFromIds(
				defaultPays,
				context.terms.mre_contact_utiles_pays,
				null
			),
		},
	})

	// Fetch data based on params filters.
	const collection = useCollectionFetcher({
		nodeType: "contacts_utiles",
		initialPosts: context.nodes,
		initialPostsCount: context.count,
		params: filters,
	})

	// Format nodes.
	const posts = normalizeNodes(collection.posts)

	// Submit filter form.
	const submitFilterForm = () => {
		setSelectedType(selectedType)
		setSelectedPays(selectedPays)
		setPager(1)
		fetchData()
	}

	/**
	 * Reset filter form.
	 */
	const resetFilterForm = () => {
		reset({
			type: "all",
			pays: "all",
		})
		setPager(1)
		setSelectedType(["all"])
		setSelectedPays(["all"])
		fetchData(true)
	}

	const fetchData = async (reset = false, page) => {
		const currentPage = page ? page : pager

		// Update pretty path URL.
		setShallowUrl(
			nodeAliasPath
				.replace(
					"{type}",
					!reset
						? selectedType !== null && selectedType !== undefined
							? generateTermsSlugFromIds(
									selectedType,
									context.terms.mre_contact_utiles_type,
									"all"
							  )
							: "all"
						: "all"
				)
				.replace(
					"{pays}",
					!reset
						? selectedPays !== null && selectedPays !== undefined
							? generateTermsSlugFromIds(
									selectedPays,
									context.terms.mre_contact_utiles_pays,
									"all"
							  )
							: "all"
						: "all"
				)
				.replace("{page}", currentPage)
		)

		setFilters((prev) => {
			let filters = {
				...prev,
			}

			if (
				reset ||
				!selectedType ||
				selectedType === "all" ||
				selectedType[0] === "all" ||
				selectedType.length == 0
			) {
				// try to delete previously set theme filters.
				delete filters?.filter?.type
			} else {
				// Add a theme filter.
				filters.filter.type = {
					condition: {
						path: "field_vactory_taxonomy_3.drupal_internal__tid",
						operator: "IN",
						value: selectedType,
					},
				}
			}

			if (
				reset ||
				!selectedPays ||
				selectedPays === "all" ||
				selectedPays[0] === "all" ||
				selectedPays.length == 0
			) {
				// try to delete previously set theme filters.
				delete filters?.filter?.pays
			} else {
				// Add a theme filter.
				filters.filter.pays = {
					condition: {
						path: "field_vactory_taxonomy_2.drupal_internal__tid",
						operator: "IN",
						value: selectedPays,
					},
				}
			}

			// Update pager.
			filters.page = {
				...filters.page,
				offset: (currentPage - 1) * (filters?.page?.limit || defaulPagetLimit),
			}

			return filters
		})
	}

	useUpdateEffect(() => {
		setPager(1)
	}, [selectedType, selectedPays])

	useUpdateEffect(() => {
		if (context.pager !== pager) setPager(context.pager)
	}, [context.pager])

	useUpdateEffect(() => {
		if (scrollRefPagination?.current) {
			scrollRefPagination.current.scrollIntoView({
				behavior: "smooth",
			})
		}

		fetchData()
	}, [
		// selectedType,
		// selectedPays,
		pager,
	])

	// const handlePagination = (page) => {
	// 	setPager(page)
	// 	fetchData(false, page)
	// }

	// Update page url using shallow.
	useUpdateEffect(() => {
		router.push(shallowUrl, undefined, { shallow: true })
	}, [shallowUrl])

	//sort posts by number of valid keys
	const sortedPosts = posts
		.map((p) => {
			let count = 0
			Object.keys(p).forEach((k) => {
				if (p[k]) {
					count++
				}
			})
			return {
				...p,
				count,
			}
		})
		.sort((a, b) => b.count - a.count)

	return (
		<div ref={scrollRefPagination}>
			<form onSubmit={handleSubmit(submitFilterForm)}>
				<Container layout="container">
					<div className="relative md:flex grid grid-cols-1 md:flex-row items-center py-6 gap-5 w-full md:px-0 px-7">
						<div>
							<Controller
								name="type"
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										placeholder={t("Nx: tous les types")}
										options={[...(context.terms?.mre_contact_utiles_type || [])]}
										selected={selectedType}
										setSelected={setSelectedType}
										className="relative md:w-fit lg:w-72 w-full"
										id="bonnes-affaires-type"
									/>
								)}
							/>
						</div>
						<div>
							<Controller
								name="pays"
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										placeholder={t("Nx: tous les pays")}
										options={[...(context.terms?.mre_contact_utiles_pays || [])]}
										selected={selectedPays}
										setSelected={setSelectedPays}
										className="relative md:w-fit lg:w-72 w-full"
										id="bonnes-affaires-pays"
									/>
								)}
							/>
						</div>
						<div className="">
							<Button id="bonnes-affaires-submit" type="submit" variant="mre">
								{t("Nx: Appliquer")}
							</Button>
						</div>
						<div>
							<Button
								id="bonnes-affaires-reset"
								type="button"
								onClick={resetFilterForm}
								variant="secondary"
							>
								{t("Nx: Réinitialiser")}
							</Button>
						</div>
						{collection.isLoading && (
							<div className="absolute left-1/2 -translate-x-1/2 md:static -bottom-4">
								<LoadingSpinner />
							</div>
						)}
					</div>
				</Container>
			</form>

			<div className="relative pb-4 pt-10 lg:pb-10">
				<Container layout="container">
					{sortedPosts.length > 0 ? (
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8 mx-auto md:px-0 px-7">
							{sortedPosts.map((post) => (
								<Fragment key={post.id}>
									<ContactsUtilesCard {...post} />
								</Fragment>
							))}
						</div>
					) : (
						<EmptyBlock />
					)}
				</Container>
			</div>

			{parseInt(collection.count) >
				parseInt(filters?.page?.limit || defaulPagetLimit) && (
				<Container className="px-4 pb-4 sm:px-6 lg:pb-8 lg:px-8">
					<Pagination
						baseUrl={`${process.env.NEXT_PUBLIC_BASE_URL}${shallowUrl.replace(
							/page=\d+/,
							"page={page}"
						)}`}
						contentRef={scrollRefPagination}
						pageSize={filters?.page?.limit || defaulPagetLimit}
						current={pager}
						total={collection.count}
						isLoading={!collection.isLoading}
						// onChange={(page) => handlePagination(page)}
					/>
				</Container>
			)}
		</div>
	)
}

export default ContactsUtilesListWidget
