import { useFormContext } from "react-hook-form"

const checkValueIs = (watch, field, value) => {
	const fieldValue = watch(field)
	if (typeof value === "object") {
		switch (Object.keys(value)[0]) {
			case "less":
				return checkLess(fieldValue, value["less"])
			case "greater": {
				return checkGreater(fieldValue, value["greater"])
			}
			case "less_equal": {
				return checkLessEqual(fieldValue, value["less_equal"])
			}
			case "greater_equal": {
				return checkGreaterEqual(fieldValue, value["greater_equal"])
			}
			default:
				break
		}
	} else {
		return fieldValue === value
	}
}
const checkChecked = (watch, field) => {
	const falsyValues = ["", false, undefined, null]
	const fieldValue = watch(field)

	if (Array.isArray(fieldValue)) {
		return fieldValue.length != 0
	} else if (typeof fieldValue === "object") {
		return Object.keys(fieldValue).length != 0
	} else {
		return !falsyValues.includes(fieldValue)
	}
}

const checkFilled = (watch, field) => {
	const falsyValues = ["", false, undefined, null]
	const fieldValue = watch(field)
	return !falsyValues.includes(fieldValue)
}

const checkLess = (fieldValue, value) => {
	return fieldValue < value
}

const checkLessEqual = (fieldValue, value) => {
	return fieldValue <= value
}
const checkGreater = (fieldValue, value) => {
	return fieldValue > value
}

const checkGreaterEqual = (fieldValue, value) => {
	return fieldValue >= value
}

export const useCheckConditions = (states) => {
	const { watch } = useFormContext()
	var concludedStates = {}
	for (var state in states) {
		var passedCheck = 0
		states[state].checks.forEach((check) => {
			switch (check.operator) {
				case "value": {
					if (checkValueIs(watch, check.element, check.value)) {
						passedCheck++
					}
					break
				}

				case "!value": {
					if (!checkValueIs(watch, check.element, check.value)) {
						passedCheck++
					}
					break
				}
				case "checked": {
					if (checkChecked(watch, check.element)) {
						passedCheck++
					}
					break
				}
				case "unchecked": {
					if (!checkChecked(watch, check.element)) {
						passedCheck++
					}
					break
				}
				case "filled": {
					if (checkFilled(watch, check.element)) {
						passedCheck++
					}
					break
				}
				case "empty": {
					if (!checkFilled(watch, check.element)) {
						passedCheck++
					}
					break
				}
				default:
					break
			}
		})
		if (states[state].operator === "and" && passedCheck === states[state].checks.length) {
			concludedStates[state] = true
		} else if (states[state].operator === "or" && passedCheck >= 1) {
			concludedStates[state] = true
		} else if (states[state].operator === "xor" && passedCheck === 1) {
			concludedStates[state] = true
		} else {
			concludedStates[state] = false
		}
	}
	return concludedStates
}
