import Image from "next/image"
import clsx from "clsx"
import { useMenu, useI18n } from "@/hooks"
import {
	Icon,
	Link,
	Wysiwyg,
	// Button
} from "@/ui"

export const config = {
	id: "mre_templates:footer",
	lazy: false,
}

export const Footer = ({
	logo,
	use_menu,
	signateur,
	bottom_menu,
	dfComponentsCtaSocial,
	// social_medias,
	enableFooterBottom,
}) => {
	const { t } = useI18n()
	const footerMenu = useMenu(use_menu)
	const bottomMenu = useMenu(bottom_menu)

	const logoContent = {
		src: logo?._default || null,
		alt: logo?.meta?.alt,
		width: "165px",
		height: "48px",
	}

	return (
		<footer
			className="bg-black text-white relative flow-root"
			aria-labelledby="footerHeading"
		>
			<div
				className={clsx(
					"-translate-y-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 absolute ",
					"bg-white top-0 left-0 h-8 w-8 md:h-20 md:w-20 ",
					"origin-center rotate-45 transform-gpu"
				)}
			/>
			<h2 id="footerHeading" className="sr-only">
				Footer
			</h2>
			<div className="container">
				<div className="py-12 space-y-8 md:space-y-0 md:flex">
					{logoContent && logoContent.src && (
						<div className="md:w-1/4">
							<Link href="/" className="cursor-pointer">
								<Image
									className="mx-auto mb-10 tabUp:m-0"
									{...logoContent}
									alt={logoContent.alt || "footer logo"}
								/>
							</Link>
						</div>
					)}
					<div className="md:w-2/4">
						<div>
							<ul className="mt-4 space-y-4 md:mt-0 md:space-y-0 md:grid grid-rows-2 grid-cols-2 grid-flow-col">
								{footerMenu.map((item) => (
									<li key={item.id}>
										<Link
											href={item.url}
											className="text-lg font-bold text-white hover:text-primary hover:underline"
										>
											{item.title}
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="md:w-1/4">
						<p className="text-xl text-white">{t("Nx:Nous suivre :")}</p>
						<div className="text-primary mt-5 flex gap-5">
							{/* <BlocksController blocks={internal_blocks} region="inFooter" /> */}
							{dfComponentsCtaSocial.map((item, i) => {
								// Check if cta's class has a class that starts with "icon"
								return (
									item.link.title && (
										<Link
											key={`social_${i}`}
											{...item.link}
											className="bg-transparent border-0"
										>
											<Icon
												id={item.link.id}
												width="18px"
												height="18px"
												style={{ stroke: "currentColor", fill: "currentColor" }}
											/>
										</Link>
									)
								)
							})}
						</div>
					</div>
				</div>
				{enableFooterBottom && (
					<div className="flex flex-col md:flex-row gap-6 md:gap-0 pt-6 md:pb-2 pb-[60px] border-t border-gray-500 text-white text-xs">
						<ul className="flex space-x-6 md:space-x-2 rtl:space-x-reverse mx-auto md:mx-0">
							{bottomMenu.map((item) => {
								return (
									<li key={item.id}>
										<Link
											className="md:mx-2 md:px-1 hover:underline"
											href={item.url}
											target={item.options?.attributes?.target || null}
										>
											{item.title}
										</Link>
									</li>
								)
							})}
							<li>
								<Link
									className="md:mx-2 md:px-1 hover:underline ot-sdk-show-settings"
									id="ot-sdk-btn"
									href="javascript:void(0);"
								>
									{t("Nx:Paramètres des cookies")}
								</Link>
							</li>
						</ul>
						<div className="flex gap-6 mx-auto md:mx-0 md:ml-auto">
							{signateur}
							<p>
								{t("Nx:Réalisé par")}{" "}
								<Link
									className="hover:underline text-primary"
									href="https://www.void.fr"
									target="_blank"
								>
									void.fr
								</Link>
							</p>
						</div>
					</div>
				)}
			</div>
		</footer>
	)
}

const FooterVariant3Widget = ({ data }) => {
	const props = {
		logo: data?.extra_field?.logo?.[0],
		use_menu: data?.extra_field?.use_menu,
		enableFooterBottom: data?.extra_field?.enableFooterBottom,
		signateur: (
			<Wysiwyg textVariant="small" html={data?.extra_field?.signateur?.value["#text"]} />
		),
		social_medias: data?.components,
		bottom_menu: data?.extra_field?.bottom_menu,
		dfComponentsCtaSocial: data?.components.map((item) => ({
			link: {
				title: item?.cta_social?.title,
				href: item?.cta_social?.url || null,
				id: item?.icon_id || "",
				className: item?.cta_social?.attributes?.class || "",
				rel: item?.cta_social.attributes?.rel || "",
				target: item?.cta_social.attributes?.target || "_self",
			},
		})),
	}
	return <Footer {...props} />
}
export default FooterVariant3Widget
