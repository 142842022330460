import { Link, Icon, LocalVideoModal } from "@/ui"
import { useI18n } from "@/hooks"
import clsx from "clsx"
import Image from "next/image"
import { useRef } from "react"
import { YouTubeGetID } from "@/lib"

const CardImage = ({ video, image, alt = "", ...rest }) => {
	const videoModalRef = useRef()
	if (!image) {
		return <></>
	}

	return (
		<div className="relative overflow-hidden">
			<div className="aspect-w-9 aspect-h-6">
				<Image
					src={image?._default}
					alt={alt || "conseils node card image"}
					layout="fill"
					className="w-full h-full object-cover transform group-hover:scale-110 group-hover:rotate-2 transition duration-1000 ease-out"
					{...rest}
				/>
			</div>
			{video && (
				<>
					<LocalVideoModal
						ref={videoModalRef}
						sourceId={YouTubeGetID(video)}
						closeIcon={<Icon className="w-5 h-5" id="x" />}
						expenderIcon={<Icon className="w-5 h-5" id="arrows-expand" />}
						minimizerIcon={<Icon className="w-5 h-5" id="minus" />}
					/>
					<div
						className="absolute inset-0 flex justify-center items-center"
						onClick={() => {
							videoModalRef.current.open()
						}}
					>
						<button className="block text-white rounded-full overflow-hidden p-1 transition duration-300 ease-in hover:scale-110">
							<span className="sr-only">play video</span>
							<Icon
								id="play-rounded"
								width="48px"
								height="48px"
								className="rtl-icon w-12 h-12 fill-current"
							/>
						</button>
					</div>
				</>
			)}
		</div>
	)
}

export const ConseilsNodeQueueCard = ({
	title,
	className,
	image,
	video,
	theme,
	url,
	excerpt,
	index,
	length,
	...rest
}) => {
	const { t } = useI18n()
	return (
		<div
			className={clsx(
				"relative isolate group flex flex-col flex-1 max-h-full",
				index === 1
					? "bg-secondary md:transform hover:translate-x-1 hover:translate-y-1 transition ease-in-out"
					: "overflow-hidden",
				![1, 3].includes(index) && "shadow-card",
				className
			)}
			{...rest}
		>
			{(![1, 3].includes(index) || (index === 1 && length === 2)) && (
				<div className="w-full">
					{<CardImage image={image} alt={title} video={video} />}
				</div>
			)}
			<div
				className={clsx(
					[1, 3].includes(index) ? "h-full relative" : "flex-1",
					"flex flex-col items-start gap-4 p-5",
					[2, 3].includes(index) && "justify-between",
					index === 3 ? "text-white bg-secondary" : "text-black bg-white",
					index === 1 &&
						"transition ease-in-out md:transform group-hover:-translate-x-1.5 group-hover:-translate-y-1.5 shadow-card"
					// index === 0 && "min-h-full"
				)}
			>
				{index === 3 && (
					<Icon
						id="awb-symbol-outline"
						className="absolute -bottom-9 -right-1/3 w-3/4 text-white opacity-50 fill-current"
					/>
				)}
				{/* if there is image "absolute top-5 inset-x-5 flex items-start space-x-2 rtl:space-x-reverse" */}
				{theme && (
					<div
						className={clsx(
							"flex items-start space-x-2 rtl:space-x-reverse w-full",
							![1, 3].includes(index) ? "absolute top-5 inset-x-5" : ""
						)}
					>
						<span
							className={clsx(
								"px-2 py-1 uppercase leading-none text-xs font-bold bg-white",
								index === 1
									? "text-secondary border border-secondary"
									: "text-black border border-white"
							)}
						>
							{theme}
						</span>
					</div>
				)}
				<h3 className="text-lg md:text-2xl font-bold md:w-11/12">
					<Link href={url}>{title}</Link>
				</h3>
				{index !== 2 && (
					<>
						{excerpt && (
							<p className="text-base leading-tight md:leading-normal md:w-11/12">
								{excerpt}
							</p>
						)}
					</>
				)}
				<Link
					href={url || ".#"}
					variant="permalink"
					color={index === 3 ? "white" : "black"}
					className="mt-auto"
				>
					{t("Nx: Savoir plus")}
				</Link>
			</div>
		</div>
	)
}
