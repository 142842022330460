export const theme = {
	default: {
		wrapper: "bg-white hidden md:block shadow-gray",
		anchors: {
			element: {
				base: "relative text-center cursor-pointer capitalize",
				active: "text-white bg-primary after:hidden",
				link: "text-lg font-medium pt-4 pb-3 px-5 flex h-full items-center hover:text-white hover:bg-primary",
				noLast:
					"after:content-['_'] after:absolute after:h-[25px] after:w-px after:-right-px after:top-1/2 after:block after:bg-black after:-translate-y-1/2 hover:after:bg-transparent",
			},
		},
		select: {
			base: "md:hidden text-center flex items-center flex-wrap justify-center",
			element: "text-center border-0 text-white bg-primary",
			label: "text-sm text-black flex-shrink-0",
		},
	},
}
