export const tabs = {
	default: {
		desktop: {
			wrapper: "w-full px-2 md:block hidden",
			listwrapper: "flex p-1 space-x-1 bg-blue-900 rounded-xl",
			title: {
				base: "w-full py-2.5 text-sm leading-5 font-medium rounded-lg transition-all duration-300 ease-in",
				active: "bg-white shadow text-blue-500",
				inactive: "text-blue-100 hover:bg-white/[0.12] hover:text-white",
			},
			panel:
				"bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
		},
		mobile: {
			wrapper: "w-full px-2 md:hidden block",
			listwrapper: "flex p-1 space-x-1 bg-blue-900 rounded-xl",
			title: {
				base: "w-full py-2.5 text-sm leading-5 font-medium rounded-lg transition-all duration-300 ease-in",
				active: "bg-white shadow text-blue-500",
				inactive: "text-blue-100 hover:bg-white/[0.12] hover:text-white",
			},
			panel:
				"bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
		},
	},
	mre: {
		desktop: {
			wrapper: `w-full px-5 py-8 md:block hidden`,
			listwrapper: "flex bg-transparent",
			textVariant: "base",
			wysiwyg: "",
			title: {
				base: `w-full py-7 px-8 relative text-lg leading-5 font-medium focus:outline-none`,
				border: `w-full relative  top-0 bottom-0 
			after:content-[''] after:absolute after:top-1/2 after:-translate-y-1/2 after:z-[10] after:-right-1
			after:w-[1px] after:bg-black after:h-[40%]`,
				active: `
				bg-white border-2 border-b-0 border-b-transparent z-[11] border-primary text-secondary 
				after:bg-transparent before:absolute before:top-0 before:-left-0.5 before:h-full before:w-[1px] before:bg-primary before:z-[12]
				`,
				inactive: "text-black bg-transparent border-2 border-b-0 border-transparent",
			},
			panel: `bg-white border-2 border-t-1 border-t-transparent  border-primary p-8 focus:outline-none relative z-10
		 before:content-[''] before:absolute before:-top-[4px] before:left-0 before:w-full before:h-[2px] before:bg-primary`,
		},
		mobile: {
			wrapper: `w-full px-0 py-8 md:hidden block`,
			listwrapper: "divide-y-2 bg-white border-2 border-primary divide-primary",
			disclosureButton: "flex justify-between items-center px-3 w-full",
			textVariant: "base",
			panel: `mt-4 px-3  text-gray-500`,
		},
	},
	paragraph_tabs: {
		desktop: {
			wrapper: "w-full px-2",
			listwrapper: "flex p-1 space-x-1 bg-blue-900 rounded-xl",
			title: {
				base: "w-full py-2.5 text-sm leading-5 font-medium rounded-lg transition-all duration-300 ease-in",
				active: "bg-white shadow text-blue-500",
				inactive: "text-blue-100 hover:bg-white/[0.12] hover:text-white",
			},
			panel:
				"bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
		},
		mobile: {
			wrapper: "w-full px-2",
			listwrapper: "flex p-1 space-x-1 bg-blue-900 rounded-xl",
			title: {
				base: "w-full py-2.5 text-sm leading-5 font-medium rounded-lg transition-all duration-300 ease-in",
				active: "bg-white shadow text-blue-500",
				inactive: "text-blue-100 hover:bg-white/[0.12] hover:text-white",
			},
			panel:
				"bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
		},
	},
	secondary: {
		desktop: {
			wrapper: "w-full border border-purple-300 border-solid rounded-xl overflow-hidden",
			listwrapper: "flex",
			title: {
				base: "w-full py-2.5 text-sm leading-5 font-medium transition-all duration-300 ease-in",
				active: "text-purple-100 bg-purple-900 hover:bg-purple-800",
				inactive: "text-purple-900 bg-purple-100 hover:bg-purple-200",
			},
			panel:
				"bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
		},
		mobile: {
			wrapper: "w-full border border-purple-300 border-solid rounded-xl overflow-hidden",
			listwrapper: "flex",
			title: {
				base: "w-full py-2.5 text-sm leading-5 font-medium transition-all duration-300 ease-in",
				active: "text-purple-100 bg-purple-900 hover:bg-purple-800",
				inactive: "text-purple-900 bg-purple-100 hover:bg-purple-200",
			},
			panel:
				"bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
		},
	},
}
