export const inputRadio = {
	default: {
		wrapper: "flex items-center gap-x-5 mb-4",
		radioContainer: "flex gap-x-2 items-center",
		input:
			"w-5 h-5 rounded-full flex items-center justify-center p-1 transition duration-200",
		state: {
			checked: "bg-indigo-500 ring-1 ring-indigo-300 hover:ring-2 hover:ring-indigo-300",
			unChecked:
				"border border-gray-200 hover:border-gray-400 hover:ring-2 hover:ring-gray-300",
		},
		icon: "h-full w-full text-white",
		label: "text-sm font-medium text-gray-700 pb-1",
		errorMessage: "inline-block text-xs text-red-600 mt-1",
	},
	mre: {
		container: "md:flex md:justify-between md:flex-wrap mb-4",
		wrapper: "w-full md:w-7/12 flex items-center gap-x-5",
		radioContainer: "flex gap-x-2 items-center",
		input:
			"w-5 h-5 rounded-full flex items-center justify-center p-1 transition duration-200",
		state: {
			checked: "bg-secondary ring-1 ring-primary hover:ring-2 hover:ring-primary",
			unChecked: "border border-[#6B7280]",
		},
		icon: "h-full w-full text-white",
		label: "w-5/12",
		errorMessage: "text-xs text-red-600 mt-1 text-left md:ml-auto md:w-7/12",
	},
	chaabi: {
		wrapper: "flex items-center lg:gap-x-[32px] gap-x-[16px] mb-4",
		radioContainer: {
			default:
				"flex-1 flex gap-x-[16px] items-center bg-[#F9FAFB] border px-[24px] py-[24px] rounded-md",
			checked: "border border-primary-500",
		},
		input:
			"w-5 h-5 rounded-full flex items-center justify-center p-[5px] transition duration-200 bg-white",
		state: {
			checked: "text-primary-500 bg-primary-300 bg-opacity-25 border border-primary-500",
			unChecked:
				"border border-gray-200 hover:border-gray-400 hover:ring-2 hover:ring-gray-300",
		},
		icon: "h-full w-full bg-primary-500 rounded-full",
		label: "text-sm font-medium text-gray-700 mb-[6px]",
	},
}
