import { deserialise } from "kitsu-core"
import get from "lodash.get"
import {
	Button,
	//  Link,
	Wysiwyg,
	Heading,
	Text,
	Icon,
} from "@/ui"
import { normalizeNodes } from "./normalizer"
import { ConseilsNodeQueueCard } from "./ConseilsNodeQueueCard"

const NodeQueue = ({ data }) => {
	const title = get(data, "extra_field.title", "")
	const raw_description = get(data, "extra_field.description.value.#text", null)
	const description = <Wysiwyg html={raw_description} />
	const link = get(data, "extra_field.link.url", null)
	const link_label = get(data, "extra_field.link.title", "")
	let nodes = []
	data?.components.map((item) => {
		const items = deserialise(get(item, "nodes.data", {}))
		nodes = [...nodes, ...items?.data]
	})

	const nodesList = [
		...normalizeNodes(nodes.slice(0, 2), 200),
		...normalizeNodes(nodes.slice(2)),
	]

	return (
		<div className="relative overflow-hidden py-10">
			{title && (
				<Icon
					id="awb-symbol-outline"
					className="absolute -top-[300px] -right-[240px]  md:-right-[570px] text-primary fill-current w-[400px] md:w-[950px] h-[600px]"
				/>
			)}
			<div className="relative container">
				<div className="text-center">
					{title && (
						<Heading variant="mre" level="2" className="text-center mb-5">
							{title}
						</Heading>
					)}
					{raw_description.length > 0 && (
						<Text className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
							{description}
						</Text>
					)}
				</div>
				{nodesList.length > 0 && (
					<div className="mt-2 space-y-3 md:space-y-0 md:grid gap-5 grid-cols-10 conseils-areas">
						{/* limit is 4 nodesList */}
						{nodesList.slice(0, 4).map((post, i) => (
							<div key={post.id} className={`flex area-${i}`}>
								<ConseilsNodeQueueCard count={nodesList.length} {...post} index={i} />
							</div>
						))}
					</div>
				)}
				{link && (
					<div className="flex justify-center mt-12">
						<Button variant="mre" href={link}>
							{link_label}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export const config = {
	id: "mre_templates:conseils_node_queue",
}

export default NodeQueue
