import { Icon, Heading, Link, Text } from "@/ui"
import clsx from "clsx"
import { useKeenSlider } from "keen-slider/react"
import Image from "next/image"
import { useState } from "react"
import { useI18n } from "@/hooks"

export const config = {
	id: "mre_templates:slider_card_icons",
}

const SliderCardIconsWidget = ({ data }) => {
	const title = data?.extra_field?.title
	const items = data?.components
	const background = data?.extra_field?.image[0]

	const { activeLocale } = useI18n()

	const [currentSlide, setCurrentSlider] = useState(0)
	const [loaded, setLoaded] = useState(false)

	let keenOptions = {
		loop: true,
		rtl: activeLocale === "ar",
		mode: "snap",
		created() {
			setLoaded(true)
		},
		centred: true,
		slides: {
			origin: "center",
			perView: 1.5,
			spacing: 15,
		},
		breakpoints: {
			"(min-width: 600px)": {
				slides: {
					origin: "center",
					perView: 2,
					spacing: 15,
				},
			},
			"(min-width: 1024px)": {
				slides: {
					origin: "center",
					perView: 3,
					spacing: 15,
				},
			},
		},
		slideChanged(slider) {
			setCurrentSlider(slider.track.details.rel)
		},
	}

	const [refCallback, slider] = useKeenSlider(keenOptions)

	const handlePaginationClick = (index) => {
		setCurrentSlider(index)
		slider.current?.moveToIdx(index)
	}

	return (
		<div className="relative slider_card_icons">
			<div className="absolute inset-0 -z-1">
				{background && (
					<Image
						src={background?._default}
						alt={background?.meta?.alt || "slider card icons image"}
						layout="fill"
						objectFit="cover"
					/>
				)}
			</div>
			<div className="py-4 md:py-8 md:container">
				<Heading
					variant="mre"
					level="2"
					className="text-center mb-7 md:mb-12 tracking-normal"
				>
					{title}
				</Heading>
				<div className="relative mx-auto overflow-x-hidden">
					<div className="mx-auto md:w-auto flex flex-col md:flex-row md:items-stretch justify-around md:space-x-5 rtl:space-x-reverse">
						<button
							aria-label="Previous"
							className={clsx(
								"items-center justify-center hidden md:flex text-white hover:opacity-100 transition duration-200 ease-in-out",
								currentSlide === 0 && "opacity-50"
							)}
							onClick={(e) => e.stopPropagation() || slider.current?.prev()}
						>
							<Icon id="chevron-left" className="w-10 h-10 fill-current rtl-icon" />
						</button>

						<div ref={refCallback} className={clsx("keen-slider", !loaded && "hidden")}>
							{items.map((slide, index) => {
								return (
									<div key={`slide-card-${index}`} className="keen-slider__slide">
										<Link
											href={slide.link.url}
											className="group md:transition md:duration-300 md:hover:scale-95 flex items-center h-full md:h-28 py-7 md:py-0 px-4 md:px-8 shadow bg-white group-hover:bg-white"
										>
											<div className="h-16 w-16 mr-4">
												<Icon
													id={slide.icon ? slide.icon : "question"}
													width="60px"
													height="60px"
													className="flex-shrink-0 group-hover:text-secondary fill-current"
												/>
											</div>
											<Text
												as="p"
												className="mx-auto text-lg font-bold group-hover:text-secondary"
											>
												{slide.title}
											</Text>
										</Link>
									</div>
								)
							})}
						</div>
						<button
							aria-label="Next"
							className={clsx(
								"items-center justify-center hidden md:flex text-white hover:opacity-100 transition duration-200 ease-in-out",
								currentSlide === items.length - 1 && "opacity-50"
							)}
							onClick={(e) => e.stopPropagation() || slider.current?.next()}
						>
							<Icon id="chevron-right" className="w-10 h-10 fill-current rtl-icon" />
						</button>
					</div>
					<div className="flex items-center w-full mt-8 mb-1 px-4">
						<button
							aria-label="Previous"
							className={clsx(
								"items-center justify-center flex md:hidden text-white hover:opacity-100 transition duration-200 ease-in-out",
								currentSlide === 0 && "opacity-50"
							)}
							onClick={(e) => e.stopPropagation() || slider.current?.prev()}
						>
							<Icon id="chevron-left" className="w-4 h-4 fill-current rtl-icon" />
						</button>
						<div className="w-full h-[5px] flex items-center mx-2 md:mx-28">
							{slider?.current?.track?.details?.slides?.map((_, index) => {
								return (
									<button
										aria-label={
											currentSlide === index ? "Active Slide" : `Slide ${index + 1}`
										}
										className="group h-[2px] flex items-center w-full py-auto"
										key={`pagination-${index}`}
										onClick={() => {
											handlePaginationClick(index)
										}}
									>
										<div
											className={`inline-block w-full transition delay-100 ease-in-out ${
												currentSlide === index
													? "bg-primary h-[4px]"
													: "bg-white bg-opacity-50 h-[2px]"
											}   group-hover:bg-opacity-100  group-hover:h-[4px]`}
										></div>
									</button>
								)
							})}
						</div>
						<button
							aria-label="Next"
							className={clsx(
								"items-center justify-center flex md:hidden text-white hover:opacity-100 transition duration-200 ease-in-out",
								currentSlide === items.length - 1 && "opacity-50"
							)}
							onClick={(e) => e.stopPropagation() || slider.current?.next()}
						>
							<Icon id="chevron-right" className="w-4 h-4 fill-current rtl-icon" />
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SliderCardIconsWidget
