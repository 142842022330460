import chalk from "chalk"
const _log = console.log
const _info = chalk.bold.blueBright
const _time = chalk.bold.magentaBright
const _error = chalk.bold.red
const _debug = chalk.bold.blackBright
const _warning = chalk.hex("#FFA500")

// Default logger. Uses console.
export const logger = {
	log(module, message, ...rest) {
		_log(`${_time(this.getTime())} - ${_info(module)}`, message, ...rest)
	},
	debug(module, message, ...rest) {
		_log(`${_time(this.getTime())} - ${_debug(module)}`, message, ...rest)
	},
	warn(module, message, ...rest) {
		_log(`${_warning(this.getTime())}`, message, ...rest)
	},
	error(module, message, ...rest) {
		_log(`${_error(this.getTime())}`, message, ...rest)
	},
	getTime() {
		var today = new Date()
		var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
		var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
		var dateTime = date + " " + time
		return dateTime
	},
}
