import clsx from "clsx"
import Image from "next/image"

export const ThreeCardSlider = ({ image, excerpt, full_width }) => {
	return (
		<div className="relative overflow-hidden mx-auto w-full md:w-[250px] lg:w-[280px] h-full shadow-md  flex items-center lg:items-start pb-5 flex-col gap-2 border border-primary p-0 bg-white">
			<div
				className={clsx(
					"w-full h-[150px]  border-b-[2px] border-gray-100",
					full_width ? "" : "py-3 px-2"
				)}
			>
				{image && (
					<div className="flex-shrink-0 items-start w-full h-full  relative">
						<Image
							src={image.src}
							className={clsx(full_width ? "object-cover" : "object-contain")}
							layout="fill"
							alt={image?.alt || "three card slider"}
						/>
					</div>
				)}
			</div>
			<div className="flex-1 pt-2 px-5 pb-0 max-h-[225px] overflow-y-auto">
				<p className="font-normal">{excerpt}</p>
			</div>
		</div>
	)
}
