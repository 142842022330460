import { useI18n } from "@/hooks"

function Error({ statusCode }) {
	const { t } = useI18n()

	const messages = {
		404: t("Not found"),
		403: t("Access denied"),
		401: t("Unauthorized"),
		500: t("An error occurred on server"),
	}
	let message = t("An error occurred on client")

	if (statusCode && messages[statusCode]) {
		message = messages[statusCode]
	}

	return (
		<div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
			<main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
				<div className="py-16">
					<div className="text-center">
						<p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
							{statusCode}
						</p>
						<h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
							{message}
						</h1>
					</div>
				</div>
			</main>
		</div>
	)
}

export default Error
