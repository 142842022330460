import { forwardRef } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { hamburger } from "./theme"

const Hamburger = forwardRef(
	({ onClick, isOpen, className = "", variant = "default" }) => {
		return (
			<button className={clsx(hamburger[variant].wrapper, className)} onClick={onClick}>
				<span className="sr-only">Toggle Hamburger Menu</span>
				<div className={hamburger[variant].lines.wrapper}>
					<div>
						<span
							className={clsx(
								hamburger[variant].lines.base,
								"w-full",
								isOpen
									? hamburger[variant].lines.open.first
									: hamburger[variant].lines.closed.first
							)}
						></span>
						<span
							className={clsx(
								hamburger[variant].lines.base,
								"w-full top-1/2 transform -translate-y-1/2",
								isOpen
									? hamburger[variant].lines.open.second
									: hamburger[variant].lines.closed.second
							)}
						></span>
						<span
							className={clsx(
								hamburger[variant].lines.base,
								isOpen
									? hamburger[variant].lines.open.third
									: hamburger[variant].lines.closed.third
							)}
						></span>
					</div>
				</div>
			</button>
		)
	}
)

Hamburger.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	onClick: PropTypes.func,
	variant: PropTypes.string,
}

export { Hamburger }
