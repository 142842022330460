import { Link, Icon } from "@/ui"

const ContactCard = ({ url = "#.", title = "", icon = "" }) => {
	return (
		<Link href={url}>
			<div className=" w-full h-[120px] group transition duration-300 md:hover:scale-95 bg-white grid grid-cols-contact-card shadow-card gap-5 place-items-center py-4 px-6 overflow-hidden">
				<Icon
					id={icon}
					className="fill-current group-hover:text-secondary"
					width="80"
					height="80"
				/>
				<p className="text-[22px] line-clamp-2 justify-self-start leading-7 font-bold group-hover:text-secondary">
					{title}
				</p>
			</div>
		</Link>
	)
}

export { ContactCard }
