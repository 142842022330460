import { forwardRef, useImperativeHandle, createRef } from "react"
import { useI18n } from "@/hooks"
import dynamic from "next/dynamic"
import { recaptchaTheme } from "./theme"
const ReCaptcha = dynamic(() => import("react-google-recaptcha"), { ssr: false })

export const Recaptcha = forwardRef(
	({ errorMessage, description, value, onChange, ...rest }, ref) => {
		const { activeLocale: currentLanguage } = useI18n()
		const recaptchaRef = createRef()
		useImperativeHandle(ref, () => ({
			reset: () => {
				recaptchaRef?.current?.reset()
				onChange(null)
			},
		}))
		return (
			<div>
				<input type="hidden" {...rest} value={value ? value : "Google no captcha"} />
				<ReCaptcha
					sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY}
					hl={currentLanguage}
					ref={recaptchaRef}
					onChange={(val) => {
						onChange(val)
					}}
					onExpired={() => {
						onChange(null)
					}}
					onErrored={() => {
						onChange(null)
					}}
				/>
				{true && <span className={recaptchaTheme.errorMessage}>{errorMessage}</span>}
				<p className={recaptchaTheme.description}>{description}</p>
			</div>
		)
	}
)
