import { Fragment } from "react"
import get from "lodash.get"
import { Heading, Link } from "@/ui"
import Image from "next/image"

const FilialesWidget = ({ data }) => {
	const title = get(data, "extra_field.title", "")

	let nodes = data?.components.map((item) => ({
		title: item.title,
		image: {
			src: item.image[0]._default,
			alt: item.image_alt,
			height: 80,
			width: 240,
		},
		link: item.link,
	}))

	return (
		<>
			{title && (
				<div className="flex justify-center mb-5">
					<Heading variant="mre" className="text-center">
						{title}
					</Heading>
				</div>
			)}
			{nodes.length > 0 && (
				<div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-3 md:max-w-none">
					{nodes.map((node) => (
						<div
							key={node.id}
							className="flex flex-col py-5 px-4 shadow-md bg-white border border-[#F2F4F7] overflow-hidden text-black text-left"
						>
							<div className="relative h-20 w-[240px] overflow-hidden">
								<Image
									{...node.image}
									layout="fill"
									className="w-full h-full object-contain"
								/>
							</div>
							<div className="h-px w-[60%] bg-gray500 mt-2 mb-4" />
							<div className="flex text-lg font-medium gap-4 flex-col">
								{node.title && (
									<h3 className="text-[20px] leading-[28px] mb-2 font-semibold line-clamp-2">
										{node.title}
									</h3>
								)}
								{node.link.title && node.link.url && (
									<Link
										href={node.link.url}
										target={node.link.attributes?.target || null}
										variant="permalink"
										className="group-hover:text-secondary mt-auto"
									>
										{node.link.title}
									</Link>
								)}
							</div>
						</div>
					))}
				</div>
			)}
		</>
	)
}

export const config = {
	id: "mre_templates:filiale_three_col",
}

export default FilialesWidget
