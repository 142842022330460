import { Fragment } from "react"
import get from "lodash.get"
import {
	Button,
	//  Link,
	Wysiwyg,
	Heading,
	Text,
} from "@/ui"
import { NewsCard } from "./NewsCard"
import { normalizeNodes } from "./normalizer"
import { deserialise } from "kitsu-core"

export const config = {
	id: "vactory_news:three-columns",
}

const ThreeColumnsContainer = ({ data }) => {
	const response = deserialise(get(data, "components.0.collection.data", {}))
	const title = get(data, "components.0.title", "")
	const raw_description = get(data, "components.0.description.value.#text", null)
	const description = <Wysiwyg html={raw_description} />
	const link = get(data, "components.0.link.url", null)
	const link_label = get(data, "components.0.link.title", "")
	const posts = normalizeNodes(response.data)

	return (
		<div className="relative">
			<div className="relative max-w-7xl mx-auto">
				<div className="text-center">
					{title && (
						<Heading variant="3" level="2" className="text-center mb-5">
							{title}
						</Heading>
					)}
					{raw_description.length > 0 && (
						<Text className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
							{description}
						</Text>
					)}
				</div>
				<div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
					{posts.map((post) => (
						<Fragment key={post.id}>
							<NewsCard {...post} />
						</Fragment>
					))}
				</div>
				<div className="flex justify-center mt-12">
					<Button href={link}>{link_label}</Button>
				</div>
			</div>
		</div>
	)
}

export default ThreeColumnsContainer
