export const card = {
	default: {
		wrapper: "flex flex-col shadow-lg overflow-hidden text-black text-left",
		image: "relative flex items-center justify-center flex-shrink-0",
		body: "px-4 py-3",
		tag: "text-sm font-medium text-indigo-600",
		title: "text-xl font-semibold tracking-wide text-gray-800",
		excerpt: "my-3 text-base",
		link: "text-primary underline",
	},
	inline: {
		wrapper: "mx-auto max-w-4xl border-primary border relative",
		image: "md:w-1/3 ",
		body: "md:w-2/3 flex flex-col justify-center",
	},
	"2Cols": {
		wrapper: "md:flex-row ",
		image: "md:w-1/2",
		body: "md:w-1/2 flex flex-col justify-center",
	},
	"2ColsInversed": {
		wrapper: "md:flex-row-reverse ",
		image: "md:w-1/2",
		body: "md:w-1/2 flex flex-col justify-center",
	},
	cardPicto: {
		image: "w-20 h-20 pl-4 pt-4",
	},
}
