export const hamburger = {
	default: {
		wrapper: "mx-2 ml-auto",
		lines: {
			base: "h-0.5 bg-current transition absolute left-0 origin-center",
			wrapper: "h-8 w-8 aspect-w-1 aspect-h-1",
			open: {
				first: "transform rotate-45 -translate-y-1/2 top-1/2",
				second: "opacity-0",
				third: "transform -rotate-45 translate-y-1/2 bottom-1/2 w-full",
			},
			closed: {
				first: "top-1/4",
				second: "opacity-100",
				third: "bottom-1/4 w-1/2",
			},
		},
	},
}
