export const textarea = {
	default: {
		base: "relative w-full px-3 py-3 border border-gray-300 focus:ring-0 focus:border-yellow-300",
		label: "text-sm font-medium text-gray-700 mb-2",
		description: "text-sm text-gray-400 mt-1",
		counter: "absolute bottom-2 right-2 text-sm",
		resize: "resize-none",
		errorMessage: "text-xs text-red-600 mt-1",
		hasError: "border border-red-500 hover:border-red-600 focus-within:ring-red-400",
	},
	rounded: {
		base: "relative w-full px-3 py-3 rounded-md border border-gray-300 focus:ring-0 focus:border-yellow-300",
		label: "text-sm font-medium text-gray-700 mb-2",
		description: "text-sm text-gray-400 mt-1",
		counter: "absolute bottom-2 right-2 text-sm",
		resize: "resize-none",
		errorMessage: "text-xs text-red-600 mt-1",
		hasError: "border border-red-500 hover:border-red-600 focus-within:ring-red-400",
	},
	mre: {
		container: "md:flex md:justify-between md:flex-wrap",
		base: "focus:border-secondary focus:ring-0 text-sm w-full md:w-7/12",
		label: "w-5/12",
		description: "text-sm text-gray-400 mt-1 md:w-7/12 ml-auto rtl:mr-auto rtl:ml-0",
		counter: "",
		resize: "",
		errorMessage: "text-xs text-red-600 mt-1 text-left md:ml-auto md:w-7/12",
		hasError: "border-red-500",
	},
	comment: {
		container: "relative w-full py-1",
		margin: "mb-0",
		base: "focus:border-secondary border-[1.5px] border-gray-300 focus:ring-0 text-sm w-full resize-none",
		required: "font-bold text-sm ml-2",
		label: "block text-gray-400 text-sm mb-0.5",
		description: "text-sm text-gray-400 mt-1 w-7/12 ml-auto rtl:mr-auto rtl:ml-0",
		counter: "",
		resize: "",
		errorMessage: "text-xs text-red-600 mt-1 w-7/12 ml-auto rtl:mr-auto rtl:ml-0",
		hasError: "border-red-500",
	},
}
