import { Heading, Button, ContactCard } from "@/ui"
import clsx from "clsx"

export const config = {
	id: "mre_templates:contact_cards",
}

export const ContactCardComponent = ({ nodes, title, link }) => {
	return (
		<div>
			{title && (
				<Heading variant="mre" className="text-center">
					{title}
				</Heading>
			)}
			<div
				className={clsx(
					"grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-4 md:gap-8 lg:gap-20",
					title ? "my-6 md:my-10" : ""
				)}
			>
				{nodes.map((node) => (
					<ContactCard key={node.id} {...node} />
				))}
			</div>
			{link && link.url && (
				<div className="w-full   flex justify-center items-center">
					<Button variant="mre" href={link.url}>
						{link.title}
					</Button>
				</div>
			)}
		</div>
	)
}

const ContactCardsWidget = ({ data }) => {
	let props = {
		nodes: data?.components?.map((item, index) => ({
			id: "card " + index,
			icon: item?.icon || "awb-symbol",
			title: item?.link?.title,
			url: item?.link?.url,
		})),
		title: data?.extra_field?.title,
		link: data?.extra_field?.link,
	}
	return <ContactCardComponent {...props} />
}

export default ContactCardsWidget
