import Image from "next/image"
import { useRouter } from "next/router"
import clsx from "clsx"
import { useNode } from "@/hooks"
import { Breadcrumb, Wysiwyg } from "@/ui"
import get from "lodash.get"

export const config = {
	id: "vactory_banner:default",
}

const BannerVariant1Widget = ({ data }) => {
	const node = useNode()
	const settingsGlobal = {
		image: {
			src: get(data, "components.0.group_banner.image.0._default", null),
			width: get(data, "components.0.group_banner.image.0.meta.width", null),
			height: get(data, "components.0.group_banner.image.0.meta.height", null),
			alt: get(data, "components.0.group_banner.image.0.meta.alt", null),
		},
		image_mobile: {
			src: get(data, "components.0.group_banner.image_mobile.0._default", null),
			width: get(data, "components.0.group_banner.image_mobile.0.meta.width", null),
			height: get(data, "components.0.group_banner.image_mobile.0.meta.height", null),
			alt: get(data, "components.0.group_banner.image_mobile.0.meta.alt", null),
		},
		title:
			get(data, "components.0.group_banner_title.use_page_title", 0) == 1
				? node.title
				: get(data, "components.0.group_banner_title.custom_title", node.title),
	}

	// from page banner
	const settings = {
		showBreadcrumb: get(data, "components.0.group_banner_content.use_breadcrumb", false),
		content: (
			<Wysiwyg
				html={get(
					data,
					"components.0.group_banner_content.description.value['#text']",
					null
				)}
			/>
		),
		breadcrumb: node?.breadcrumb.map((item, index) => ({
			id: index,
			href: item.url,
			name: item.text,
		})),
		image: {
			src: get(
				node,
				"banner.image.thumbnail.uri.value._default",
				settingsGlobal.image.src
			),
			width: get(node, "banner.image.thumbnail.meta.width", settingsGlobal.image.width),
			height: get(node, "banner.image.thumbnail.meta.width", settingsGlobal.image.height),
			alt: get(node, "banner.image.thumbnail.meta.alt", settingsGlobal.image.alt),
		},
		image_mobile: {
			src: get(
				node,
				"banner.image_mobile.thumbnail.uri.value._default",
				settingsGlobal.image_mobile.src
			),
			width: get(
				node,
				"banner.image_mobile.thumbnail.meta.width",
				settingsGlobal.image_mobile.width
			),
			height: get(
				node,
				"banner.image_mobile.thumbnail.meta.width",
				settingsGlobal.image_mobile.height
			),
			alt: get(
				node,
				"banner.image_mobile.thumbnail.meta.alt",
				settingsGlobal.image_mobile.alt
			),
		},
		title: node?.banner?.title || settingsGlobal.title,
	}

	return <Banner {...settings} />
}
export default BannerVariant1Widget

const Banner = ({
	image,
	image_mobile = null,
	title,
	content,
	showBreadcrumb,
	breadcrumb,
	variant = "default",
}) => {
	const router = useRouter()
	const locale = router.locale

	const banner = {
		default: {
			wrapper: `relative w-full pt-[110px] md:pt-[298px] lg:pt-[322px] pb-10 md:pb-14 before:content-[''] before:bg-gray-900/50 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-[1]
				${locale.toLocaleLowerCase() === "tfng" && "md:pb-3 md:pt-[250px] lg:pb-28 lg:pt-[250px]"}
			`,
			image_mobile: "absolute top-0 left-0 w-full h-full object-cover",
			image_desktop: "absolute top-0 left-0 w-full h-full object-cover",
			title:
				locale.toLocaleLowerCase() === "tfng"
					? "md:w-1/2 text-white text-3xl md:text-5xl font-extrabold"
					: "text-white text-4xl font-extrabold mt-3 md:mt-5 mx-2.5 md:mx-0",
			content: "mx-2.5 md:mx-0",
			breadcrumb: {
				wrapper: "mt-5 mx-2.5 md:mx-0",
				variant: "default",
			},
		},
	}

	const imgMobileExist = image_mobile?.src !== null ? true : false
	return (
		<div className={banner[variant].wrapper}>
			{image.src && image.width && image.height && (
				<div
					className={clsx(
						imgMobileExist && "md:block hidden",
						!imgMobileExist && "block"
					)}
				>
					<Image
						{...image}
						alt={image?.alt || "banner desktop"}
						className={banner[variant].image_desktop}
						layout="fill"
					/>
				</div>
			)}
			{image_mobile.src && image_mobile.width && image_mobile.height && (
				<div className="block md:hidden">
					<Image
						{...image_mobile}
						alt={image_mobile?.alt || "banner mobile"}
						className={banner[variant].image_mobile}
						layout="fill"
					/>
				</div>
			)}
			<div className="container relative z-[1]">
				{showBreadcrumb && locale.toLocaleLowerCase() !== "tfng" && (
					<div className={clsx(banner?.[variant]?.breadcrumb?.wrapper, "text-white")}>
						<Breadcrumb
							// variant={banner?.[variant]?.variant || "default"}
							variant="mre"
							pages={breadcrumb}
						/>
					</div>
				)}
				{title && <h1 className={banner?.[variant]?.title}>{title}</h1>}
				{content && <div className={banner?.[variant]?.content || ""}>{content}</div>}
			</div>
		</div>
	)
}
