import { Button, Heading, Icon } from "@/ui"
import { FormLayout } from "./../FormLayout"
import { FormPreview } from "./Preview"
import { FormStepper } from "./Stepper"
import { useI18n as useTranslation } from "@/hooks"

export const FormMultiStep = ({
	pages,
	submitButton,
	currentStep,
	prevFormStep,
	nextFormStep,
	goToStep,
	schema,
}) => {
	let steps = Object.keys(pages || [])
	!pages?.webform_preview?.preview?.enable
		? steps.splice(steps.indexOf("webform_preview"), 1)
		: null
	return (
		<>
			<FormStepper pages={pages} currentStep={currentStep} goToStep={goToStep} />
			{steps.map((step, index) => {
				return (
					<FormStep
						key={index}
						nbrPages={steps.length}
						step={pages[step]}
						index={index}
						currentStep={currentStep}
						prevFormStep={prevFormStep}
						nextFormStep={nextFormStep}
						schema={schema}
					>
						{submitButton}
					</FormStep>
				)
			})}
		</>
	)
}

export const FormStep = ({
	step,
	index,
	nbrPages,
	currentStep,
	prevFormStep,
	nextFormStep,
	schema,
	render = null,
	children = null,
}) => {
	const { t } = useTranslation()
	return (
		currentStep == index && (
			<div className="px-5 py-5 border rounded-sm border-primary">
				{step?.preview?.enable ? (
					<FormPreview schema={schema} />
				) : (
					<>
						{step ? <Heading level={3}>{step["title"]}</Heading> : null}

						{render ? render() : <FormLayout data={step} />}
					</>
				)}

				<div className="flex flex-wrap gap-4 mt-8">
					{index > 0 && (
						<Button onClick={prevFormStep} variant={"secondary"}>
							<Icon id={"arrow-left"} className="w-5 h-5" />
							{step ? step["prev_button_label"] || t("Nx:Previous") : t("Nx:Previous")}
						</Button>
					)}

					{currentStep !== nbrPages - 1 && (
						<Button onClick={nextFormStep} variant={"primary"}>
							{step ? step["next_button_label"] || t("Nx:Next") : t("Nx:Next")}
							<Icon id={"arrow-right"} className="w-5 h-5" />
						</Button>
					)}

					{currentStep === nbrPages - 1 && children}
				</div>
			</div>
		)
	)
}
