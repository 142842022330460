import getConfig from "next/config"

const { publicRuntimeConfig } = getConfig()

export const stripHtml = (inputStr) => inputStr.replace(/(<([^>]+)>)/gi, "")

export const isEmpty = (value) => value == null || value.length === 0

/**
 * Formats a single URL query
 *
 * @param {string} value Right-hand side of the query
 * @param {string} key Left-hand side of the query
 * @returns {string} URL query string
 * @private
 */
function queryFormat(value, key) {
	if (value !== null && typeof value === "object") return query(value, key)
	else return encodeURIComponent(key) + "=" + encodeURIComponent(value)
}

/**
 * Constructs a URL query string for JSON:API parameters
 *
 * @param {Object} [params] Parameters to parse
 * @param {string} [prefix] Prefix for nested parameters - used internally
 * @returns {string} URL query string
 *
 * @example
 * query({
 *   filter: {
 *     slug: 'cowboy-bebop',
 *     title: {
 *       value: 'foo'
 *     }
 *   }
 *  sort: '-id'
 * })
 * // filter%5Bslug%5D=cowboy-bebop&filter%5Btitle%5D%5Bvalue%5D=foo&sort=-id
 */
export function query(params, prefix = null) {
	const str = []

	for (const param in params) {
		str.push(queryFormat(params[param], prefix ? `${prefix}[${param}]` : param))
	}
	return str.join("&")
}

export const getI18nConfig = () => {
	return publicRuntimeConfig.i18n
}

export const getEnabledLanguages = (config = {}) => {
	const { withLabels = false } = config
	if (withLabels) {
		return getI18nConfig().labels
	}
	return getI18nConfig().enabled
}

export const getDefaultLanguage = () => {
	return getI18nConfig().default
}

export const getEnabledMenus = () => {
	return publicRuntimeConfig.menus
}

export const generateTranslationForStaticRoute = (route) => {
	const languages = getEnabledLanguages()
	const translations = {}

	languages.forEach((locale) => {
		translations[locale] = `/${locale}${route}`
	})
	return translations
}

/**
 * Get multilanguage API URL
 * @param {*} language current language
 * @returns String the API URL
 */
export const getApiURL = (language) =>
	`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${language}`

/**
 * Find slug from drupal intenal ID.
 *
 * @param {*} value
 * @param {*} terms
 * @param {*} default_value
 * @returns
 */
export const generateTermsSlugFromIds = (value, terms = [], default_value = "all") => {
	const result = terms
		.filter((item) =>
			typeof value === "string" ? value == item.id : value.includes(item.id)
		)
		.map((item) => item.slug)
		.join("+")

	if (result.length <= 0) {
		return default_value
	}

	return result
}

export const generateIdsFromTermsSlug = (
	value = "",
	terms = [],
	default_value = "all"
) => {
	// const query_terms = value.split("+")
	// let result = []

	// query_terms.forEach((t) => {
	// 	const term = terms.find((item) => item.slug === t)
	// 	if (term) {
	// 		result.push(term.id)
	// 	}
	// })

	// if (result.length <= 0) {
	// 	return [default_value]
	// }

	// return result

	const result = terms
		.filter((item) => value.split("+").includes(item.slug))
		.map((item) => item.id)
	return result.length ? result : [default_value]
}

export const YouTubeGetID = (url) => {
	url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return url[2] !== undefined ? url[2].split(/[^0-9a-z_]/i)[0] : url[0]
}
