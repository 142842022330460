export const heading = {
	1: `text-[32px] leading-[48px] mb-2 lg:text-5xl lg:leading-normal font-semibold -tracking-[1px] line-clamp-2`,
	2: `text-[30px] leading-[44px] mb-2 font-semibold -tracking-[1px] line-clamp-2`,
	3: `text-[28px] leading-[45px] mb-2 font-semibold -tracking-[1px] line-clamp-2`,
	4: `text-[24px] leading-[38px] mb-2 font-semibold line-clamp-2`,
	5: `text-[20px] leading-[34px] mb-2 font-semibold line-clamp-2`,
	6: `text-[18px] leading-[32px] mb-2 font-semibold line-clamp-2`,
	mre: {
		button:
			"text-xl md:text-3xl tracking-tight font-bold leading-tight md:leading-normal text-center",
		icon: {
			left: "w-9 fill-primary my-4",
			right: "w-9 fill-primary my-4 ml-auto",
			center: "w-9 fill-primary my-4 mx-auto",
		},
	},
	"mre-agency": {
		button:
			"text-xl md:text-[32px] tracking-tight font-medium leading-tight md:leading-normal",
		icon: {
			left: "w-9 fill-primary my-4",
			right: "w-9 fill-primary my-4 ml-auto",
			center: "w-9 fill-primary my-4 mx-auto",
		},
	},
	"modal-mre": {
		button: "text-lg tracking-tight font-bold leading-tight md:leading-normal",
		icon: {
			left: "w-9 fill-primary my-4",
			right: "w-9 fill-primary my-4 ml-auto",
			center: "w-9 fill-primary my-4 mx-auto",
		},
	},
	intro: "text-2xl font-semibold text-center",
	"slider-header": "text-[15px] tracking-wide font-bold text-left",
	title: `text-[30px] leading-[36px] mb-2 font-semibold -tracking-[1px] text-indigo-500 pb-3 relative before:content-[''] before:bg-black/40 before:absolute before:bg-indigo-500 before:bottom-0 before:left-0 before:w-24 before:h-1 md:text-[40px] md:leading-[56px]`,
	"extra-title":
		"text-[20px] leading-[24px] text-center mb-8 lg:mb-12  -tracking-[1px] md:text-[30px] md:leading-[44px]",
	"title-paragraph": `text-[30px] leading-[36px] mb-8 lg:mb-12 font-semibold -tracking-[1px] text-indigo-500 pb-3 relative before:content-[''] before:bg-black/40 before:absolute before:bg-indigo-500 before:bottom-0 before:left-0 before:w-24 before:h-1 md:text-[40px] md:leading-[56px]`,
}
