import { useRef, useEffect, useState } from "react"
import { Heading } from "@/ui"
import dynamic from "next/dynamic"
const CountUp = dynamic(() => import("react-countup"), { ssr: false })

export const config = {
	id: "mre_templates:key_numbers",
	lazy: false,
}

const KeyFigures = ({ items, title }) => {
	const ref = useRef(null)
	const [myElementIsVisible, setMyElementIsVisible] = useState(false)

	// Fucntionality to run the aniamtion when reaching the element
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			const entry = entries[0]
			entry.isIntersecting && setMyElementIsVisible(entry.isIntersecting)
		})
		observer.observe(ref.current)
	}, [])

	let animatedNumber = (item) => {
		return Number.isInteger(Number(item.number)) ? (
			<CountUp start={0} end={item.number} duration={1.5} separator=""></CountUp>
		) : (
			<CountUp
				start={0}
				end={item.number}
				duration={1.5}
				decimal={item.decimal}
				decimals={1}
				separator=""
			></CountUp>
		)
	}

	return (
		<>
			<Heading variant="mre" className="text-white text-center">
				{title}
			</Heading>
			<div
				className="w-full font-din-dense grid grid-cols-1  max-w-4xl lg:max-w-5xl mx-auto gap-1 md:grid-cols-4 text-white "
				ref={ref}
			>
				{items.map((item, index) => {
					return (
						<div
							key={index}
							className={`flex h-full items-center justify-center
							relative  after:-right-px after:top-1/4 after:content-[''] after:absolute after:h-16 after:w-px  
							${
								index < items.length - 1
									? "md:after:bg-white after:bg-transparent"
									: "after:bg-transparent"
							}`}
						>
							<div className="self-start bg-transparent py-5 mb-3" key={item.id}>
								<h3 className="text-7xl lg:text-8xl tracking-tighter font-bold mb-3 text-center">
									{item.textPrefix && <span className="mr-1">{item.textPrefix}</span>}
									{myElementIsVisible ? animatedNumber(item) : <span>0</span>}
									{item.textSuffix && <span className="ml-1">{item.textSuffix}</span>}
								</h3>
								<p className="text-lg text-center">{item.description}</p>
							</div>
						</div>
					)
				})}
			</div>
		</>
	)
}

const KeyFiguresContainer = ({ data }) => {
	const props = {
		items: data?.components.map((item, index) => ({
			id: index,
			number: item.number,
			decimal: index === 2 ? "." : index === 3 ? "," : "",
			textPrefix: item.textPrefix,
			textSuffix: item.textSuffix,
			description: item.description,
		})),
		title: data?.extra_field.title,
	}

	return <KeyFigures {...props} />
}

export default KeyFiguresContainer
