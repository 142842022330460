import { useRouter } from "next/router"
import { signOut, useSession } from "next-auth/react"
import { drupal } from "@/lib"
import { SYSTEM_ROUTES } from "@/lib/routes"

export const useAccount = () => {
	const router = useRouter()
	const { data, status } = useSession()

	const isAuthenticated = status === "authenticated"
	const isLoading = status === "loading"
	const profile = data

	let loginUrl = `/${router.locale}${SYSTEM_ROUTES.account_login.path}`
	const loginUrlNoCallback = `/${router.locale}${SYSTEM_ROUTES.account_login.path}`
	if (!router.asPath.includes("callbackUrl=")) {
		loginUrl = `${loginUrl}?callbackUrl=${process.env.NEXT_PUBLIC_BASE_URL}/${router.locale}${router.asPath}`
	}

	let lostPasswordUrl = `/${router.locale}${SYSTEM_ROUTES.account_lost_password.path}`
	let registerUrl = `/${router.locale}${SYSTEM_ROUTES.account_register.path}`
	let accountUrl = `/${router.locale}${SYSTEM_ROUTES.account.path}`

	const signIn = () => {
		router.push(
			`/${router.locale}${SYSTEM_ROUTES.account_login.path}?callbackUrl=${process.env.NEXT_PUBLIC_BASE_URL}/${router.locale}${router.asPath}`
		)
	}

	const signUp = () => {
		router.push(
			`/${router.locale}${SYSTEM_ROUTES.account_register.path}?callbackUrl=${process.env.NEXT_PUBLIC_BASE_URL}/${router.locale}${router.asPath}`
		)
	}

	const getUsernameByEmail = async (email) => {
		return drupal.fetch(`api/get-unique-username-by-email?email=${email}`)
	}

	const createUser = async (body) => {
		return drupal.fetch(`${router.locale}/api/user/register`, {
			method: "POST",
			headers: {
				Accept: "application/vnd.api+json",
				"Content-Type": "application/vnd.api+json",
			},
			body: JSON.stringify(body),
		})
	}

	const updateUserSession = async () => {
		return fetch("/api/auth/session?update", {
			method: "GET",
			credentials: "include",
		}).then(() => {
			const event = new Event("visibilitychange")
			document.dispatchEvent(event)
		})
	}

	const resetUserPassword = async (body) => {
		return drupal.fetch(`${router.locale}/api/user/password/reset`, {
			method: "POST",
			headers: {
				Accept: "application/vnd.api+json",
				"Content-Type": "application/vnd.api+json",
			},
			body: JSON.stringify(body),
		})
	}

	return {
		signIn,
		signOut,
		signUp,
		loginUrl,
		lostPasswordUrl,
		accountUrl,
		loginUrlNoCallback,
		registerUrl,
		isAuthenticated,
		isLoading,
		profile,
		createUser,
		getUsernameByEmail,
		updateUserSession,
		resetUserPassword,
	}
}
