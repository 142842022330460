import { Card, Icon, LocalVideoModal, Text, Button } from "@/ui"
import Image from "next/image"
import { useRef } from "react"

export const config = {
	id: "mre_templates:list_cards",
	lazy: false,
}

const Thumbnail = ({ onClick, alt, imageSrc }) => {
	return (
		<div
			onClick={onClick}
			className="relative flex items-center justify-center flex-1 w-full aspect-w-16 aspect-h-9 rounded overflow-hidden cursor-pointer"
		>
			<Image
				alt={alt}
				src={imageSrc}
				layout="fill"
				className="absolute top-0 left-0 w-full h-full object-cover"
			/>

			<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-20">
				<Icon id="play-solid" className="w-16 h-16 text-white hover:text-gray-500"></Icon>
			</div>
		</div>
	)
}

export const Cards = ({ intro, btn_more, items = [] }) => {
	const videoModalRef = useRef()
	const link_attributes = {
		id: btn_more.id,
		target: btn_more.target,
		rel: btn_more.rel,
		className: btn_more.class,
	}
	return (
		<>
			{intro && <Text className="text-center mb-5"> {intro}</Text>}
			<div className="grid grid-cols-1 md:grid-cols-3 md:gap-3 mb-5">
				{items.map((item) => {
					return (
						<Card
							key={item.id}
							variant="default"
							title={item.title}
							excerpt={item.description}
							url={item.link_url}
							urlContent={item.link_title}
							image={
								item.video_url ? (
									<>
										<Thumbnail
											imageSrc={item.image.src}
											alt={item?.image_alt || item?.image?.alt || "card image"}
											onClick={() => {
												videoModalRef.current.open()
											}}
										/>
										<LocalVideoModal
											ref={videoModalRef}
											sourceId={item.video_url}
											closeIcon={<Icon className="h-5 w-5" id="x" />}
											expenderIcon={<Icon className="h-5 w-5" id="arrows-expand" />}
											minimizerIcon={<Icon className="h-5 w-5" id="minus" />}
										/>
									</>
								) : (
									<Image
										{...item.image}
										className="h-full object-cover w-full"
										alt={item.image_alt}
										layout="fill"
									/>
								)
							}
						/>
					)
				})}
			</div>
			{btn_more.url && btn_more.title && (
				<div className="text-center">
					<Button href={btn_more.url} {...link_attributes}>
						{btn_more.title}
					</Button>
				</div>
			)}
		</>
	)
}

const CardsContainer = ({ data }) => {
	const props = {
		intro: data?.extra_field.intro,
		btn_more: data?.extra_field.btn_more,
		slider: data?.extra_field.slider,
		items: data?.components.map((item, index) => ({
			id: index,
			image: {
				src: item.image[0]._default,
				width: item.image[0].meta.width,
				height: item.image[0].meta.height,
				alt: item.image[0].meta.alt,
			},
			image_alt: item.image_alt,
			title: item.title,
			description: item.description,
			link_url: item.link.url,
			link_title: item.link.title,
			video_url: item.video,
		})),
	}

	return <Cards {...props} />
}

export default CardsContainer
