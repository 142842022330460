import { createContext, useState } from "react"

export const VideoContext = createContext()

export const VideoProvider = ({ children }) => {
	const [source, setSource] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [minimize, setMinimize] = useState(false)
	return (
		<VideoContext.Provider
			value={{ source, setSource, isOpen, setIsOpen, minimize, setMinimize }}
		>
			{children}
		</VideoContext.Provider>
	)
}
