import { Button, Heading, Wysiwyg } from "@/ui"
import Image from "next/image"

const ImageContainer = ({ image }) => {
	return (
		<div className="relative h-[300px] md:h-[450px] w-full max-w-4xl mx-auto overflow-hidden">
			<Image
				src={image._default}
				layout="fill"
				className="w-full h-full object-contain"
			/>
		</div>
	)
}

const TwoColumnImage = ({ image, content, title, link }) => {
	return (
		<div className="w-full overflow-hidden lg:px-0 md:px-8 px-0">
			{title && (
				<Heading variant="mre" className="text-center">
					{title}
				</Heading>
			)}
			<div className="w-full gap-4 md:gap-10 mt-4 md:mt-14 flex flex-col items-center justify-center">
				{image && image._default && <ImageContainer image={image} />}
				<Wysiwyg
					html={content}
					className="grid w-full grid-cols-1 md:grid-cols-2 gap-7"
					textVariant="base"
				/>
			</div>
			{link && link.url && (
				<div className="w-full mt-10 flex justify-center items-center">
					<Button href={link.url || "#."} variant="mre">
						{link.title}
					</Button>
				</div>
			)}
		</div>
	)
}

export { TwoColumnImage }
