import clsx from "clsx"
import { Link } from "../link/Link"
import { card } from "./theme"

const Card = ({
	variant = "default",
	title,
	excerpt,
	image,
	urlTag,
	url,
	urlContent,
	className,
	category,
	...props
}) => {
	return (
		<div
			className={clsx(card.default.wrapper, card[variant].wrapper, className)}
			{...props}
		>
			<div className={clsx(card.default.image, card[variant].image)}>{image}</div>
			<div className={clsx(card.default.body, card[variant].body)}>
				{category && (
					<div>
						{urlTag ? (
							<Link href={urlTag} className={clsx(card.default.tag, card[variant].tag)}>
								{category}
							</Link>
						) : (
							<p className={clsx(card.default.tag, card[variant].tag)}>{category}</p>
						)}
					</div>
				)}
				<Link href={url} className="block">
					<>
						{title && (
							<h3 className={clsx(card.default.title, card[variant].title)}>{title}</h3>
						)}
						{excerpt && (
							<p className={clsx(card.default.excerpt, card[variant].excerpt)}>
								{excerpt}
							</p>
						)}
					</>
				</Link>
				{url && urlContent && (
					<Link href={url} className={clsx(card.default.link, card[variant].link)}>
						{urlContent}
					</Link>
				)}
			</div>
		</div>
	)
}

export { Card }
