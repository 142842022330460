import PropTypes from "prop-types"
import clsx from "clsx"
import { heading } from "./theme"
import { Icon } from "../icon/Icon"

/**
 * TODO: i guess We should merge theme object of text and heading.
 */

const Heading = ({
	children,
	className = "",
	iconClassName = "",
	icon = "center",
	level = 2,
	variant = null,
	...props
}) => {
	// const levels = [1, 2, 3, 4, 5, 6]
	const Component = level ? `h${level}` : "h2"

	return (
		<>
			<Component
				className={clsx(
					variant === null && heading[level],
					variant && heading[variant],
					heading[variant]?.button,
					className
				)}
				{...props}
			>
				{children}
				{(variant === "mre" || variant === "mre-agency" || variant === "modal-mre") && (
					<Icon
						className={clsx(heading[variant].icon[icon], iconClassName)}
						id="awb-symbol"
						width="37"
						height="10"
					/>
				)}
			</Component>
		</>
	)
}

Heading.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	//ariant: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
}

export { Heading }

/**
 * fs lh ls fw fm ......
 */
