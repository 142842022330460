export const LoadingSpinner = ({ className = "" }) => {
	return (
		<div className={"flex items-center " + className}>
			<span className="sr-only">loading content</span>
			{/* <svg
				className="animate-spin h-10 w-10"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					className="opacity-25 text-primary"
					cx={12}
					cy={12}
					r={10}
					stroke="currentColor"
					strokeWidth={4}
				/>
				<path
					className="opacity-75 text-secondary"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				/>
			</svg> */}

			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				width="24px"
				height="30px"
				viewBox="0 0 24 30"
				style={{ enableBackground: "new 0 0 50 50" }}
				xmlSpace="preserve"
			>
				<rect x="0" y="10" width="4" height="10" fill="#FF6700" opacity="0.2">
					<animate
						attributeName="opacity"
						attributeType="XML"
						values="0.2; 1; .2"
						begin="0s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="height"
						attributeType="XML"
						values="10; 20; 10"
						begin="0s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="y"
						attributeType="XML"
						values="10; 5; 10"
						begin="0s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
				</rect>
				<rect x="8" y="10" width="4" height="10" fill="#FF6700" opacity="0.2">
					<animate
						attributeName="opacity"
						attributeType="XML"
						values="0.2; 1; .2"
						begin="0.15s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="height"
						attributeType="XML"
						values="10; 20; 10"
						begin="0.15s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="y"
						attributeType="XML"
						values="10; 5; 10"
						begin="0.15s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
				</rect>
				<rect x="16" y="10" width="4" height="10" fill="#FF6700" opacity="0.2">
					<animate
						attributeName="opacity"
						attributeType="XML"
						values="0.2; 1; .2"
						begin="0.3s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="height"
						attributeType="XML"
						values="10; 20; 10"
						begin="0.3s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="y"
						attributeType="XML"
						values="10; 5; 10"
						begin="0.3s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
				</rect>
			</svg>
		</div>
	)
}
