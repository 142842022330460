export const badge = {
	wrapper: "inline-flex items-center font-medium rounded-3xl",
	size: {
		xsmall: "px-2.5 py-0.5 text-xs",
		small: "px-3 py-2 text-sm",
		normal: "px-2 pt-1.5 pb-1 text-sm",
	},
	icon: "mr-2 w-3 h-3",
	color: "bg-primary text-white",
}
