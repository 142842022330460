import { forwardRef, useState } from "react"
import clsx from "clsx"
import { theme } from "./theme"
import dynamic from "next/dynamic"

const Scrollspy = dynamic(() => import("react-scrollspy"), { ssr: false })
const AnchorLink = dynamic(() => import("react-anchor-link-smooth-scroll"), {
	ssr: false,
})

const Anchors = forwardRef(({ items, variant = "default" }, ref) => {
	const [current, setCurrent] = useState(null)

	const jumpTo = (id) => {
		const $anchor = document.getElementById(id)
		$anchor.click()
	}

	const updateHandler = (ActiveElment) => {
		const currentId = ActiveElment?.id
		setCurrent(currentId)
	}

	return (
		<nav ref={ref} className={theme[variant].wrapper}>
			<div className="container flex justify-center">
				<div className="hidden md:block">
					<Scrollspy
						onUpdate={updateHandler}
						as="div"
						items={items.map((i) => i.id)}
						offset={-300}
						className="flex"
					>
						{items.map((item, index) => (
							<li
								key={index}
								className={clsx(
									theme[variant].anchors.element.base,
									index + 1 < items.length ? theme[variant].anchors.element.noLast : "",
									current === item.id ? theme[variant].anchors.element.active : ""
								)}
							>
								<AnchorLink
									key={index}
									href={`#${item.id}`}
									id={`ancre-${item.id}`}
									className={theme[variant].anchors.element.link}
								>
									{item.title}
								</AnchorLink>
							</li>
						))}
					</Scrollspy>
				</div>

				<div className={theme[variant].select.base}>
					<label htmlFor="factory-ancre" className={theme[variant].select.label}>
						{/* Go to section: */}
						Sauter à la section:
					</label>
					<select
						onBlur={null}
						onChange={(e) => jumpTo(e.currentTarget.value)}
						id="factory-ancre"
						name="factory-ancre"
						className={theme[variant].select.element}
					>
						{items.map((item, i) => (
							<option key={i} value={`ancre-${item.id}`}>
								{item.title}
							</option>
						))}
					</select>
				</div>
			</div>
		</nav>
	)
})

export { Anchors }
