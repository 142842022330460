export const pagination = {
	default: {
		wrapper:
			"mt-10 md:mx-20 px-4 flex items-center justify-between sm:px-0 relative isolate",
		arrow: {
			base: "inline-flex items-center text-sm font-medium text-black group",
			icon: {
				base: "h-5 w-5 transform rtl:-scale-x-100 group-hover:text-yellow-800",
				width: "12",
				height: "24",
			},
		},
		pager: {
			wrapper: "w-0 flex-1 flex justify-end pr-4",
			base: "inline-flex items-center pt-0.5 self-center",
			active: "border-secondary bg-secondary text-white px-2 font-bold m-2 text-md",
			no_active:
				"border-gray-100 bg-gray-100 text-black md:hover:bg-secondary md:hover:border-secondary md:hover:text-white md:hover:border-opacity-50 px-2 m-2 font-medium text-sm",
		},
	},
	forum: {
		wrapper:
			"mt-10 md:mx-20 px-4 flex items-center justify-between sm:px-0 relative isolate",
		arrow: {
			base: "inline-flex items-center text-sm font-medium text-black group",
			icon: {
				base: "h-5 w-5 transform rtl:-scale-x-100 group-hover:text-yellow-800",
				width: "12",
				height: "24",
			},
		},
		pager: {
			wrapper: "w-0 flex-1 flex justify-end pr-4",
			base: "inline-flex items-center pt-0.5 self-center",
			active: "border-secondary bg-secondary text-white px-2 font-bold m-2 text-md",
			no_active:
				"border-gray-100 bg-gray-100 text-black hover:bg-secondary hover:border-secondary hover:text-white hover:border-opacity-50 px-2 m-2 font-medium text-sm",
		},
	},
}
