import Image from "next/image"

import { Button } from "@/ui"
import clsx from "clsx"

export const VideoThumbnail = ({ image, video, title, onClick, isOverlapped }) => {
	return (
		<div
			className={clsx(
				"relative w-full ",
				isOverlapped
					? " max-w-full md:max-w-[500px] h-[350px]"
					: "max-w-[450px] h-[250px]"
			)}
		>
			<Image
				src={image}
				alt={title || "video thumbnail"}
				layout="fill"
				className="w-full h-full object-contain"
			/>
			{video && (
				<div className="absolute inset-0 flex items-center justify-center">
					<Button variant="play-video" onClick={onClick} />
				</div>
			)}
		</div>
	)
}
