import { Text, Heading, Button } from "@/ui"

export const config = {
	id: "mre_templates:services_blocks",
	lazy: false,
}

const Services = (data) => {
	return (
		<div className="grid grid-cols-1 md:grid-cols-3 md:gap-3 text-center">
			{data.data.components.map((service, i) => {
				const link_title = service.link.title,
					link_url = service.link.url,
					link_attributes = {
						id: service.link.attributes.id,
						target: service.link.attributes.target,
						rel: service.link.attributes.rel,
						className: service.link.attributes.class + " w-full justify-center",
					}
				return (
					<div
						key={i}
						className="border-2 border-dotted p-3 mb-3 rounded-md hover:border-solid"
					>
						<Heading level="5" variant="5" className="mb-4 pb-4 border-b">
							{service.title}
						</Heading>
						{service.description && <Text className="mb-4">{service.description}</Text>}
						{link_title && link_url && (
							<div className="text-center">
								<Button href={link_url} {...link_attributes}>
									{link_title}
								</Button>
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}

export default Services
