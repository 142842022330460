import { Anchors } from "@/ui"
import { useRef, useEffect } from "react"

export const config = {
	id: "mre_templates:anchors",
}

function AnchorWidget({ data }) {
	const items = data?.components?.map((item) => ({
		id: item.id,
		title: item.sectionName,
	}))

	const anchorRef = useRef(null)

	useEffect(() => {
		if (anchorRef.current) {
			//adding the sticky to the closest parent which is the direct child of a scrollable element
			const classes = ["sticky", "top-20", "z-[49]"]
			classes.forEach((className) => {
				anchorRef.current
					.closest(".paragraph--vactory_component")
					.classList.add(className)
			})
		}
	}, [anchorRef])
	return <Anchors ref={anchorRef} items={items} />
}

export default AnchorWidget
