import { useRef } from "react"
import clsx from "clsx"
import { pagination } from "./theme"
import { Icon } from "../icon/Icon"
import { Link } from "../link/Link"

export const Pagination = (props) => {
	const ref = useRef(null)
	const {
		total,
		pageSize,
		current,
		// onChange,
		// contentRef,
		variant = "default",
		baseUrl,
	} = props
	const count = Math.ceil(total / pageSize)

	// const internalOnChange = (newIndex) => {
	// 	onChange(newIndex)
	// 	if (contentRef.current)
	// 		contentRef.current.scrollIntoView({
	// 			behavior: "smooth",
	// 		})
	// }

	const getUrl = (page) => {
		return baseUrl.replace("{page}", page)
	}

	return (
		<nav ref={ref} className={pagination[variant].wrapper}>
			<div className={pagination[variant].pager.wrapper}>
				{/* <button
					disabled={current - 1 < 1}
					onClick={() => internalOnChange(current - 1)}
					className={clsx(
						current - 1 < 1 ? "hidden" : "",
						pagination[variant].arrow.base
					)}
				>
					<Icon
						width={pagination[variant].arrow.icon.width}
						height={pagination[variant].arrow.icon.height}
						className={pagination[variant].arrow.icon.base}
						id="chevron-left"
					/>
				</button> */}
				<Link
					className={clsx(
						current - 1 < 1 ? "hidden" : "",
						pagination[variant].arrow.base
					)}
					href={getUrl(current - 1)}
				>
					<Icon
						width={pagination[variant].arrow.icon.width}
						height={pagination[variant].arrow.icon.height}
						className={pagination[variant].arrow.icon.base}
						id="chevron-left"
					/>
				</Link>
			</div>
			<div className="md:-mt-px flex">
				{count <= 6 ? (
					Array(count)
						.fill()
						.map((_, i) => (
							<Link
								key={i + 1}
								href={getUrl(i + 1)}
								className={clsx(
									i + 1 === current
										? pagination[variant].pager.active
										: pagination[variant].pager.no_active,
									pagination[variant].pager.base
								)}
							>
								{i + 1}
							</Link>
						))
				) : (
					<>
						<Link
							href={getUrl(1)}
							className={clsx(
								1 === current
									? pagination[variant].pager.active
									: pagination[variant].pager.no_active,
								pagination[variant].pager.base
							)}
						>
							1
						</Link>
						{current > 3 && (
							<Link
								href={getUrl(2)}
								className={clsx(
									pagination[variant].pager.no_active,
									pagination[variant].pager.base
								)}
							>
								{"..."}
							</Link>
						)}
						{/* {current === count && (
							<button
								href={getUrl(current - 2)}
								className={clsx(
									pagination[variant].pager.no_active,
									pagination[variant].pager.base
								)}
							>
								{current - 2}
							</button>
						)} */}
						{current > 2 && (
							<Link
								href={getUrl(current - 1)}
								className={clsx(
									pagination[variant].pager.no_active,
									pagination[variant].pager.base,
									current > 3 && current < count && "hidden md:flex"
								)}
							>
								{current - 1}
							</Link>
						)}
						{current !== 1 && current !== count && (
							<Link
								className={clsx(
									pagination[variant].pager.active,
									pagination[variant].pager.base
								)}
							>
								{current}
							</Link>
						)}
						{current < count - 1 && (
							<Link
								href={getUrl(current + 1)}
								className={clsx(
									pagination[variant].pager.no_active,
									pagination[variant].pager.base,
									current > 1 && current < count - 2 && "hidden md:flex"
								)}
							>
								{current + 1}
							</Link>
						)}
						{current === 1 && (
							<Link
								href={getUrl(current + 2)}
								className={clsx(
									pagination[variant].pager.no_active,
									pagination[variant].pager.base,
									current === 1 && "hidden md:flex"
								)}
							>
								{current + 2}
							</Link>
						)}
						{current < count - 2 && (
							<Link
								href={getUrl(count - 2)}
								className={clsx(
									pagination[variant].pager.no_active,
									pagination[variant].pager.base
								)}
							>
								{"..."}
							</Link>
						)}
						<Link
							href={getUrl(count)}
							className={clsx(
								count === current
									? pagination[variant].pager.active
									: pagination[variant].pager.no_active,
								pagination[variant].pager.base
							)}
						>
							{count}
						</Link>
					</>
				)}
			</div>
			<div className="-mt-px w-0 flex-1 flex justify-start items-center pl-4">
				{/* <button
					disabled={current + 1 > count}
					onClick={() => internalOnChange(current + 1)}
					className={clsx(
						current + 1 > count ? "hidden" : "",
						pagination[variant].arrow.base
					)}
				>
					<Icon
						width={pagination[variant].arrow.icon.width}
						height={pagination[variant].arrow.icon.height}
						className={pagination[variant].arrow.icon.base}
						id="chevron-right"
					/>
				</button> */}
				<Link
					className={clsx(
						current + 1 > count ? "hidden" : "",
						pagination[variant].arrow.base
					)}
					href={getUrl(current + 1)}
				>
					<Icon
						width={pagination[variant].arrow.icon.width}
						height={pagination[variant].arrow.icon.height}
						className={pagination[variant].arrow.icon.base}
						id="chevron-right"
					/>
				</Link>
			</div>
		</nav>
	)
}
