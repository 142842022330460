import { Icon, Heading, OneCardSlider, ThreeCardSlider } from "@/ui"
import clsx from "clsx"
import { useKeenSlider } from "keen-slider/react"
import { useState } from "react"

export const config = {
	id: "mre_templates:card_slider",
}
// title,excerpt,image,urlTag,url,urlContent,className,category,
const SliderCard = ({ data }) => {
	const slidesData = data.components.map((item) => {
		return {
			title: item.title,
			excerpt: item.content,
			image: {
				src: item.image[0]._default,
				alt: item.image_alt || "slider card image",
			},
			url: item.link.url,
			urlContent: item.link.title,
		}
	})
	let variant = data?.extra_field?.hasQuotes === 1 ? "quoted" : "default"
	let isThree = data?.extra_field?.isThree === 1
	let full_width = data?.extra_field?.full_width === 1

	const [loaded, setLoaded] = useState(false)
	const [currentSlide, setCurrentSlider] = useState(0)

	let keenOptions = {
		loop: true,
		defaultAnimation: {
			duration: 500,
			easing: (number) => number,
		},
		created() {
			setLoaded(true)
		},
		slides: {
			perView: 1,
			spacing: 10,
		},
		slideChanged(slider) {
			setCurrentSlider(slider.track.details.rel)
		},
		mode: "free-snap",
	}

	if (isThree) {
		keenOptions.slides = { perView: 3 }
		keenOptions.breakpoints = {
			"(max-width: 1024px)": {
				slides: {
					perView: 2,
					spacing: 10,
				},
			},
			"(max-width: 480px)": {
				slides: {
					origin: "center",
					perView: 1.2,
					spacing: 10,
				},
			},
		}
	}
	const [refCallback, slider] = useKeenSlider(keenOptions)

	const handlePaginationClick = (index) => {
		setCurrentSlider(index)
		slider.current?.moveToIdx(index)
	}

	return (
		<div
			className={clsx(
				"relative md:container md:px-10",
				isThree ? "pb-8 md:pb-10" : "px-4 pb-3 md:pb-6"
			)}
		>
			{data?.extra_field?.slider_title && (
				<Heading variant="mre" className="text-center">
					{data?.extra_field?.slider_title}
				</Heading>
			)}
			<div
				ref={refCallback}
				className={clsx(
					"relative flex overflow-x-hidden keen-slider mx-auto ",
					isThree ? "max-w-full md:max-w-xl lg:max-w-[950px]" : ""
				)}
			>
				{slidesData.map((slide, index) => {
					return (
						<div
							key={`slide-card-${index}`}
							className={clsx(
								`box-border relative flex-1 keen-slider__slide`,
								isThree
									? "px-2"
									: `px-0 ${
											variant === "quoted" ? "md:px-[120px]" : "md:px-24"
									  } pt-5 pb-4`
							)}
						>
							{isThree ? (
								<ThreeCardSlider full_width={full_width} {...slide} />
							) : (
								<OneCardSlider variant={variant} {...slide} />
							)}
						</div>
					)
				})}
			</div>
			<>
				<button
					className={clsx(
						`absolute z-[20] items-center justify-center hidden transform w-16 h-16 rounded-full md:flex top-[calc(50%+25px)] -translate-y-1/2`,
						isThree ? "left-0 lg:-left-2 xl:left-5 " : "left-5 ",
						variant === "default" ? "text-gray-900" : "text-gray-400"
					)}
					onClick={(e) => e.stopPropagation() || slider.current?.prev()}
				>
					<Icon
						id="chevron-left"
						className={clsx(
							"w-10 h-10 fill-current hover:text-secondary rtl-icon",
							isThree ? "w-[30px] h-[30px]" : "w-10 h-10"
						)}
					/>
				</button>

				<button
					className={clsx(
						"absolute z-[20] items-center justify-center hidden transform rounded-full md:flex top-[calc(50%+25px)]  -translate-y-1/2",
						isThree ? "right-4 lg:right-2 xl:right-9" : "right-5 ",
						variant === "default" ? "text-gray-900" : "text-gray-400"
					)}
					onClick={(e) => e.stopPropagation() || slider.current?.next()}
				>
					<Icon
						id="chevron-right"
						className={clsx(
							"w-10 h-10 fill-current hover:text-secondary rtl-icon",
							isThree ? "w-[30px] h-[30px]" : "w-10 h-10"
						)}
					/>
				</button>
			</>
			{loaded && slider.current && (
				<>
					<button
						className="absolute left-3 sm:left-7 z-20 items-center justify-center md:hidden w-5 h-5 transform -translate-y-1/2 rounded-full flex -bottom-5 "
						onClick={(e) => e.stopPropagation() || slider.current?.prev()}
					>
						<Icon id="chevron-left" className="w-10 h-10 rtl-icon" />
					</button>

					<div className="absolute -bottom-0.5 left-[10%] md:left-[25%] w-[80%] md:w-1/2 flex justify-center items-center h-[4px] z-20 ">
						{slider.current.track.details.slides.map((idx, index) => {
							return (
								<button
									className="group h-[1px] w-full bg-transparent  py-2 flex justify-center items-center"
									key={`pagination-${index}`}
									onClick={() => {
										handlePaginationClick(index)
									}}
								>
									<div
										className={`inline-block  w-full ${
											currentSlide === index
												? "bg-primary h-[3px]"
												: "bg-gray-400 h-[1px]"
										}   group-hover:bg-gray-400  group-hover:h-[3px]`}
									></div>
								</button>
							)
						})}
					</div>
					<button
						className="absolute right-3 sm:right-7 z-20 items-center justify-center md:hidden w-5 h-5 transform -translate-y-1/2 rounded-full flex -bottom-5"
						onClick={(e) => e.stopPropagation() || slider.current?.next()}
					>
						<Icon id="chevron-right" className="w-10 h-10 rtl-icon" />
					</button>
				</>
			)}
		</div>
	)
}

export default SliderCard
