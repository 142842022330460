import { Tab, Disclosure, Transition } from "@headlessui/react"
import clsx from "clsx"
import { tabs } from "./theme"
import { Wysiwyg, Icon } from "@/ui"
import { useRef } from "react"

export const Tabs = ({
	nodes = [],
	variant = "default",
	onClick,
	useWysiwyg = false,
}) => {
	return (
		<>
			<TabsDesktop {...{ nodes, variant, onClick, useWysiwyg }} breakpoint="desktop" />
			<TabsMobile {...{ nodes, variant, useWysiwyg }} breakpoint="mobile" />
		</>
	)
}

export const TabsDesktop = ({ nodes, onClick, useWysiwyg, variant, breakpoint }) => {
	return (
		<div className={tabs[variant][breakpoint].wrapper}>
			<Tab.Group>
				<Tab.List className={clsx(tabs[variant][breakpoint].listwrapper)}>
					{nodes.map((node, index) => (
						<Tab
							onClick={() => {
								typeof onClick === "function" && onClick(node.id)
							}}
							key={node.id}
							className={({ selected }) =>
								clsx(
									tabs[variant][breakpoint].title.base,
									index < nodes.length - 1 && tabs[variant][breakpoint].title.border,
									selected
										? tabs[variant][breakpoint].title.active
										: tabs[variant][breakpoint].title.inactive
								)
							}
						>
							{node.title}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels>
					{nodes.map((node) => (
						<Tab.Panel key={node.id} className={tabs[variant][breakpoint].panel}>
							{useWysiwyg ? (
								<Wysiwyg
									as={"p"}
									textVariant={tabs[variant][breakpoint].textVariant}
									className={tabs[variant][breakpoint].wysiwyg}
									html={node.content.value["#text"]}
								/>
							) : (
								<>{node.content}</>
							)}
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export const TabsMobile = ({ nodes, useWysiwyg, variant, breakpoint }) => {
	const buttonRefs = useRef([])
	const openedRef = useRef(null)
	const clickRecent = (index) => {
		const clickedButton = buttonRefs.current[index]
		if (clickedButton === openedRef.current) {
			openedRef.current = null
			return
		}
		if (openedRef.current?.getAttribute("data-value")) {
			openedRef.current?.click()
		}
		openedRef.current = clickedButton
	}
	return (
		<div className={tabs[variant][breakpoint].wrapper}>
			<div className={tabs[variant][breakpoint].listwrapper}>
				{nodes.map((item, index) => (
					<div key={item.id} className="p-3">
						<Disclosure>
							{({ open }) => (
								<>
									<Disclosure.Button as="div">
										<button
											data-value={open}
											ref={(ref) => {
												buttonRefs.current[index] = ref
											}}
											onClick={() => clickRecent(index)}
											className={clsx(
												tabs[variant][breakpoint].disclosureButton,
												open && "text-secondary"
											)}
										>
											{item.title}
											<Icon
												id={open ? "chevron-up" : "chevron-down"}
												className="text-black fill-current"
												width="12"
												height="12"
											></Icon>
										</button>
									</Disclosure.Button>
									<Transition
										show={open}
										enter="transition duration-100 ease-out"
										enterFrom="transform scale-95 opacity-0"
										enterTo="transform scale-100 opacity-100"
										leave="transition duration-75 ease-out"
										leaveFrom="transform scale-100 opacity-100"
										leaveTo="transform scale-95 opacity-0"
									>
										<Disclosure.Panel static className={tabs[variant][breakpoint].panel}>
											{useWysiwyg ? (
												<Wysiwyg as={"p"} html={item.content.value["#text"]} />
											) : (
												<>{item.content}</>
											)}
										</Disclosure.Panel>
									</Transition>
								</>
							)}
						</Disclosure>
					</div>
				))}
			</div>
		</div>
	)
}
