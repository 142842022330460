import { Card, Icon, Text, Button, Wysiwyg } from "@/ui"
import Image from "next/image"
import { useVideo } from "@/hooks"
import { YouTubeGetID } from "@/lib"

export const config = {
	id: "mre_templates:list_cards_mosaique",
	lazy: false,
}

const Thumbnail = ({ onClick, alt, imageSrc }) => {
	return (
		<div
			onClick={onClick}
			className="relative flex items-center justify-center flex-1 w-full aspect-w-16 aspect-h-9 overflow-hidden cursor-pointer"
		>
			<Image
				alt={alt}
				src={imageSrc}
				className="absolute top-0 left-0 w-full h-full object-cover"
				layout="fill"
			/>

			<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-20">
				<Icon id="play-solid" className="w-16 h-16 text-white hover:text-gray-500"></Icon>
			</div>
		</div>
	)
}

export const Cards = ({ intro, btn_more, items = [] }) => {
	const { setIsOpen, setSource } = useVideo()
	const link_attributes = {
		id: btn_more.id,
		target: btn_more.target,
		rel: btn_more.rel,
		className: btn_more.class,
	}
	return (
		<div className="flex flex-col gap-4">
			{intro && <Text className="mb-5 text-center"> {intro}</Text>}
			{items.map((item) => {
				return (
					<Card
						className="mb-5"
						key={item.id}
						variant={item.id % 2 === 0 ? "2Cols" : "2ColsInversed"}
						title={item.title}
						excerpt={item.description}
						url={item.link_url}
						urlContent={item.link_title}
						image={
							item.video_url ? (
								<>
									<Thumbnail
										imageSrc={item.image.src}
										alt={item?.image_alt || item?.image?.alt || "card image"}
										onClick={() => {
											setIsOpen(true)
											setSource(YouTubeGetID(item.video_url))
										}}
									/>
								</>
							) : (
								// <Image
								// 	{...item.image}
								// 	alt={item.image_alt}
								// 	// layout="fill"
								// 	className="w-full aspect-w-16 aspect-h-9"
								// 	// className="object-cover w-full h-full"
								// />
								<div className="relative w-full aspect-w-16 aspect-h-9 overflow-hidden ml-4 my-3">
									<Image
										{...item.image}
										alt={item.image_alt}
										className="absolute top-0 left-0 w-full h-full object-cover"
										layout="fill"
									/>
								</div>
							)
						}
					/>
				)
			})}

			{btn_more.url && btn_more.title && (
				<div className="text-center">
					<Button href={btn_more.url} variant="mre" {...link_attributes}>
						{btn_more.title}
					</Button>
				</div>
			)}
		</div>
	)
}

const CardsContainer = ({ data }) => {
	const props = {
		intro: data?.extra_field.intro,
		btn_more: data?.extra_field.btn_more,
		slider: data?.extra_field.slider,
		items: data?.components.map((item, index) => ({
			id: index,
			image: {
				src: item.image[0]._default,
				width: item.image[0].meta.width,
				height: item.image[0].meta.height,
				alt: item.image[0].meta.alt,
			},
			image_alt: item.image_alt,
			title: item.title,
			description: (
				<Wysiwyg
					html={item.description.value["#text"]}
					textVariant="base"
					className="flex flex-col gap-4"
				/>
			),
			link_url: item.link.url,
			link_title: item.link.title,
			video_url: item.video,
		})),
	}

	return <Cards {...props} />
}

export default CardsContainer
