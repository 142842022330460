import { useEffect, useRef, useState } from "react"
import router from "next/router"
import { useI18n as useTranslation } from "@/hooks"
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import merge from "lodash.merge"
import { useWebformRequest } from "../hooks"
import { defaultStyles } from "./FormStyles"
import { TextField } from "./TextField"
import { TextAreaField } from "./TextAreaField"
import { RawHtml } from "./RawHtml"
import { Markup } from "./Markup"
import { NumberField } from "./NumberField"
import { CheckboxField } from "./CheckboxField"
import { CheckboxesField } from "./CheckboxesField"
import { RadiosField } from "./RadiosField"
import { SelectField } from "./SelectField"
import { ReCaptchaField } from "./ReCaptchaField"
import { InputFileField } from "./InputFileField"
import { DateField } from "./DateField"
import { TimeField } from "./TimeField"
import cogoToast from "cogo-toast"
import { Button, Wysiwyg, Icon } from "@/ui"
import { ConfirmationMessage } from "../WebFormConfirmationWidget"
import { FormMultiStep } from "./form-multi-steps/MultiStep"
import { FormLayout } from "./FormLayout"

const LAYOUTS = [
	"webform_flexbox",
	"container",
	"fieldset",
	"details",
	"webform_wizard_page",
]

export const RenderField = (props) => {
	const fieldData = props.field
	const [name, field] = fieldData
	const { webformId, internalRefs } = useFormContext()
	const { t } = useTranslation()
	let Component = null

	const acceptNumsOnly = (e) => {
		const regex = new RegExp("^[0-9]+$")
		if (!regex.test(e.key)) {
			e.preventDefault()
		}
	}

	switch (field.type) {
		case "text":
			Component = TextField
			break

		case "textArea":
			Component = TextAreaField
			break

		case "rawhtml":
			Component = RawHtml
			break

		case "markup":
			Component = Markup
			break

		case "number":
			Component = NumberField
			break

		case "checkbox":
			Component = CheckboxField
			break

		case "checkboxes":
			Component = CheckboxesField
			break

		case "radios":
			Component = RadiosField
			break

		case "select":
			Component = SelectField
			break

		case "captcha":
			Component = ReCaptchaField
			break

		case "upload":
			Component = InputFileField
			break

		case "date":
			Component = DateField
			break

		case "time":
			Component = TimeField
			break

		case "webform_flexbox":
		case "container":
		case "fieldset":
		case "details":
			return <FormLayout data={fieldData[1]} />
		case "phoneWithCountry":
			return (
				<div className="flex flex-col md:flex-row">
					<label className="md:w-5/12 mb-0 md:mb-4">
						{t(field.elements[0].label)}
						{field.elements[0].validation.required && (
							<span
								className={"font-bold mx-1 text-red-500"}
								title="This field is required"
							>
								*
							</span>
						)}
					</label>
					<div className="w-full flex gap-2 md:w-7/12">
						<div className="w-1/3">
							<SelectField
								form={props.form}
								name="country_code"
								webformId={webformId}
								fullWidth={true}
								field={{ ...field.elements[1], label: "", fullWidth: true }}
								ref={(r) => (internalRefs.current["country_code"] = r)}
							/>
						</div>
						<div className="w-2/3">
							<TextField
								name={name}
								fullWidth={true}
								webformId={webformId}
								field={{
									...field.elements[0],
									hideLabel: true,
									label: t("Numéro de téléphone"),
									fullWidth: true,
									onKeyPress: acceptNumsOnly,
								}}
								ref={(r) => (internalRefs.current[name] = r)}
							/>
						</div>
					</div>
				</div>
			)
		case "custom":
			Component = field.component
			return (
				<Component
					name={name}
					webformId={webformId}
					field={field}
					ref={(r) => (internalRefs.current[name] = r)}
					{...field.props}
				/>
			)

		default:
			return (
				<div className="alert alert-warning">
					<p>
						Component <i>{field.type}</i> is not found. Checkout
						`packages/next-webform/src/components/Form.js` for more.
					</p>
				</div>
			)
	}

	return (
		<Component
			form={field.type === "select" && props.form}
			name={name}
			webformId={webformId}
			field={field.type === "rawhtml" ? { ...field, textVariant: "small" } : field}
			ref={(r) => (internalRefs.current[name] = r)}
		/>
	)
}

const getDefaultValues = (schema, defaultValues = {}) => {
	Object.keys(schema).forEach((fieldName) => {
		if (fieldName === "flexTotal" || fieldName === "webform_preview") return
		var field = schema[fieldName]
		if (fieldName == "pages") {
			return getDefaultValues(schema[fieldName], defaultValues)
		}
		if (LAYOUTS.includes(field["type"])) {
			if (field["childs"] == undefined) return
			return getDefaultValues(field["childs"], defaultValues)
		}
		if ("default_value" in field) {
			defaultValues[fieldName] = field.default_value
		}
	})
	return defaultValues
}

export const Form = ({
	webformId,
	schema,
	overwriteDefaultStyles,
	buttons,
	styles = {},
	render,
	handleSubmission = (response) => response,
	handleSubmitRedirection = true,
	formatSubmitData = (data) => {
		return data
	},
	hideSubmitButton = false,
	buttonClassName = "",
}) => {
	const { t } = useTranslation()
	const form = useForm({
		validateCriteriaMode: "all",
		defaultValues: getDefaultValues(schema),
		shouldFocusError: false,
	})

	useEffect(() => {
		if (form.formState.errors && form.formState.isSubmitting) {
			// Sort inputs based on their position on the page. (the order will be based on validaton order otherwise)
			const elements = Object.keys(form.formState.errors)
				.map((name) => document.getElementsByName(name)[0])
				.filter((el) => !!el)
			elements.sort(
				(a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
			)

			if (elements.length > 0) {
				let errorElement = elements[0]
				const facusElement = errorElement.getBoundingClientRect()
				const offsetTop = window.pageYOffset + facusElement.top
				const padding = 140
				window.scrollTo({
					top: offsetTop - padding,
					behavior: "smooth",
				})
				errorElement.focus({ preventScroll: true })
			}
		}
	}, [form.formState])
	const internalRefs = useRef({})
	const baseStyles = overwriteDefaultStyles ? styles : merge({}, defaultStyles, styles)
	const submitWebform = useWebformRequest()
	const [isLoading, setIsLoading] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const [isError, setIsError] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState("")
	const [inlineConfirmation, setInlineConfirmation] = useState(false)
	const [confirmationAtTopPage, setConfirmationAtTopPage] = useState(false)
	const [sid, setSid] = useState(schema?.draft?.sid || null)
	const [formStep, setFormStep] = useState(() => {
		const keys = Object.keys(schema?.pages || [])
		return keys.indexOf(schema?.draft?.currentPage) > 0
			? keys.indexOf(schema?.draft?.currentPage)
			: 0
	})
	const nextFormStep = async () => {
		const result = await form.trigger()
		if (result) {
			setFormStep((currentStep) => currentStep + 1)
		}
	}
	const prevFormStep = () => setFormStep((currentStep) => currentStep - 1)

	const goToStep = async (page) => {
		let result = true
		if (page > formStep) {
			result = await form.trigger()
		}
		if (result && (page - formStep == 1 || page - formStep < 0)) {
			setFormStep(page)
		}
	}

	const resetForm = () => {
		// Resetting each field by it's name to becaue the previous code ( form.reset() ) doesn't work sometimes
		// let fieldItemsName = {}
		// Object.keys(schema).map((el) => (fieldItemsName[el] = ""))

		// form.reset(fieldItemsName)
		// /* eslint-disable no-unused-expressions */
		// try {
		// 	Object.entries(schema).forEach(([name]) => internalRefs?.current?.[name]?.reset())
		// } catch (err) {
		// 	console.error(err)
		// }
		form.reset()
	}

	const onSubmit = async (data) => {
		let submit_data = formatSubmitData(data)
		submit_data.webform_id = webformId
		submit_data.in_draft = false
		if (sid !== null) {
			submit_data.sid = sid
		}
		setIsLoading(true)
		setIsSuccess(false)
		setIsError(false)
		// resetForm()
		submitWebform(submit_data, schema)
			.then((response) => {
				if (response?.status === 200) {
					setIsSuccess(true)
					setIsLoading(false)
					const confirmation_message =
						response?.data?.settings?.confirmation_message ||
						t("Merci d'avoir rempli notre formulaire!")
					const confirmation_url = response?.data?.settings?.confirmation_url || "/fr/"
					let toast = null
					if (handleSubmitRedirection === true) {
						switch (response?.data?.settings?.confirmation_type) {
							case "page":
								router.push(
									`/confirmation?message=${confirmation_message}&destination=${router.asPath}`
								)
								break
							case "inline":
								setInlineConfirmation(true)
								setConfirmationMessage(confirmation_message)
								break
							case "message":
								setConfirmationAtTopPage(true)
								setConfirmationMessage(confirmation_message)
								break
							case "modal":
								toast = cogoToast.success(confirmation_message, {
									hideAfter: 0,
									onClick: () => {
										toast.hide()
									},
								})
								break
							case "url":
								router.push(confirmation_url)
								break
							case "url_message":
								router.push(
									`${confirmation_url}?isSubmitted=true&message=${confirmation_message}`
								)
								break
							case "none":
								handleSubmission(response)
								break
							default:
								router.push(confirmation_url)
								break
						}
					} else {
						handleSubmission(response)
					}
					if (form.formState.isSubmitted) {
						const message = document.getElementById("confirmation-message")
						message.scrollIntoView({ behavior: "smooth", block: "center" })
						message.focus({ preventScroll: true })
					}
					resetForm()
					setFormStep(0)
				} else {
					setIsError(true)
					setIsLoading(false)
					Object.entries(response).forEach(([name, message]) => {
						form.setError(name, "server", message)
						// Internal name is used to target upload fields.
						form.setError("__" + name + "_internal", "server", message)
					})
				}
			})
			.catch((error) => {
				setIsError(true)
				setIsLoading(false)

				Object.entries(error).forEach(([name, message]) => {
					form.setError(name, { message })
				})
				// const { hide } = cogoToast.error(t(error.message), {
				// 	hideAfter: 0,
				// 	onClick: () => {
				// 		hide()
				// 	},
				// })
				// setTimeout(() => {
				// 	hide()
				// }, 5000)
			})
	}

	const handleDraftSubmit = async (data) => {
		setIsLoading(true)
		let submit_data = formatSubmitData(data)
		submit_data.webform_id = webformId
		submit_data.in_draft = true
		submit_data.current_page = Object.keys(schema?.pages)[formStep]
		if (sid !== null) {
			submit_data.sid = sid
		}
		submitWebform(submit_data, schema)
			.then((response) => {
				if (response?.status === 200) {
					const toast = cogoToast.success("draft saved successfully", {
						hideAfter: 0,
						onClick: () => {
							toast.hide()
						},
					})
					setSid(response?.data?.sid)
					setIsLoading(false)
				}
			})
			.catch((error) => {
				setIsError(true)
				setIsLoading(false)
				const { hide } = cogoToast.error(t(error.message), {
					hideAfter: 0,
					onClick: () => {
						hide()
					},
				})
				setTimeout(() => {
					hide()
				}, 5000)
			})
	}

	const reloadCurrentPage = () => {
		setInlineConfirmation(false)
	}

	return (
		<FormProvider
			webformId={webformId}
			internalRefs={internalRefs}
			{...form}
			defaultValues={getDefaultValues(schema)}
		>
			{inlineConfirmation ? (
				<>
					<div className="py-[30px]">
						<div className="relative bg-green-500 pl-5 pr-10 py-4 rounded">
							<span className="text-sm">
								<Wysiwyg html={confirmationMessage} />
							</span>
							<button className="absolute right-3 top-3">
								{/* <svg className="w-5 h-5">
								<use href="/icons.svg#x"></use>
							</svg> */}
								<Icon id="close" className="fill-current w-4 h-4" />
							</button>
						</div>
					</div>
					<Button onClick={reloadCurrentPage}>{t("Nx:Back to form")}</Button>
				</>
			) : (
				<>
					{confirmationAtTopPage && (
						<ConfirmationMessage
							message={confirmationMessage}
							close={() => setConfirmationAtTopPage(false)}
						/>
					)}
					<form onSubmit={form.handleSubmit(onSubmit)}>
						{render ? (
							render(
								resetForm,
								isLoading,
								isSuccess,
								isError,
								nextFormStep,
								prevFormStep,
								goToStep,
								formStep
							)
						) : (
							<>
								<div className="ui-form__fieldGroups">
									{Object.entries(schema).map((field) => {
										if (field[0] === "buttons" || field[0] == "draft") return false
										if (field[0] == "pages") {
											return (
												<FormMultiStep
													pages={field[1]}
													schema={schema}
													currentStep={formStep}
													prevFormStep={prevFormStep}
													nextFormStep={nextFormStep}
													goToStep={goToStep}
													submitButton={
														<Button
															type="submit"
															{...baseStyles?.submitButton}
															disabled={isLoading}
														>
															{buttons?.submit?.text || t("Nx:Submit")}
														</Button>
													}
												/>
											)
										}
										if (hideSubmitButton && field[0] === "votre_e_mail") {
											return (
												<div className="flex">
													<RenderField key={`${field[0]}-container`} field={field} />

													<Button
														className="hidden md:w-3/5 md:flex !text-[14px] !leading-4 !px-0"
														type="submit"
														variant="secondary-white"
														{...baseStyles?.submitButton}
														disabled={isLoading}
													>
														{buttons?.submit?.text || t("Nx:Submit")}
													</Button>
												</div>
											)
										}
										return (
											<RenderField
												key={`${field[0]}-container`}
												field={field}
												form={form}
											/>
										)
									})}
								</div>
								{schema?.draft?.enable && (
									<Button
										disabled={isLoading}
										onClick={form.handleSubmit(handleDraftSubmit)}
										type="button"
									>
										{t("Nx:Save Draft")}
									</Button>
								)}
								{!schema?.pages && (
									<div
										className={`ui-form__buttonGroup ${
											hideSubmitButton ? "md:hidden" : ""
										}`}
									>
										{/* {buttons?.reset?.hidden ? null : (
													<Button
														type="reset"
														onClick={resetForm}
														{...baseStyles?.resetButton}
														disabled={isLoading}
													>
														{buttons?.reset?.text || t("Nx:Reset")}
													</Button>
												)} */}

										<Button
											variant={hideSubmitButton ? "secondary-white" : "mre"}
											type="submit"
											{...baseStyles?.submitButton}
											disabled={isLoading}
											className={`${hideSubmitButton ? "w-full" : ""} ${buttonClassName}`}
										>
											{buttons?.submit?.text || t("Nx:Submit")}
										</Button>
									</div>
								)}
							</>
						)}
					</form>
				</>
			)}
		</FormProvider>
	)
}
