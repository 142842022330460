import Image from "next/image"
import { Button, Heading, Text, Wysiwyg, Icon } from "@/ui"
import { useVideo } from "@/hooks"
import { YouTubeGetID } from "@/lib"

export const config = {
	id: "mre_templates:reclamation_assistance",
}

const CardImage = ({ video, image, ...rest }) => {
	const { setIsOpen, setSource } = useVideo()

	if (!image) {
		return <></>
	}

	return (
		<div className="relative overflow-hidden">
			<div className="aspect-w-16 aspect-h-9">
				<Image
					src={image?._default}
					alt={image?.meta?.alt || "reclamation assistance image"}
					layout="fill"
					className="w-full h-full object-cover transform group-hover:scale-110 group-hover:rotate-2 transition duration-1000 ease-out"
					{...rest}
				/>
			</div>
			{video && (
				<div
					className="absolute inset-0 flex justify-center items-center"
					onClick={() => {
						setSource(YouTubeGetID(video?._default))
						setIsOpen(true)
					}}
				>
					<button className="block text-white rounded-full overflow-hidden p-1 transition duration-300 ease-in hover:scale-110">
						<span className="sr-only">play video</span>
						<Icon
							id="play-rounded"
							width="48px"
							height="48px"
							className="rtl-icon w-12 h-12 fill-current"
						/>
					</button>
				</div>
			)}
		</div>
	)
}

const ReclamationAssistanceWidget = ({ data }) => {
	const title = data?.components?.[0]?.title
	const image = data?.components?.[0]?.image?.[0]
	const background = data?.components?.[0]?.background?.[0]
	const video = data?.components?.[0]?.video?.[0]
	const phone = data?.components?.[0]?.phone
	const cardContent = data?.components?.[0]?.card_content?.value?.["#text"]
	const mainContent = data?.components?.[0]?.main_content?.value?.["#text"]
	const link = data?.components?.[0]?.link

	return (
		<div className="mx-5 lg:mx-auto lg:max-w-5xl border border-primary my-5 md:my-10 bg-white">
			<div className="py-12 mx-auto max-w-5xl px-5 md:px-12">
				<Heading variant="mre" level="2" className="text-center mb-12">
					{title}
				</Heading>
				<div className="flex flex-col gap-8 md:flex-row md:items-center">
					<div className="md:w-1/2">
						<CardImage image={image} video={video} />
					</div>
					<div className="md:w-1/2 flex flex-col">
						<Wysiwyg html={cardContent} />
						<Button variant="mre" href={link.url} className="mt-4">
							{link.title}
						</Button>
					</div>
				</div>
				<Wysiwyg html={mainContent} className="mt-10" />
			</div>
			<div className="bg-primary relative">
				<Image
					src={background?._default}
					alt={background?.meta?.alt || "reclamation assistance background image"}
					layout="fill"
				/>
				<div className="p-5 md:p-10 z-1 relative bg-black bg-opacity-20 flex flex-col md:flex-row items-center justify-center gap-4 text-white">
					<Icon id="phone-call-1" width="60px" height="54px" className="fill-current" />
					<Text as="strong" className="text-xl md:text-3xl">
						{phone}
					</Text>
				</div>
			</div>
		</div>
	)
}

export default ReclamationAssistanceWidget
