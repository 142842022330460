import { Transition } from "@headlessui/react"
import clsx from "clsx"
import { forwardRef, useImperativeHandle, useState } from "react"
import dynamic from "next/dynamic"
const YouTube = dynamic(() => import("react-youtube"), { ssr: false })

// const youtube_parser = (url) => {
// 	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
// 	var match = url.match(regExp)
// 	return match && match[7].length == 11 ? match[7] : false
// }

export const LocalVideoModal = forwardRef(({ sourceId, closeIcon }, ref) => {
	const [isOpen, setIsOpen] = useState(false)
	useImperativeHandle(ref, () => ({
		open: () => {
			setIsOpen(true)
		},
	}))

	const opts = {
		playerVars: {
			autoplay: 1,
		},
	}

	return (
		<>
			<Transition
				show={isOpen}
				enter="transition-opacity duration-75"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className={clsx(
					"fixed z-[1000] flex justify-center items-center transition",
					"inset-0 p-5 lg:px-40"
				)}
			>
				<Transition.Child
					show={isOpen}
					enter="transition-opacity ease-linear duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					className="absolute inset-0 transition-opacity bg-black bg-opacity-50"
					onClick={() => {
						setIsOpen(false)
					}}
				></Transition.Child>
				<Transition.Child
					show={isOpen}
					enter="transition-opacity ease-linear duration-600"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					className="relative flex-1 transition bg-black rounded-lg shadow-xl"
				>
					<div className="group">
						<div
							className={clsx(
								"absolute z-50 md:bottom-full right-0 mb-1",
								"lg:opacity-0 md:group-hover:opacity-100 space-x-1"
							)}
						>
							<button
								onClick={(e) => {
									e.stopPropagation()
									setIsOpen(false)
								}}
								type="button"
								className="p-2 text-white bg-black bg-opacity-50 rounded-md hover:text-indigo-300"
							>
								<span className="sr-only">Close</span>
								{closeIcon}
							</button>
						</div>
						<div
							className={clsx(
								"overflow-hidden",
								"aspect-w-16 aspect-h-9 isolate rounded-lg "
							)}
						>
							<YouTube
								id={sourceId}
								opts={opts}
								videoId={sourceId}
								iframeClassName="w-full h-full"
								className="absolute top-0 bottom-0 left-0 w-full h-full my-auto"
							/>
						</div>
					</div>
				</Transition.Child>
			</Transition>
		</>
	)
})
