import { useNode } from "@/hooks"
import { Heading, Button, Wysiwyg, Text } from "@/ui"
import { ConseilsCard } from "./ConseilsCard"
import { normalizeVCC } from "./normalizer"

export const config = {
	id: "mre_templates:cross-content-conseils",
}

const CrossContentConseils = ({ data }) => {
	const { vcc } = useNode()
	console.log("vcc", vcc)

	const title = vcc?.title || null
	const linkUrl = vcc?.more_link || null
	const linkTitle = vcc?.more_link_label || null

	const props = {
		title: data?.components?.[0]?.title || title,
		intro: data?.components?.[0]?.intro?.value?.["#text"] ? (
			<Wysiwyg html={data?.components?.[0]?.intro?.value?.["#text"]} />
		) : null,
		link: {
			href: data?.components?.[0]?.link?.url || linkUrl,
			id: data?.components?.[0]?.link?.attributes?.id,
			rel: data?.components?.[0]?.link?.attributes?.rel,
			target: data?.components?.[0]?.link?.attributes?.target || "_self",
			className: data?.components?.[0]?.link?.attributes?.class,
		},
		linkTitle: data?.components?.[0]?.link?.title || linkTitle,
		nodes: normalizeVCC(vcc?.nodes),
	}

	return <CrossContent {...props} />
}

export const CrossContent = ({ title, intro, link, linkTitle, nodes }) => {
	return (
		<div className="bg-[#F8F8F8] px-4 py-10 md:py-20">
			<div className="container">
				{title && (
					<Heading level="2" className="mb-8 text-center">
						{title}
					</Heading>
				)}

				{intro && (
					<Text variant="intro" as="div">
						{intro}
					</Text>
				)}
				<div className="flex flex-wrap flex-col md:flex-row justify-center gap-4">
					{nodes.map((item) => {
						return (
							<ConseilsCard
								key={item.id}
								className="md:w-1/3 md:max-w-[33.33%] flex-1"
								{...item}
							/>
						)
					})}
				</div>
				{link.href && linkTitle && (
					<div className="text-center mt-10">
						<Button {...link}>{linkTitle}</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export default CrossContentConseils
