import Image from "next/image"
import { Icon, Heading, Wysiwyg } from "@/ui"
import { theme } from "./theme"
import clsx from "clsx"

export const OneCardSlider = ({ image, excerpt, title, variant }) => {
	return (
		<div
			className={clsx(theme[variant].wrapper, "after:w-full before:h-full")}
			style={{
				clipPath: `polygon(100% 0, 100% calc(100% - 45px), calc(100% - 45px) 100%, 0 100%, 0 0)`,
			}}
		>
			<Icon
				id="awb-symbol-outline"
				className="absolute z-10 -bottom-14 w-[350px] left-0 fill-current text-primary"
			/>
			<Icon
				id="awb-symbol-outline"
				className="absolute z-10 -top-12 w-[350px] -right-[258px] fill-current text-primary"
			/>

			{image && (
				<div className={clsx(theme[variant].image)}>
					<Image
						src={image.src}
						className="object-contain w-full h-full"
						layout="fill"
						alt={image?.alt || "one card slider"}
					/>
				</div>
			)}
			<div className={theme[variant].card.wrapper}>
				{variant === "quoted" ? (
					<div className="gap-2 mb-2 flex flex-col md:items-start items-center">
						<span className="md:inline-block text-base hidden font-normal">{title}</span>
						<svg
							className=" text-primary"
							width="20"
							height="20"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 19 12"
						>
							<path
								fill="currentColor"
								fillRule="nonzero"
								d="M4.536.468c2.492 0 4.512 1.955 4.512 4.365 0 2.411-2.02 4.366-4.512 4.366-.211 0-.418-.02-.622-.048.139 1.409.97 2.62 2.16 3.317-3.493-.976-6.372-4.045-6.05-7.71.212-2.403 2.02-4.29 4.512-4.29zm9.953 0C16.98.468 19 2.423 19 4.833 19 7.244 16.98 9.2 14.489 9.2c-.212 0-.418-.02-.622-.048.139 1.409.97 2.62 2.16 3.317-3.494-.976-6.372-4.045-6.05-7.71.211-2.403 2.02-4.29 4.512-4.29z"
							/>
						</svg>
					</div>
				) : (
					<Heading variant={"slider-header"} className="font-medium mb-0.5">
						{title}
					</Heading>
				)}

				<Wysiwyg html={excerpt} className={clsx(theme[variant].card.wysiwyg)} />
				{variant === "quoted" && (
					<span className="inline-block md:hidden  mt-2 text-xs font-normal">
						{title}
					</span>
				)}
			</div>
			<div className={theme[variant].card.curve.border}></div>
		</div>
	)
}
