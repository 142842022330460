import clsx from "clsx"
import { Icon } from "@/ui"

export const Details = ({ title, title_display, classes, children, open = false }) => {
	return (
		<details
			className={clsx("group relative shadow bg-white rounded-lg mb-5", classes)}
			open={open}
		>
			{(title_display !== "invisible" ||
				title_display === "" ||
				title_display === undefined) && (
				<summary className="flex items-center px-3 py-4 font-bold cursor-pointer select-none">
					{title}
					<Icon
						id="plus"
						className="w-4 h-4 ml-auto transition-transform rotate-0 group-open:rotate-45"
					/>
				</summary>
			)}
			<div className="p-4 bg-gray-50">{children}</div>
		</details>
	)
}
