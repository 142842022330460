import { Fragment } from "react"
import get from "lodash.get"
import {
	Button,
	//  Link,
	Wysiwyg,
	Heading,
	Text,
	Slider,
	SliderSlides,
} from "@/ui"
import { NewsCard } from "./NewsCard"
import { normalizeNodes } from "./normalizer"
import { deserialise } from "kitsu-core"

export const config = {
	id: "vactory_news:block-slider",
}

const NewsBlockSliderWidget = ({ data }) => {
	const response = deserialise(get(data, "components.0.collection.data", {}))
	const title = get(data, "components.0.title", "")
	const raw_description = get(data, "components.0.description.value.#text", null)
	const description = <Wysiwyg html={raw_description} />
	const link = get(data, "components.0.link.url", null)
	const link_label = get(data, "components.0.link.title", "")
	const posts = normalizeNodes(response.data)

	const sliderSettings = {
		arrows: {
			hideArrowMobile: true,
			hideArrowTablet: false,
			hideArrowDesktop: false,
		},
		rtl: false,
		loop: true,
		disabled: false,
		centred: false,
		initial: 0,
		slides: {
			origin: "auto",
			number: null,
			perView: 1,
			spacing: 16,
		},
		mode: "snap",
		rubberband: true,
		renderMode: "precision",
		defaultAnimation: { duration: 500 },
		vertical: false,
		opacity: false,
		breakpoints: {
			"(min-width: 992px)": {
				slides: {
					origin: "auto",
					number: null,
					perView: 3,
					spacing: 20,
				},
			},
		},
	}

	return (
		<div className="relative">
			<div className="relative max-w-7xl mx-auto">
				<div className="text-center mb-5">
					{title && (
						<Heading variant="3" level="2" className="text-center mb-5">
							{title}
						</Heading>
					)}
					{raw_description.length > 0 && (
						<Text className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
							{description}
						</Text>
					)}
				</div>

				<Slider
					sliderSettings={sliderSettings}
					variant="default"
					className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
				>
					{posts.map((post, index) => {
						return (
							<SliderSlides key={index}>
								<Fragment key={post.id}>
									<NewsCard {...post} />
								</Fragment>
							</SliderSlides>
						)
					})}
				</Slider>

				<div className="flex justify-center mt-12">
					<Button href={link}>{link_label}</Button>
				</div>
			</div>
		</div>
	)
}

export default NewsBlockSliderWidget
