import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { useI18n as useTranslation } from "@/hooks"
import { toRegister } from "../utils/toRegister"
import { Checkboxes } from "@/ui"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const CheckboxesField = ({ name, field }) => {
	const { label, validation, shouldDisplay, states, title_display, variant, helperText } =
		field
	const { t } = useTranslation()
	const { control, watch, defaultValues } = useFormContext()
	const values = watch({ nest: true })
	const errorMessage = useErrorMessage(name, label)
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	const local_state = useCheckConditions(states)

	if (!isVisible) return null

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	return (
		<>
			<Controller
				control={control}
				name={name}
				rules={toRegister(t("Nx:" + label), local_validation, values, t)}
				render={({ field: { onChange, value } }) => (
					<Checkboxes
						// label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
						variant={variant || "mre"}
						label={label && title_display != "invisible" && t(`Nx:${label}`)}
						checkboxesData={field.options.map((radio) => {
							return {
								value: radio.value,
								label: radio.label || radio.name,
								checked: defaultValues[name]?.includes(radio.value),
							}
						})}
						//	{...register(name, toRegister(t("Nx:" + label), local_validation, values, t))}
						checked={value !== undefined ? value : []}
						setChecked={(e) => {
							if (value === undefined) {
								onChange([e.target.value])
							} else {
								if (!value.includes(e.target.value)) {
									onChange([...value, e.target.value])
								} else {
									onChange([
										...value.filter((element) => {
											return element !== e.target.value
										}),
									])
								}
							}
						}}
						hasError={!!errorMessage}
						errorMessage={!!errorMessage && errorMessage}
						disabled={local_state?.disabled}
						description={helperText}
					/>
				)}
			/>
		</>
	)
}
