import { useNode } from "@/hooks"
import { Icon, Container } from "@/ui"
import { Widgets } from "@/runtime/widgets"
// @todo use this.
// import DF_DEV_MAPPING from "../../../../packages/next/.tmp/widgets.dev.json"

const layoutsMapping = {
	narrow_width: "default",
	full_width: "fluid",
	no_container: "full",
}

export const BlocksController = ({ region = "" }) => {
	const { blocks } = useNode()

	const regionBlocks = blocks.filter((block) => block.region === region)

	if (regionBlocks.length <= 0) {
		return null
	}

	return (
		<>
			{regionBlocks &&
				regionBlocks.map((block) => (
					<BlocksTemplate
						key={block.id}
						layout={block?.container}
						spacing={block?.container_spacing}
						widget={block.content}
						hasAMP={false}
						blockClasses={block.classes}
					/>
				))}
		</>
	)
}

// @todo: move this.
const Alert = ({ children }) => (
	<div className="max-w-4xl mx-auto bg-yellow-50 ltr:border-l-4 rtl:border-r-4 border-yellow-400 p-4 my-2.5">
		<div className="flex">
			<div className="flex-shrink-0">
				<Icon id="exclamation-circle" width="24" height="24" />
			</div>
			<div className="ml-3">
				<p className="text-sm text-yellow-700">{children}</p>
			</div>
		</div>
	</div>
)

const BlocksTemplate = ({ widget, spacing, layout, blockClasses, ...rest }) => {
	const { widget_id, widget_data } = widget
	let Component = Widgets[widget_id]

	if (!Component) {
		return (
			<Alert role="alert">
				Caught an error. Block Template {widget_id} is not mapped!
			</Alert>
		)
	}

	return (
		<Container layout={layoutsMapping[layout]} spacing={spacing} className={blockClasses}>
			<Component data={JSON.parse(widget_data)} {...rest} />
		</Container>
	)
}
