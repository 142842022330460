import { Link, Icon } from "@/ui"
import { useI18n } from "@/hooks"
import clsx from "clsx"

export const ContactsUtilesCard = ({
	title,
	email,
	adresse,
	website,
	className,
	phone,
	...rest
}) => {
	const { t } = useI18n()

	return (
		<div
			className={clsx(
				"flex flex-col py-5 px-4 shadow-md bg-white border border-[#F2F4F7] overflow-hidden text-black text-left",
				className
			)}
			{...rest}
		>
			{title && (
				<h3 className="text-xl md:text-[24px] line-clamp-2 leading-[30px] tracking-[1px] font-semibold">
					{title}
				</h3>
			)}
			<div className="h-px w-[60%] bg-gray500 mt-2 mb-4" />
			<div className="flex text-lg font-medium gap-4 flex-col">
				{phone && (
					<div className="grid grid-cols-icon-paragraph items-center gap-3">
						<Icon
							id="handset"
							className="text-secondary fill-current"
							width={20}
							height={20}
						/>
						<a href={`tel:${phone}`}>{phone}</a>
					</div>
				)}
				{adresse && (
					<div className="grid grid-cols-icon-paragraph items-center gap-3 word-break-all">
						<Icon
							id="location"
							className="text-secondary fill-current"
							width={20}
							height={20}
						/>
						<p>{adresse}</p>
					</div>
				)}
				{email && (
					<div className="grid grid-cols-icon-paragraph items-center gap-3 mb-3">
						<Icon
							id="mail"
							className="text-secondary fill-current"
							width={20}
							height={20}
						/>
						<a href={`mailto:${email}`}>{email}</a>
					</div>
				)}
			</div>
			{website && (
				<div className="flex items-center mt-auto">
					<Link href={website} target="_blank" variant="permalink" className="text-lg">
						{t("Nx: Accéder au site")}
					</Link>
				</div>
			)}
		</div>
	)
}
