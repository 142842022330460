import { Button, Heading, Tabs } from "@/ui"

export const config = {
	id: "mre_templates:tabs_content",
}

const TabsComponent = ({ nodes, title, link }) => {
	return (
		<>
			{title && (
				<Heading variant="mre" className="text-center">
					{title}
				</Heading>
			)}
			<div className="my-4 md:my-8">
				<Tabs useWysiwyg={true} variant="mre" nodes={nodes} />
			</div>

			{link && link.url && (
				<div className="w-full flex justify-center items-center">
					<Button variant="mre" href={link.url}>
						{link.title}
					</Button>
				</div>
			)}
		</>
	)
}

const TabsContentWidget = ({ data }) => {
	let props = {
		nodes: data?.components?.map((item, index) => ({
			id: index,
			title: item.tabName,
			content: item.content,
		})),
		title: data?.extra_field?.title,
		link: data?.extra_field?.link,
	}

	return <TabsComponent {...props} />
}

export default TabsContentWidget
