import { useEffect, useRef, useState } from "react"

export const useHeader = (hideonPosition) => {
	const positionAfterScroll = useRef(() => {
		typeof document !== "undefined" && document.scrollY
	})
	const [headerState, setHeaderState] = useState("fixed top-12")

	const detectScrollTop = (e) => {
		if (e.target === document) {
			if (typeof window != "undefined") {
				if (window.scrollY === 0) {
					setHeaderState("top") // reach the top by scrolling top
				} else if (
					window.scrollY > positionAfterScroll.current &&
					window.scrollY > hideonPosition + 60
				) {
					setHeaderState("scroll_down_after_header") // scrolling down but still not leaving the header
				} else if (
					window.scrollY > positionAfterScroll.current &&
					window.scrollY > hideonPosition
				) {
					setHeaderState("bypassing_header") // scrolling down and leaving header
				} else if (
					window.scrollY < positionAfterScroll.current &&
					window.scrollY > hideonPosition
				) {
					setHeaderState("scroll_top") // scrolling up but no reaching the header
				}
				positionAfterScroll.current = window.scrollY
			}
		}
	}

	useEffect(() => {
		document.addEventListener("scroll", detectScrollTop, true)
		return () => {
			document.removeEventListener("scroll", detectScrollTop, true)
		}
	}, [])
	return { headerState }
}
