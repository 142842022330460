import get from "lodash.get"
import { Link, Heading, Text, Container, Icon } from "@/ui"
import clsx from "clsx"

const ConseilsFilter = ({ data }) => {
	const title = get(data, "extra_field.title", "")
	const label = get(data, "extra_field.label", "")
	const tags = data?.components.map((item) => {
		return { ...item.tags, active: item.active }
	})

	return (
		<div className="relative pt-16 overflow-hidden">
			{title && (
				<Icon
					id="awb-symbol-outline"
					className="absolute -top-[300px] z-0 opacity-50 -right-[240px] md:-right-[570px] text-primary fill-current w-[400px] md:w-[950px] h-[600px]"
				/>
			)}
			<Container>
				{title && (
					<Heading
						variant="mre"
						level="2"
						className="mb-5 relative z-1 flex flex-col items-center"
					>
						{title}
					</Heading>
				)}

				<nav className="md:flex items-center relative z-1">
					<Text as="span" className="mb-4 md:mb-0 mr-5 whitespace-nowrap">
						{label} :
					</Text>
					<ul className="inline items-center">
						{tags.map((tag) => (
							<li
								key={tag.attributes.id}
								className="flex-shrink-0 border border-secondary group md:transition md:duration-100 inline-block mb-1 ltr:mr-1 rtl:ml-1"
							>
								<Link
									href={tag.url}
									className={clsx(
										"text-base leading-none uppercase flex-1 px-2 pt-1.5 pb-1 md:transition md:duration-100 inline-block",
										tag.active
											? "bg-secondary text-white"
											: "bg-white text-black group-hover:bg-secondary group-hover:text-white"
									)}
								>
									<span
										className={clsx(
											"font-bold mr-1.5 md:transition md:duration-100",
											tag.active ? "text-white" : "text-secondary group-hover:text-white"
										)}
									>
										#
									</span>
									{tag.title}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</Container>
		</div>
	)
}

export const config = {
	id: "mre_templates:conseils_filter",
}

export default ConseilsFilter
