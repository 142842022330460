import { useI18n as useTranslation } from "@/hooks"
import clsx from "clsx"

export const FormStepper = ({ pages, currentStep, goToStep }) => {
	const { t } = useTranslation()

	const steps = Object.keys(pages || [])
	!pages?.webform_preview?.preview?.enable
		? steps.splice(steps.indexOf("webform_preview"), 1)
		: null

	const style = {
		Li: "relative text-center block shrink grow onlyMobile:grow-0 basis-auto whitespace-nowrap after:content-[''] after:w-[28px] after:h-[28px] after:absolute after:right-[-4px] after:top-[6px] after:border-r after:border-t after:border-primary after:rounded-[1px] after:rotate-45",
		LiActive: "after:bg-primary",
		Button:
			"group block relative px-[10px] before:content-[''] before:block before:w-full before:h-full before:absolute before:top-0 before:left-[-10px] before:border-t before:border-b before:border-primary",
		ButtonActive:
			"cursor-pointer before:bg-primary before:!left-[0px] before:w-[calc(100%)]",
		ButtonCurrent: "before:!w-[calc(100%-10px)]",
		ButtonText:
			"relative z-10 flex items-center justify-center w-full px-[10px] h-[40px] text-primary",
		ButtonTextActive: "!text-white",
	}

	return (
		<nav aria-label="Progress" className="mb-5">
			<ol role="list" className="flex items-center ">
				{steps.map((step, index) => (
					<li
						key={step}
						className={clsx(
							style.Li,
							(index == 0 || index < currentStep || index == currentStep) &&
								style.LiActive,
							index != currentStep && "onlyMobile:!hidden"
						)}
					>
						<a
							onClick={() => goToStep(index)}
							className={clsx(
								style.Button,
								index == currentStep && style.ButtonCurrent,
								(index == currentStep || index < currentStep) && style.ButtonActive,
								index == currentStep + 1 && "cursor-pointer"
							)}
						>
							<span
								className={clsx(
									style.ButtonText,
									(index == 0 || index < currentStep || index == currentStep) &&
										style.ButtonTextActive
								)}
							>
								{pages[step]["title"]
									? t(`Nx:${pages[step]["title"]}`)
									: t(`Nx:${pages[step]?.preview?.label}`)}
							</span>
						</a>
					</li>
				))}
			</ol>
		</nav>
	)
}
