import { Icon, Heading, Button, Wysiwyg, Link } from "@/ui"
import clsx from "clsx"
import { useKeenSlider } from "keen-slider/react"
import Image from "next/image"
import { useState, useEffect } from "react"

export const config = {
	id: "mre_templates:slider_cards",
}

const Card = ({ title, image, content, tag, link }) => {
	return (
		<div className="keen-slider__slide w-full">
			<div className="w-full h-full">
				<div className=" bg-white w-[calc(100%-5px)] m-auto shadow-md h-[calc(100%-5px)] shadow-gray-300">
					<div className=" bg-white w-full h-full">
						<div className="flex flex-col !min-h-0 !h-fit w-full">
							{image?.src && (
								<div className="aspect-w-16 aspect-h-7">
									<Image
										src={image?.src}
										layout="fill"
										objectFit="cover"
										alt={image?.alt || "slider cards image"}
									/>
								</div>
							)}

							<div className="p-7 flex flex-col">
								{tag && <span className="text-secondary mb-2">{tag}</span>}
								<Heading level="3" icon="center" className="!text-black">
									{title}
								</Heading>
								<>{content}</>
								{link?.url && (
									<Link href={link?.url} variant="permalink" className="mt-2">
										{link?.title}
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const SliderCardsWidget = ({ data }) => {
	const title = data?.extra_field?.title
	const items = data?.components?.map((item) => ({
		title: item?.title,
		content: <Wysiwyg html={item?.content?.value?.["#text"]} />,
		image: {
			src: item?.image[0]?._default,
			alt: item?.image[0]?.meta?.alt,
		},
		link: item?.link,
		tag: item?.tag,
	}))
	const background = data?.extra_field?.image[0]
	const theme = data?.extra_field?.theme || "white"
	const primaryLink = data?.extra_field?.primary_link
	const secondaryLink = data?.extra_field?.secondary_link
	const showTwo = data?.extra_field?.showTwo

	const [currentSlide, setCurrentSlider] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [isMobile, setIsMobile] = useState(false)

	const AdaptiveHeight = (slider) => {
		function updateHeight() {
			if (window.innerWidth <= 748) {
				const currentSlideIndex = slider.relativeSlide
				const currentSlide = slider.slides[currentSlideIndex]
				if (currentSlide) {
					slider.container.style.height =
						currentSlide.querySelector("div")?.offsetHeight + "px"
				}
			} else {
				const currentSlideIndex = slider.relativeSlide
				const currentSlide = slider.slides[currentSlideIndex]
				const prevSlide = slider.slides[currentSlideIndex - 1]
				const nextSlide = slider.slides[currentSlideIndex + 1]

				const visibleSlides = [prevSlide, currentSlide, nextSlide].filter(
					(slide) => slide?.getBoundingClientRect()?.left > 0
				)

				const maxHeight = Math.max(
					...visibleSlides.map((slide) => slide?.querySelector("div")?.offsetHeight || 0)
				)

				slider.container.style.height = maxHeight + "px"
			}
		}

		slider.on("created", () => {
			const _timer = setTimeout(() => {
				updateHeight()
				clearTimeout(_timer)
			}, 100)
		})

		slider.on("slideChanged", () => {
			const _timer = setTimeout(() => {
				updateHeight()
				clearTimeout(_timer)
			}, 100)
		})
	}

	let keenOptions = {
		loop: true,
		mode: "snap",
		created() {
			setLoaded(true)
		},
		slides: {
			spacing: 20,
			perView: 2,
		},
		breakpoints: {
			"(max-width: 1024px)": {
				slides: { perView: 1, spacing: 5 },
			},
		},
		slideChanged(slider) {
			setCurrentSlider(slider.track.details.rel)
		},
	}

	const [refCallback, slider] = useKeenSlider(keenOptions, [AdaptiveHeight])

	const handlePaginationClick = (index) => {
		setCurrentSlider(index)
		slider.current?.moveToIdx(index)
	}

	useEffect(() => {
		setIsMobile(window.innerWidth <= 748)

		const handleResize = () => {
			setIsMobile(window.innerWidth <= 748)
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return (
		<div className="relative slider_card_icons">
			{background?._default && (
				<div className="absolute inset-0 -z-1">
					<Image
						src={background?._default}
						alt={background?.meta?.alt}
						layout="fill"
						objectFit="cover"
					/>
				</div>
			)}
			<div className="py-12 px-4 md:px-0 md:container">
				{title && (
					<Heading variant="mre" level="2" className="text-center mb-12">
						{title}
					</Heading>
				)}
				{items.length <= 2 && (
					<div className="flex flex-col md:flex-row gap-10 md:container">
						{isMobile ? (
							<>
								{showTwo ? (
									items.slice(0, 2).map((slide, index) => (
										<div
											key={`card_${index + 1}`}
											className="flex flex-col items-center gap-4"
										>
											<Card
												key={`card_${index + 1}`}
												title={slide.title}
												image={slide.image}
												content={slide.content}
												tag={slide.tag}
												link={slide.link}
											/>

											{primaryLink.url && index === 0 && (
												<Button
													href={primaryLink?.url}
													target={primaryLink?.attributes?.target}
													variant="mre"
													color={theme === "white" ? "white" : "black"}
													className="w-fit md:w-fit h-full"
												>
													{primaryLink?.title}
												</Button>
											)}

											{secondaryLink.url && index === 1 && (
												<Button
													href={secondaryLink?.url}
													target={secondaryLink?.attributes?.target}
													variant="outlined"
													className="w-fit text-center md:w-fit max-h-full"
												>
													{secondaryLink?.title}
												</Button>
											)}
										</div>
									))
								) : (
									<Card
										title={items[0].title}
										image={items[0].image}
										content={items[0].content}
										tag={items[0].tag}
										link={items[0].link}
									/>
								)}
							</>
						) : (
							items.map((slide, index) => (
								<Card
									key={`card_${index + 1}`}
									title={slide.title}
									image={slide.image}
									content={slide.content}
									tag={slide.tag}
									link={slide.link}
								/>
							))
						)}
					</div>
				)}

				{items.length > 2 && (
					<div className="relative mx-auto overflow-x-hidden">
						<div className="space-y-3 md:space-y-0 max-w-full mx-auto w-fit md:w-auto flex flex-col md:flex-row md:items-stretch justify-around md:space-x-5 rtl:space-x-reverse">
							<button
								className={clsx(
									theme === "white" ? "text-white" : "text-secondary",
									"items-center justify-center hidden md:flex hover:opacity-100 transition duration-200 ease-in-out",
									currentSlide === 0 && "opacity-50"
								)}
								onClick={(e) => e.stopPropagation() || slider.current?.prev()}
							>
								<Icon id="chevron-left" className="w-10 h-10 fill-current rtl-icon" />
							</button>

							<div
								ref={refCallback}
								className={clsx(
									// "relative flex overflow-x-hidden keen-slider mx-auto w-auto py-5"
									"relative flex overflow-x-hidden keen-slider mx-auto w-auto"
								)}
							>
								{items.map((slide, index) => {
									return (
										<Card
											key={`slider_card_${index + 1}`}
											title={slide.title}
											image={slide.image}
											content={slide.content}
											tag={slide.tag}
											link={slide.link}
										/>
									)
								})}
							</div>

							<button
								className={clsx(
									theme === "white" ? "text-white" : "text-secondary",
									"items-center justify-center hidden md:flex hover:opacity-100 transition duration-200 ease-in-out",
									currentSlide === items.length - 1 && "opacity-50"
								)}
								onClick={(e) => e.stopPropagation() || slider.current?.next()}
							>
								<Icon id="chevron-right" className="w-10 h-10 fill-current rtl-icon" />
							</button>
						</div>
						{loaded && slider.current && (
							<div className="flex items-center w-full mt-8 px-0 md:px-4">
								<button
									className={clsx(
										"items-center justify-center flex md:hidden hover:opacity-100 transition duration-200 ease-in-out",
										theme === "white" ? "text-white" : "text-secondary",
										currentSlide === 0 && "opacity-50"
									)}
									onClick={(e) => e.stopPropagation() || slider.current?.prev()}
								>
									<Icon id="chevron-left" className="w-4 h-4 fill-current rtl-icon" />
								</button>
								<div className="w-full h-[5px] flex items-center mx-2 md:mx-28">
									{slider.current.track.details.slides.map((_, index) => {
										return (
											<button
												className="group h-[2px] flex items-center w-full py-auto"
												key={`pagination-${index}`}
												onClick={() => {
													handlePaginationClick(index)
												}}
											>
												<div
													data-theme={theme}
													className={clsx(
														"inline-block w-full transition delay-100 ease-in-out",
														currentSlide === index
															? `${
																	theme === "white" ? "bg-[#fff]" : "bg-primary"
															  }  h-[4px]`
															: `${
																	theme === "white" ? "bg-[#fff]" : "bg-[#D8D8D8]"
															  } bg-opacity-50 h-[2px]`,
														"group-hover:bg-opacity-100  group-hover:h-[4px]"
													)}
												></div>
											</button>
										)
									})}
								</div>
								<button
									className={clsx(
										"items-center justify-center flex md:hidden hover:opacity-100 transition duration-200 ease-in-out",
										theme === "white" ? "text-white" : "text-secondary",
										currentSlide === items.length - 1 && "opacity-50"
									)}
									onClick={(e) => e.stopPropagation() || slider.current?.next()}
								>
									<Icon id="chevron-right" className="w-4 h-4 fill-current rtl-icon" />
								</button>
							</div>
						)}
					</div>
				)}
				{(primaryLink.url || secondaryLink.url) && (
					<div
						className={clsx(
							"flex items-center justify-center flex-col md:flex-row mt-4 md:mt-12 px-0 gap-4",
							items.length > 2
								? "md:px-[59px] md:gap-[20px]"
								: "md:gap-10 md:px-4 lg:px-8",
							showTwo && "hidden md:flex"
						)}
					>
						{primaryLink.url && (
							<Button
								href={primaryLink?.url}
								target={primaryLink?.attributes?.target}
								variant="mre"
								color={theme === "white" ? "white" : "black"}
								className="w-fit md:w-fit h-full"
							>
								{primaryLink?.title}
							</Button>
						)}

						{secondaryLink.url && (
							<Button
								href={secondaryLink?.url}
								target={secondaryLink?.attributes?.target}
								variant="outlined"
								className="w-fit text-center md:w-fit max-h-full"
							>
								{secondaryLink?.title}
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default SliderCardsWidget
