import { InputFileSimple, InputFileMultiple } from "@/ui"
import { useI18n as useTranslation } from "@/hooks"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const InputFileField = ({ webformId, name, field }) => {
	const { t } = useTranslation()
	const { validation, states } = field
	const local_state = useCheckConditions(states)

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	const urlUploadDocuments = `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/webform_rest/${webformId}/upload/${name}`
	if (field?.validation?.maxFiles) {
		return (
			<InputFileMultiple
				label={t(`Nx:${field.label}`) + `${local_validation?.required ? " *" : ""}`}
				url={urlUploadDocuments}
				name={name}
				accept={field?.extensionsClean ? (field?.extensionsClean).split(" ") : []}
				validations={
					local_validation?.required ? { required: t("Nx:This field is requiered") } : {}
				}
				fileSizeLimit={field?.maxSizeMb || 2}
				limitFiles={field?.validation?.maxFiles}
			/>
		)
	}
	return (
		<InputFileSimple
			url={urlUploadDocuments}
			name={name}
			accept={field?.extensionsClean ? (field?.extensionsClean).split(" ") : []}
			validations={
				local_validation?.required ? { required: t("Nx:This field is requiered") } : {}
			}
			fileSizeLimit={field?.maxSizeMb || 2}
		/>
	)
}
