import { Icon } from "../icon/Icon"
import clsx from "clsx"
import { badge } from "./theme"

export const Badge = ({ icon, text, href, size = "normal", className = "" }) => {
	const Component = href ? "a" : "span"
	return (
		<Component
			href={href}
			className={clsx(badge.wrapper, badge.color, badge.size[size], className)}
		>
			{icon && <Icon id={icon} className={clsx(badge.icon)} />}
			<span>{text}</span>
		</Component>
	)
}

export default Badge
