import PropTypes from "prop-types"
import { domToReact } from "html-react-parser"
// import { Element } from "domhandler/lib/node"
import parse from "html-react-parser"
import Image from "next/image"
import Link from "next/link"
import clsx from "clsx"
import { Text } from "../text/Text"
import { Heading } from "../heading/Heading"

export function Wysiwyg({ html, as, prose, className, textVariant, ...rest }) {
	// usecase: Wysiwyg inside of p ==> wrong dom nesting
	const AsComponent = as ?? "div"

	// prose should be optional. e.g, form valadiation messages are not prose
	const finalClassName = clsx(prose && "prose", className)

	const options = {
		replace: (domNode) => {
			if (domNode.name === "img") {
				const { src, alt, width, height } = domNode.attribs

				return (
					<Image
						src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`}
						width={`${width}px`}
						height={`${height}px`}
						alt={alt || "wysiwyg image"}
						layout="intrinsic"
						objectFit="cover"
					/>
				)
			}

			const headings = ["h1", "h2", "h3", "h4", "h5", "h6"]
			if (headings.includes(domNode.name)) {
				for (let i = 1; i <= 6; i++) {
					if (domNode.name == "h" + i) {
						return (
							<Heading level={i} className="mt-6">
								{domToReact(domNode.children)}
							</Heading>
						)
					}
				}
			}

			if (domNode.name === "ul" || domNode.name === "ol") {
				const className = domNode.attribs.class ? domNode.attribs.class : "custom-list"
				return <ul className={clsx(className)}>{domToReact(domNode.children)}</ul>
			}

			if (domNode.name == "p") {
				const className = domNode?.attribs?.class?.includes("-align-")
					? domNode.attribs.class.replace("-align-", "-")
					: domNode.attribs.class
				return (
					<Text as="p" variant={textVariant} className={className}>
						{domToReact(domNode.children)}
					</Text>
				)
			}

			if (domNode.name === "a") {
				const { href, class: className } = domNode.attribs

				return (
					<Link href={href} passHref>
						<a className={className}>{domToReact(domNode.children)}</a>
					</Link>
				)
			}
		},
	}

	return (
		<AsComponent className={clsx(finalClassName, "wysiwyg")} {...rest}>
			{parse(html, options)}
		</AsComponent>
	)
}

Wysiwyg.propTypes = {
	prose: PropTypes.bool,
	as: PropTypes.element,
}
