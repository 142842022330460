import React, { forwardRef, useRef } from "react"
import { Icon } from "../icon/Icon"
import clsx from "clsx"
import { inputRadio } from "./theme"

export const InputRadio = forwardRef(
	(
		{
			variant = "default",
			hasError,
			errorMessage,
			label,
			name,
			options,
			selectedInput,
			setSelectedInput,
			// disabled,
			// description,
			isRequired,
			...rest
		},
		ref
	) => {
		return (
			<div className={inputRadio[variant].container}>
				{/* <div className={clsx(inputRadio[variant].label)}>
					{label && <label>{label}</label>}
				</div> */}
				<label className={inputRadio[variant].label}>
					{label}
					{isRequired && (
						<span
							className={clsx(
								inputRadio[variant].required
									? inputRadio[variant].required
									: "font-bold mx-1 text-red-500"
							)}
							title="This field is required"
						>
							*
						</span>
					)}
				</label>
				<div className={inputRadio[variant].wrapper}>
					{options.map((option, index) => {
						return (
							<InputRadioElement
								variant={variant}
								key={index}
								selectedInput={
									selectedInput !== undefined
										? options.find((option) => {
												return selectedInput === option.value
										  })
										: {}
								}
								setSelectedInput={setSelectedInput}
								name={name}
								option={option}
								{...rest}
								ref={ref && ref}
							/>
						)
					})}
				</div>
				{hasError && <p className={inputRadio[variant].errorMessage}>{errorMessage}</p>}
			</div>
		)
	}
)

const InputRadioElement = forwardRef(
	({ variant = "default", selectedInput, setSelectedInput, option, ...rest }, ref) => {
		const inputRadioRef = useRef()

		const handleInputClick = () => {
			setSelectedInput(option)
			inputRadioRef.current.click()
		}

		return (
			<div>
				<label className={clsx(inputRadio[variant].radioContainer, "relative")}>
					<input
						type="radio"
						value={option.value}
						checked={selectedInput.value === option.value ? true : false}
						id={option.value}
						className="absolute w-0 h-0 left-56 invisible"
						{...rest}
						ref={(e) => {
							typeof ref === "function" && ref?.(e)
							inputRadioRef.current = e
						}}
					/>
					<div
						onClick={handleInputClick}
						className={clsx(
							inputRadio[variant].input,
							selectedInput.value === option.value
								? inputRadio[variant].state.checked
								: inputRadio[variant].state.unChecked
						)}
					>
						{selectedInput === option && (
							<Icon
								id="dots-circle-horizontal-solid"
								className={inputRadio[variant].icon}
							></Icon>
							/** this icon must a be a simple filled circle */
						)}
					</div>
					<span className="text-sm">{option.label}</span>
				</label>
			</div>
		)
	}
)
